import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import ForgotPwd from "../views/ForgotPwd.vue";
import SignUp from "../views/SignUp.vue";
import Verification from "../views/Verification.vue";
import ShopHome from "../views/ShopHome.vue";
import Dashboard from "../views/Dashboard.vue";
import Album from "../views/Album.vue";
import Boutique from "../views/Boutique.vue";
import Contact from "../views/Contact.vue";
import NewAcceuil from "../views/home/NewAcceuil.vue";
import PersonalDetails from "../views/dashboard/PersonalDetails.vue";
import Categories from "../views/dashboard/Categories.vue";
import CreateAlbum from "../views/dashboard/CreateAlbum.vue";
import DetailsAlbum from "../views/dashboard/DetailsAlbum.vue";
import Messages from "../views/dashboard/Messages.vue";
import Acceuil from "../views/home/Acceuil.vue";
import Details from "../views/home/Details.vue";
import Souscription from "../views/home/Souscription.vue";
import Shop from "../views/shophome/Shop.vue";
import ShopAlbum from "../views/shophome/ShopAlbum.vue";
import Join from "../views/shophome/Join.vue";
import Detail from "../views/shophome/Detail.vue";
import Categorie from "../views/home/Categorie.vue";
import Type from "../views/home/Type.vue";
import Boutiques from "../views/dashboard/Boutiques.vue";
import Types from "../views/dashboard/Types.vue";
import Publicite from "../views/dashboard/Publicite.vue";
import Evenement from "../views/dashboard/Evenement.vue";
import UsersFournisseurs from "../views/dashboard/UsersFournisseurs.vue";
import UsersFourActive from "../views/dashboard/UsersFourActive.vue";
import Users from "../views/dashboard/Users.vue";
import Abonnement from "../views/dashboard/Abonnement.vue";
import Preuves from "../views/dashboard/Preuves.vue";
import Videos from "../views/dashboard/Videos.vue";
import BoutiquesActif from "../views/dashboard/BoutiquesActif.vue";
import BoutiquesInactif from "../views/dashboard/BoutiquesEncours.vue";
import Mescommande from "../views/dashboard/Mescommande.vue";
import Fourcommande from "../views/dashboard/Fourcommande.vue";
import Admincommande from "../views/dashboard/Admincommande.vue";
import Acceptercommande from "../views/dashboard/Acceptercommande.vue";
import Livrercommande from "../views/dashboard/Livrercommande.vue";
import AcceptercommandeAdmin from "../views/dashboard/AcceptercommandeAdmin.vue";
import LivrercommandeAdmin from "../views/dashboard/LivrercommandeAdmin.vue";
import Notations from "../views/dashboard/Notations.vue";
import Devise from "../views/dashboard/Devise.vue";
import i18n from "../i18n";

import store from "../store";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        component: Home,
        children: [
          {
            path: "/",
            name: "Acceuil",
            component: NewAcceuil,
          },
          {
            path: "albums",
            name: "Albums",
            component: Album,
          },
          {
            path: "categorie/:id",
            name: "Categorie",
            component: Categorie,
          },
          {
            path: "boutiques",
            name: "Boutiques",
            component: Boutique,
          },
          {
            path: "type/:id",
            name: "Types",
            component: Type,
          },
          {
            path: "contact",
            name: "Contact",
            component: Contact,
          },
          {
            path: "albums/:id",
            name: "Details",
            component: Details,
          },
          {
            path: "souscription/:id",
            name: "Souscription",
            component: Souscription,
          },
        ],
      },
      {
        path: "shopHome/:id",
        component: ShopHome,
        children: [
          {
            path: "/",
            name: "Shop",
            component: Shop,
          },
          {
            path: "shopAlbum",
            name: "ShopAlbum",
            component: ShopAlbum,
          },
          {
            path: "contact-us",
            name: "Join",
            component: Join,
          },
          {
            path: "details/:idAlb",
            name: "Detail",
            component: Detail,
          },
        ],
      },
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: { guest: true },
      },
      {
        path: "forgotPassword",
        name: "ForgotPassword",
        component: ForgotPwd,
        meta: { guest: true },
      },
      {
        path: "signup",
        name: "SignUp",
        component: SignUp,
        meta: { guest: true },
      },

      {
        path: "verification/:id",
        name: "Verification",
        component: Verification,
      },
      {
        path: "dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
        children: [
          {
            path: "/",
            name: "PersonalDetails",
            component: PersonalDetails,
          },
          {
            path: "categories",
            name: "Categories",
            component: Categories,
          },
          {
            path: "boutiques",
            name: "boutiques",
            component: Boutiques,
          },
          {
            path: "messages",
            name: "Messages",
            component: Messages,
          },
          {
            path: "createAlbum",
            name: "CreateAlbum",
            component: CreateAlbum,
          },
          {
            path: "detailsAlbum/:id",
            name: "detailsAlbum",
            component: DetailsAlbum,
          },
          {
            path: "types",
            name: "types",
            component: Types,
          },
          {
            path: "mes/commande",
            name: "commandes",
            component: Mescommande,
          },
          {
            path: "four/commande",
            name: "fourcommandes",
            component: Fourcommande,
          },
          {
            path: "four/commande/accepter",
            name: "fourcommandesaccepter",
            component: Acceptercommande,
          },
          {
            path: "four/commande/livrer",
            name: "fourcommandeslivrer",
            component: Livrercommande,
          },
          {
            path: "devise",
            name: "devise",
            component: Devise,
          },
          {
            path: "admin/commande",
            name: "admincommandes",
            component: Admincommande,
          },
          {
            path: "admin/commande/accepter",
            name: "admincommandesaccepter",
            component: AcceptercommandeAdmin,
          },
          {
            path: "admin/commande/livrer",
            name: "admincommandeslivrer",
            component: LivrercommandeAdmin,
          },
          {
            path: "publicites",
            name: "publicites",
            component: Publicite,
          },
          {
            path: "evenements",
            name: "evenements",
            component: Evenement,
          },
          {
            path: "users",
            name: "users",
            component: Users,
          },
          {
            path: "users/inactif",
            name: "users/inactif",
            component: UsersFournisseurs,
          },
          {
            path: "users/actif",
            name: "users/actif",
            component: UsersFourActive,
          },
          {
            path: "abonnements",
            name: "abonnements",
            component: Abonnement,
          },
          {
            path: "preuves",
            name: "preuves",
            component: Preuves,
          },
          {
            path: "videos",
            name: "videos",
            component: Videos,
          },
          {
            path: "boutiques/actif",
            name: "boutiques/actif",
            component: BoutiquesActif,
          },
          {
            path: "boutiques/inactif",
            name: "boutiques/inactif",
            component: BoutiquesInactif,
          },
          {
            path: "notations",
            name: "notations",
            component: Notations,
          },
          ,
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next({ name: "Login" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({ name: "PersonalDetails" });
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
