import axios from "axios";

const state = {
  notations: [],
  loading: true,
};

const getters = {
  notations: (state) => state.notations,
};

const actions = {
  async getNotations({ commit, rootState }) {
    commit("loading");
    let token = rootState.auth.token;
    let response = await axios.get("/notations/satisfait", {
      headers: { Authorization: "Bearer " + token },
    });
    let data = response.data;
    commit("setNotations", { notations: data });
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },

  setNotations(state, { notations }) {
    state.notations = notations;
    state.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
