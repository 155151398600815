<template>
  <v-app>
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      style="background-color: #256d7b"
    >
      <div class="container">
        <!-- Logo container-->
        <a
          class="logo"
          @click="goTo(`/${$i18n.locale}`)"
          style="position:absolute"
        >
          <span class="logo-light-mode">
            <img
              src="/logo.png"
              class="l-dark"
              style="height: 70px; width: 150px; padding: 10px;"
              alt=""
            />
            <img
              src="/logo.png"
              class="l-light"
              style="height: 70px; width: 150px; padding: 10px;"
              alt=""
            />
          </span>
          <img
            src="/logo.png"
            style="height: 90px; width: 150px; padding: 10px;"
            class="logo-dark-mode"
            alt=""
          />
        </a>
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a class="navbar-toggle" id="isToggle" @click="toggleMenu()">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul class="navigation-menu nav-light">
            <li>
              <a @click="goTo(`/${$i18n.locale}`)">{{ $t("nav.acceuil") }}</a>
            </li>
            <li id="dash">
              <a @click="goTo(`/${$i18n.locale}/dashboard`)">
                {{ $t("nav.dashboard") }}</a
              >
            </li>
            <li
              id="subdash"
              class="has-submenu parent-menu-item"
              style="display: none;"
            >
              <a>{{ $t("nav.dashboard") }}</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a
                    class="sub-menu-item"
                    @click="goTo(`/${$i18n.locale}/dashboard`)"
                    >{{ $t("nav.profile") }}</a
                  >
                </li>
                <li>
                  <a
                    class="sub-menu-item"
                    @click="goTo(`/${$i18n.locale}/dashboard/mes/commande`)"
                    >{{ $t("dashboard.mescommande") }}</a
                  >
                </li>
                <template v-if="role === 2">
                  <li>
                    <a
                      class="sub-menu-item"
                      @click="goTo(`/${$i18n.locale}/dashboard/boutiques`)"
                      >{{ $t("dashboard.btq") }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="sub-menu-item"
                      @click="goTo(`/${$i18n.locale}/dashboard/categories`)"
                      >{{ $t("dashboard.cat") }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="sub-menu-item"
                      @click="goTo(`/${$i18n.locale}/dashboard/createAlbum`)"
                      >{{ $t("dashboard.alb") }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="sub-menu-item"
                      @click="goTo(`/${$i18n.locale}/dashboard/four/commande`)"
                      >{{ $t("dashboard.fourcommande") }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="sub-menu-item"
                      @click="
                        goTo(
                          `/${$i18n.locale}/dashboard/four/commande/accepter`
                        )
                      "
                      >{{ $t("dashboard.fourcommandeacc") }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="sub-menu-item"
                      @click="
                        goTo(`/${$i18n.locale}/dashboard/four/commande/livrer`)
                      "
                      >{{ $t("dashboard.fourcommandeliv") }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="sub-menu-item"
                      @click="goTo(`/${$i18n.locale}/dashboard/abonnements`)"
                      >{{ $t("dashboard.abonnement") }}</a
                    >
                  </li>
                </template>
                <template v-if="role === 0">
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/types`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.types") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/publicites`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.pub") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/admin/commande`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.allcommande") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="
                        goTo(
                          `/${$i18n.locale}/dashboard/admin/commande/accepter`
                        )
                      "
                      class="sub-menu-item"
                      >{{ $t("dashboard.fourcommandeacc") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="
                        goTo(`/${$i18n.locale}/dashboard/admin/commande/livrer`)
                      "
                      class="sub-menu-item"
                      >{{ $t("dashboard.fourcommandeliv") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/evenements`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.event") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/videos`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.video") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/users/inactif`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.usersIna") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/users/actif`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.usersEn") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/users`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.users") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/preuves`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.preuve") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="
                        goTo(`/${$i18n.locale}/dashboard/boutiques/actif`)
                      "
                      class="sub-menu-item"
                      >{{ $t("dashboard.btqac") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="
                        goTo(`/${$i18n.locale}/dashboard/boutiques/inactif`)
                      "
                      class="sub-menu-item"
                      >{{ $t("dashboard.btqinac") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/notations`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.notations") }}
                    </a>
                  </li>
                  <li class="navbar-item account-menu px-0 mt-2">
                    <a
                      @click="goTo(`/${$i18n.locale}/dashboard/devise`)"
                      class="sub-menu-item"
                      >{{ $t("dashboard.devise") }}
                    </a>
                  </li>
                </template>
                <li>
                  <a
                    class="sub-menu-item"
                    @click="goTo(`/${$i18n.locale}/dashboard/messages`)"
                    >{{ $t("dashboard.msg") }}</a
                  >
                </li>
                <li>
                  <a @click="logout" class="sub-menu-item">{{
                    $t("dashboard.logout")
                  }}</a>
                </li>
                <li>
                  <a @click="dialogDelete = true" class="sub-menu-item">{{
                    $t("dashboard.delete11")
                  }}</a>
                </li>
              </ul>
            </li>
            <li>
              <a @click="goTo(`/${$i18n.locale}/albums`)">{{
                $t("nav.album")
              }}</a>
            </li>
            <li>
              <a @click="goTo(`/${$i18n.locale}/boutiques`)">{{
                $t("nav.boutique")
              }}</a>
            </li>
            <li>
              <a @click="goTo(`/${$i18n.locale}/contact`)">{{
                $t("nav.contact")
              }}</a>
            </li>
            <li class="has-submenu parent-menu-item">
              <a>{{ lng }}</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a @click="changedtofr" class="sub-menu-item">{{
                    $t("lang1")
                  }}</a>
                </li>
                <li>
                  <a @click="changedtoen" class="sub-menu-item">{{
                    $t("lang2")
                  }}</a>
                </li>
              </ul>
            </li>
          </ul>
          <!--end navigation menu-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card :loading="myloading" :disabled="myloading">
        <v-card-title class="text-h5"
          >{{ $t("dashboard.deleteAcc") }} ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Profile Start -->
    <section class="section mt-60">
      <div class="container-fluid mt-lg-3">
        <div class="row mr-2 ml-2">
          <div class="col-lg-3 col-md-3 col-12 d-lg-block d-none">
            <div class="sidebar sticky-bar p-2 rounded shadow">
              <div class="widget">
                <h5 class="widget-title">{{ $t("dashboard.info") }} :</h5>
                <template v-if="role != 0">
                  <div class="row mt-4">
                    <div class="col-6 text-center">
                      <i
                        data-feather="user-plus"
                        class="fea icon-ex-md text-primary mb-1"
                      ></i>
                      <h5 class="mb-0">{{ nbrCat }}</h5>
                      <p class="text-muted mb-0">{{ $t("dashboard.cat") }}</p>
                    </div>
                    <!--end col-->

                    <div class="col-6 text-center">
                      <i
                        data-feather="users"
                        class="fea icon-ex-md text-primary mb-1"
                      ></i>
                      <h5 class="mb-0">{{ nbrArt }}</h5>
                      <p class="text-muted mb-0">{{ $t("dashboard.alb") }}</p>
                    </div>
                    <!--end col-->
                  </div>
                </template>
                <template v-else>
                  <div class="row mt-4">
                    <div class="col-6 text-center">
                      <i
                        data-feather="user-plus"
                        class="fea icon-ex-md text-primary mb-1"
                      ></i>
                      <h5 class="mb-0">{{ nbrBout }}</h5>
                      <p class="text-muted mb-0">{{ $t("dashboard.btq") }}</p>
                    </div>
                    <!--end col-->

                    <div class="col-6 text-center">
                      <i
                        data-feather="users"
                        class="fea icon-ex-md text-primary mb-1"
                      ></i>
                      <h5 class="mb-0">{{ nbrUser }}</h5>
                      <p class="text-muted mb-0">{{ $t("dashboard.users") }}</p>
                    </div>
                    <!--end col-->
                  </div>
                </template>
                <!--end row-->
              </div>

              <div class="widget mt-4">
                <ul class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                  <li class="navbar-item account-menu px-0">
                    <router-link
                      :to="{ path: `/${$i18n.locale}/dashboard` }"
                      class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                    >
                      <span class="h4 mb-0"><i class="uil uil-user"></i></span>
                      <h6 class="mb-0 ms-2">{{ $t("nav.profile") }}</h6>
                    </router-link>
                  </li>

                  <li class="navbar-item account-menu px-0 mt-2">
                    <router-link
                      :to="{ path: `/${$i18n.locale}/dashboard/mes/commande` }"
                      class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                    >
                      <span class="h4 mb-0"
                        ><i class="uil uil-picture"></i
                      ></span>
                      <h6 class="mb-0 ms-2">
                        {{ $t("dashboard.mescommande") }}
                      </h6>
                    </router-link>
                  </li>

                  <template v-if="role === 2">
                    <li class="navbar-item account-menu px-0 mt-2">
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/boutiques` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-store-alt"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.btq") }}</h6>
                      </router-link>
                    </li>

                    <li class="navbar-item account-menu px-0 mt-2">
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/categories` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-file"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.cat") }}</h6>
                      </router-link>
                    </li>

                    <li class="navbar-item account-menu px-0 mt-2">
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/createAlbum` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-picture"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.alb") }}</h6>
                      </router-link>
                    </li>

                    <li class="navbar-item account-menu px-0 mt-2">
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/four/commande`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-picture"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.fourcommande") }}
                        </h6>
                      </router-link>
                    </li>
                    <li class="navbar-item account-menu px-0 mt-2">
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/four/commande/accepter`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-picture"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.fourcommandeacc") }}
                        </h6>
                      </router-link>
                    </li>
                    <li class="navbar-item account-menu px-0 mt-2">
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/four/commande/livrer`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-picture"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.fourcommandeliv") }}
                        </h6>
                      </router-link>
                    </li>
                    <li class="navbar-item account-menu px-0 mt-2">
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/abonnements` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-picture"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.abonnement") }}
                        </h6>
                      </router-link>
                    </li>
                  </template>
                  <template v-if="role === 0">
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/types` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.types") }}</h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/publicites` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.pub") }}</h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/admin/commande`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.allcommande") }}
                        </h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/admin/commande/accepter`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-picture"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.fourcommandeacc") }}
                        </h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/admin/commande/livrer`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-picture"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.fourcommandeliv") }}
                        </h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/evenements` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.event") }}</h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/videos` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.video") }}</h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/users/inactif`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.usersIna") }}
                        </h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/users/actif`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.usersEn") }}
                        </h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/users` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.users") }}</h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{ path: `/${$i18n.locale}/dashboard/preuves` }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.preuve") }}</h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/boutiques/actif`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.btqac") }}</h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/boutiques/inactif`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.btqinac") }}</h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/notations`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">
                          {{ $t("dashboard.notations") }}
                        </h6>
                      </router-link>
                    </li>
                    <li
                      class="navbar-item account-menu px-0 mt-2"
                      v-if="role === 0"
                    >
                      <router-link
                        :to="{
                          path: `/${$i18n.locale}/dashboard/devise`,
                        }"
                        class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                      >
                        <span class="h4 mb-0"
                          ><i class="uil uil-window-section"></i
                        ></span>
                        <h6 class="mb-0 ms-2">{{ $t("dashboard.devise") }}</h6>
                      </router-link>
                    </li>
                  </template>

                  <li class="navbar-item account-menu px-0 mt-2">
                    <router-link
                      :to="{ path: `/${$i18n.locale}/dashboard/messages` }"
                      class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                    >
                      <span class="h4 mb-0"
                        ><i class="uil uil-envelope-star"></i
                      ></span>
                      <h6 class="mb-0 ms-2">{{ $t("dashboard.msg") }}</h6>
                    </router-link>
                  </li>

                  <li
                    class="navbar-item account-menu px-0 mt-2"
                    @click="logout"
                  >
                    <div
                      class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                    >
                      <span class="h4 mb-0"
                        ><i class="uil uil-dashboard"></i
                      ></span>
                      <h6 class="mb-0 ms-2">{{ $t("dashboard.logout") }}</h6>
                    </div>
                  </li>

                  <li
                    class="navbar-item account-menu px-0 mt-2"
                    @click="dialogDelete = true"
                  >
                    <div
                      class="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                    >
                      <span class="h4 mb-0"
                        ><i class="uil uil-user-minus"></i
                      ></span>
                      <h6 class="mb-0 ms-2">{{ $t("dashboard.delete11") }}</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-9 col-12">
            <div class="border-bottom">
              <h5>
                {{ nom }} {{ prenom }}
                <template>
                  <v-chip
                    v-if="role === 2 || role === 0"
                    class="ma-2"
                    color="green"
                    text-color="white"
                  >
                    {{ $t("user.active") }}
                  </v-chip>

                  <v-chip v-else class="ma-2" color="red" text-color="white">
                    {{ $t("user.desactive") }}
                  </v-chip>
                </template>
              </h5>
              <!-- <v-alert
                v-if="role === 1 || pieces.length > 0"
                dense
                outlined
                type="warning"
                prominent
                border="left"
              >
                {{ $t('user.message') }}
              </v-alert> -->
              <!-- <v-alert
                v-if="dayBeetwen(dateFin) <= 30"
                dense
                outlined
                type="warning"
                prominent
                border="left"
              >
                {{ $t('msg1') }} {{ dayBeetwen(dateFin) }} {{ $t('msg2') }}
              </v-alert> -->
              <p class="text-muted mb-0">{{ $t("dashboard.welcome") }}.</p>
            </div>

            <div class="border-bottom">
              <v-container fluid>
                <router-view />
              </v-container>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Profile End -->
  </v-app>
</template>

<script>
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
import te from "/public/js/plugins.init.js";
import tr from "/public/js/tobii.min.js";
import tt from "/public/js/bootstrap.bundle.min.js";
import td from "/public/js/shuffle.min.js";
import i18n from "../i18n";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { dayBeetwen } from "../helper";
export default {
  name: "Dashboard",
  data() {
    return {
      dialogDelete: false,
      myloading: false,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    ...mapGetters([
      "loading",
      "categoriesByBoutiques",
      "nbrUser",
      "nbrCat",
      "nbrBout",
      "nbrArt",
    ]),
    ...mapGetters(["loading", "albumsByUsers", "lng"]),
    nom: function() {
      return this.$store.getters.nom;
    },
    prenom: function() {
      return this.$store.getters.prenom;
    },
    role: function() {
      return this.$store.getters.role;
    },

    dateFin: function() {
      return this.$store.getters.dateFin;
    },

    pieces: function() {
      if (this.$store.getters.pieces) return this.$store.getters.pieces;
      else return [];
    },

    categories() {
      if (this.categoriesByBoutiques) return this.categoriesByBoutiques;
      else [];
    },

    albums() {
      if (this.albumsByUsers) return this.albumsByUsers;
      else [];
    },
  },
  methods: {
    dayBeetwen(str) {
      if (str) {
        return dayBeetwen(str);
      } else {
        return 0;
      }
    },

    changedtofr: function() {
      i18n.locale = "fr";
      this.$store.commit("setlng", { lang: this.$t("lang1") });

      let language = this.$route.params.lang;
      if (language === "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
    changedtoen: function() {
      i18n.locale = "en";
      this.$store.commit("setlng", { lang: this.$t("lang2") });

      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
    async deleteItemConfirm() {
      try {
        this.myloading = true;
        await this.$store.dispatch("deleteUser");
        this.closeDelete();
        this.logout();
      } catch (e) {
        this.myloading = false;
        console.log(e);
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.myloading = false;
    },
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push({ name: "Login" }).catch(() => {});
    },
    closetoggle: function() {
      var $isOpen = document.getElementById("navigation");
      if ($isOpen.style.display === "block") {
        document.getElementById("isToggle").classList.toggle("open");
      }
      $isOpen.style.display = "none";
    },
    goTo(url) {
      this.closetoggle();
      this.$router.push(url).catch(() => {});
      window.scrollTo(0, 0);
    },
    toggleMenu: function() {
      document.getElementById("isToggle").classList.toggle("open");
      var isOpen = document.getElementById("navigation");
      var dash = document.getElementById("dash");
      var subdash = document.getElementById("subdash");
      if (isOpen.style.display === "block") {
        dash.style.display = "block";
        subdash.style.display = "none";
        isOpen.style.display = "none";
      } else {
        dash.style.display = "none";
        subdash.style.display = "block";
        isOpen.style.display = "block";
      }
    },
  },
  mounted() {
    // console.log(dayBeetwen("2022-12-31"));
    this.$store.dispatch("getAllUsers");
    this.$store.dispatch("getCategoriesByBoutiques");
    this.$store.dispatch("getBoutiquesByUsers");
    this.$store.dispatch("getAlbumsByUsers");
    this.$store.dispatch("getNbrUser");
    this.$store.dispatch("getNbrCat");
    this.$store.dispatch("getNbrBout");
    this.$store.dispatch("getNbrArt");
    let language = this.$route.params.lang;
    if (language === "en") {
      this.$store.commit("setlng", { lang: "English" });
    } else if (language === "fr") {
      this.$store.commit("setlng", { lang: "Français" });
    }
  },
};
</script>
<style>
@media (max-width: 1150px) {
  .ab {
    display: none;
  }
}
</style>
