<template>
  <div>
    <!-- Hero Start -->
    <section
      class="bg-half d-table w-100 h-1"
      style="background-color: #256d7b"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link :to="{ path: `/${$i18n.locale}/login` }">{{
                        $t('home.seconnecter')
                      }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link :to="{ path: `/${$i18n.locale}/signup` }">{{
                        $t('home.sinscrire')
                      }}</router-link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="auto">
            <div class="border-bottom pb-4">
              <div class="row">
                <div class="col-lg-9 col-md-8">
                  <div class="section-title">
                    <h4 class="title mb-2">{{ $t('acceuil.allcat') }}</h4>
                    <p class="text-muted mb-0">{{ $t('acceuil.listcat') }}</p>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="form custom-form">
                    <div class="">
                      <label class="menu-arrow mb-5">{{
                        $t('acceuil.selectcat')
                      }}</label>
                      <v-select
                        v-model="categorie"
                        :items="categories"
                        item-text="libelle"
                        item-value="libelle"
                        :label="$t('acceuil.allcat')"
                        return-object
                        dense
                        solo
                      ></v-select>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div
          class="row"
          v-for="item in albumsByCategories"
          :key="item.categorie.idCategorie"
        >
          <template v-if="item.albumsList[0] != null">
            <div class="col-12 mt-4 pt-2">
              <div class="section-title">
                <h5 class="mb-0">{{ item.categorie.libelle }}</h5>
              </div>
            </div>
            <!--end col-->

            <div
              class="col-lg-3 col-md-6 col-12 mt-4 pt-2"
              :key="album.idAlbum"
              v-for="album in item.albumsList"
            >
              <album :album="album"></album>
            </div>
            <!--end col-->
          </template>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
  </div>
</template>

<style scoped></style>

<script>
import { mapGetters } from 'vuex';
import i18n from '../../i18n';
import axios from 'axios';
import album from '../../components/Album.vue';

export default {
  components: { album },
  computed: {
    ...mapGetters(['categories', 'albumsByCategories']),
    categorie: {
      get() {
        return {};
      },
      set(categorie) {
        this.$store.commit('setCategorie', categorie);
        this.$router.push(`/${i18n.locale}/categorie/` + categorie.idCategorie);
        this.$store.dispatch('getAlbumsByCategorie', categorie.idCategorie);
        axios
          .get('auth/albums_categorie/' + categorie.idCategorie)
          .then((response) => {});
      },
    },
  },
  methods: {
    getDate(mydate) {
      let splitted = mydate.split(`T`);
      let date = splitted[0];
      if (i18n.locale === 'en') {
        return date;
      } else {
        let split = date.split(`-`);
        let dt = `${split[2]}-${split[1]}-${split[0]}`;
        return dt;
      }
    },
    cafouillage: function(endstock) {
      if (endstock[0] == 'E' || endstock[0] == 'I') return this.$t('stock');
      else return this.$t('command');
    },
  },
  created() {},

  mounted: function() {
  },
};
</script>
