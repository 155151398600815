<template>
  <div class="col  pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0 col" style="display: inline-block;">
          {{ $t("abonnement.preuve") }}
        </h4>

        <div class="row p-4">
          <div class="row">
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
            <div class="col-12 col-sm-6 text-right pr-5"></div>
          </div>

          <v-data-table
            :search="search"
            :headers="headers"
            :items="abonnements"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon
                v-for="i in item.pieces"
                :key="i.id"
                small
                class="mr-2"
                @click="showPiece(i.url)"
              >
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="openDialog(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>

      <v-dialog v-model="dialog1" persistent max-width="600px" class="col-auto">
        <v-card :loading="myloading" :disabled="myloading">
          <v-card-title>
            <span class="text-h5">{{ $t("delete1") }} ?</span>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="close1">
              {{ $t("cancel") }}
            </v-btn>

            <v-btn color="blue darken-1" text @click="deleteAlbum">
              OK
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog2" persistent max-width="600px" class="col-auto">
        <v-card :loading="myloading" :disabled="myloading">
          <v-card-title>
            <span class="text-h5">{{ $t("abonnement.active") }}</span>
          </v-card-title>

          <!-- <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="defaultAbonnement.pack"
                    :label="$t('abonnement.pack')"
                    :items="packs"
                    item-text="libelle"
                    item-value="value"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="defaultAbonnement.annee"
                    :label="$t('abonnement.annee')"
                    :items="annees"
                    item-text="libelle"
                    item-value="value"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="defaultAbonnement.file_max"
                    type="number"
                    :label="$t('personal.space1')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text> -->

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close2">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--end row-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
import te from "/public/js/plugins.init.js";
import tr from "/public/js/tobii.min.js";
import tt from "/public/js/bootstrap.bundle.min.js";
import td from "/public/js/shuffle.min.js";
import { compressImage } from "../../helper";
export default {
  data: () => {
    return {
      albu: {},
      myloading: false,
      search: "",
      dialog1: false,
      dialog2: false,
      alert1: false,
      alert2: false,
      alert: false,
      files: [],
      dialog: false,
      categories: [1, 2, 3],

      annees: [
        {
          id: 1,
          libelle: "1 an",
        },
        {
          id: 2,
          libelle: "2 ans",
        },
      ],
      defaultAbonnement: {
        user: null,
        file_max: "",
        annee: {},
        pack: {},
      },

      packs: [
        {
          id: 1,
          libelle: "Elementaire",
          value: "Elementaire",
        },
        {
          id: 2,
          libelle: "Elite",
          value: "Elite",
        },
        {
          id: 3,
          libelle: "Entreprise",
          value: "Entreprise",
        },
      ],

      initialAbonnement: {
        user: null,
        file_max: "",
        annee: {},
        pack: {},
      },
    };
  },

  watch: {
    dialog1(val) {
      val || this.close1();
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("type.name"),
          align: "center",
          sortable: false,
          value: "pack",
        },
        {
          text: this.$t("nom"),
          align: "center",
          sortable: false,
          value: "user.nom",
        },
        {
          text: this.$t("username"),
          align: "center",
          sortable: false,
          value: "user.username",
        },
        {
          text: this.$t("tel"),
          align: "center",
          sortable: false,
          value: "user.tel",
        },
        { text: this.$t("shops.actions"), value: "actions", sortable: false },
      ];
    },

    tab: function() {
      return [this.$t("stock"), this.$t("command")];
    },

    ...mapGetters(["loading", "abonnements"]),
  },

  created() {
    this.$store.dispatch("getAbonnement");
  },

  methods: {
    async showPiece(url) {
      await axios
        .get(url, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          const type = response.headers["content-type"];
          let blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8",
          });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({});
    },

    openDialog(alb) {
      this.albu = alb;
      this.dialog2 = true;
    },

    setGallery(image) {
      this.image = image;
    },

    close1() {
      this.myloading = false;
      this.dialog1 = false;
      this.albu = {};
      this.$nextTick(() => {
        this.defaultAbonnement = Object.assign({}, this.initialAbonnement);
      });
    },

    close2() {
      this.myloading = false;
      this.dialog2 = false;
      this.albu = {};
      this.$nextTick(() => {
        this.defaultAbonnement = {};
      });
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.defaultAbonnement = Object.assign({}, this.initialAbonnement);
      });
    },

    async deleteAlbum() {
      this.myloading = true;
      try {
        console.log(this.initialAbonnement);
        await this.$store.dispatch("deleteOneAbonnement", this.albu.id);
        this.alert1 = true;
        this.close1();
      } catch (e) {}
    },

    async tr(file) {
      let compressed = await compressImage(file);
      return compressed;
    },

    async save() {
      this.myloading = true;

      try {
        await this.$store.dispatch("updateFileSpace", this.albu.id);
        this.$store.dispatch("getAbonnement");
        this.$toast.success(this.$t("toast.update"));

        this.close2();
      } catch (e) {
        this.$toast.error(this.$t("toast.error"));
        this.myloading = false;
      }
      this.myloading = false;
    },

    async myfunc(tab) {
      var myTab = [];
      for (var t in tab) {
        var cmp = await compressImage(tab[t]);
        myTab.push(cmp);
      }
      return myTab;
    },

    async mysum(tab) {
      var sum = 0;
      for (var t in tab) {
        sum = sum + tab[t].size;
      }
      return sum;
    },

    async update() {
      this.myloading = true;
      var pack = this.defaultAbonnement.pack;
      var pieces = this.defaultAbonnement.pieces;
      let itemToSave;
      if (pack != null) {
        var compressedFichier = [];

        if (pieces) {
          if (pieces.length != 0) {
            compressedFichier = await this.myfunc(pieces);
          }
        }

        itemToSave = {
          id: this.albu.id,
          pack: this.defaultAbonnement.pack.value,
          pieces: compressedFichier,
        };

        try {
          await this.$store.dispatch("updateAbonnement", itemToSave);
          await this.$store.dispatch("userme");
          this.$toast.success(this.$t("toast.update"));
          this.close2();
        } catch (e) {
          this.$toast.error(this.$t("toast.error"));
        }
        this.myloading = false;
      }
    },
  },
};
</script>
