<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t("video.list") }}</h4>
      </div>

      <div class="p-1">
        <v-card>
          <div class="row">
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
            <div class="col-12 col-sm-6 text-right pr-5">
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="dialog = true">
                {{ $t("video.new") }}
              </v-btn>
            </div>
          </div>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="listVideos"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>

          <v-dialog v-model="dialog" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.contenu"
                        :label="$t('video.contenu')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        v-model="editedItem.video"
                        accept="image/gif"
                        prepend-icon="mdi-camera"
                        :label="$t('type.select')"
                        required
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5">{{ $t("delete1") }} ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
export default {
  data: () => {
    return {
      myloading: false,
      alert: false,
      search: "",
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        contenu: "",
        video: null,
      },
      defaultItem: {
        id: 0,
        contenu: "",
        video: null,
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("video.contenu"),
          align: "center",
          sortable: false,
          value: "contenu",
        },
        { text: this.$t("shops.actions"), value: "actions", sortable: false },
      ];
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("video.newcat")
        : this.$t("video.updatecat");
    },
    ...mapGetters(["loading", "listVideos"]),
    // ...mapGetters(["loading", "listTypes"]),
  },
  created() {
    this.initialize();
    this.$store.dispatch("getAllVideos");
    // this.$store.dispatch("getAllTypes");
  },
  methods: {
    // async check(libelle) {
    //   await this.$store.dispatch("checkCategorie", libelle);
    // },
    initialize() {},

    setSelected(value) {},

    editItem(item) {
      this.editedIndex = this.listTypes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // this.defaultBoutique = Object.assign({}, item.boutique);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.listTypes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.myloading = true;
      await this.$store.dispatch("deleteVideos", this.editedItem);
      this.closeDelete();
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.defaultBoutique = Object.assign({}, this.initialBoutique);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.myloading = false;
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.myloading = true;
      var libelle = this.editedItem.libelle;
      if (libelle != "") {
        let lang = this.$i18n.locale;
        let token = this.$store.state.auth.token;
        let pass = false;
        if (this.editedIndex > -1) {
          this.$store.dispatch("updateVideios", this.editedItem);
          this.$toast.success(this.$t("toast.update"));
        } else {
          try {
            this.$store
              .dispatch("postVideos", this.editedItem)
              .then((response) => {
                this.$toast.success(this.$t("toast.save"));
              });
          } catch (e) {
            this.$toast.error(this.$t("toast.error"));
          }
        }

        this.close();
      }
    },
  },
};
</script>
