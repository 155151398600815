<template>
  <v-app>
    <div class="mt-10" id="">
      <!-- Job List Start -->
      <section class="section">
        <div
          class="contain-lg contain-md contain-sm contain-esm contain-xl contain-xxl contain-xxxl"
        >
          <div class="row ml-15 mr-15">
            <div class="col-12">
              <div class="border-bottom pb-4">
                <div class="row">
                  <div class="col-lg-9 col-md-8">
                    <div class="section-title">
                      <h4 class="title mb-2">{{ $t("album.allalb") }}</h4>
                      <p class="text-muted mb-0">{{ $t("album.listalb") }}</p>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="form custom-form">
                      <div class="">
                        <label class="menu-arrow mb-5"
                          >{{ $t("acceuil.selectcat") }}
                        </label>
                        <v-select
                          v-model="categorie"
                          :items="catBoutiques"
                          item-text="libelle"
                          item-value="libelle"
                          :label="$t('acceuil.allcat')"
                          return-object
                          dense
                          solo
                        ></v-select>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row  ml-15 mr-15">
            <div
              class="col-xxs-8r col-xs-8r col-xsm-8r col-sm-8r col-md-8r col-lg-8r col-xlg-8r  mt-4 pt-2"
              style=""
              v-for="album in list"
              :key="album.id"
            >
              <album :album="album.albums"></album>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Job List End -->
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "../../i18n";
import album from "../../components/Album.vue";
import axios from "axios";
export default {
  components: { album },
  name: "Album",
  data() {
    return {
      list: [],
      date: new Date().getFullYear(),
    };
  },
  methods: {
    toggleMenu: function() {
      document.getElementById("isToggle").classList.toggle("open");
      var isOpen = document.getElementById("navigation");
      if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
      } else {
        isOpen.style.display = "block";
      }
    },
  },
  computed: {
    ...mapGetters(["catBoutiques", "albumsBoutiqueData", "idBtq"]),
    categorie: {
      get() {
        return {};
      },
      set(categorie) {
        var data = Object.assign({}, this.albumsBoutiqueData);
        var t = [];
        for (let x in data) {
          if (data[x].albums.categorie.idCategorie === categorie.idCategorie) {
            t.push(data[x]);
          }
        }
        this.list = Object.assign({}, t);
      },
    },
  },
  mounted: function() {
    let id = this.$store.state.home.idBtq;
    axios.get("auth/web/boutiques/nom/" + id).then((response) => {
      let boutique = response.data;
      if (boutique) {
        let bou = boutique.idBoutique;
        this.$store.commit("setIdBoutique", { idBtq: boutique.nom });
        this.$store.dispatch("getCateBoutiques", bou);
        this.$store.dispatch("getHomeAlbumsBoutique", bou);
        axios.get("auth/articles_boutique/" + bou).then((response) => {
          this.list = Object.assign({}, response.data);
          this.loading = false;
        });
      }
    });
  },
};
</script>

<style scoped>
@media screen and (min-width: 1550px) {
  .contain-xxxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}

@media screen and (min-width: 1400px) {
  .contain-xxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}
@media screen and (max-width: 1200px) {
  .contain-xl {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}

@media screen and (max-width: 992px) {
  .contain-lg {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .contain-md {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

@media screen and (max-width: 576px) {
  .contain-sm {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
}

@media screen and (max-width: 400px) {
  .contain-esm {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
}

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

/* .col-xs-8r {
  width: 38.9%;
  float: left;
  font-size: 7px;
} */

@media (max-width: 450px) {
  .col-xxs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 450px) {
  .col-xs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .col-sm-8r {
    width: 33%;
    float: left;
  }
}

@media (min-width: 750px) {
  .col-xsm-8r {
    width: 25%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-8r {
    width: 16.5%;
    float: left;
  }
}

@media (min-width: 1550px) {
  .col-xlg-8r {
    width: 12.5%;
    float: left;
  }
}
</style>
