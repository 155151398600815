<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="#256d7b">
      <v-col class="text-center white--text" cols="12">
        © 2019-2021 copyright S2A - {{ $t("allright") }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
};
</script>
