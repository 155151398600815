<template>
  <div>
    <!-- Hero Start -->
    <section
      class="bg-half-150 d-table w-100 h-1"
      style="background-color: #256d7b"
    >
      <div class="container" align="center">
        <v-carousel
          :continuous="true"
          :cycle="cycle"
          :show-arrows="false"
          hide-delimiter-background
          delimiter-icon="mdi-minus"
          height="250"
        >
          <v-carousel-item v-for="(slide, i) in desc" :key="i">
            <v-sheet color="#256d7b" height="100%" tile>
              <v-row class="fill-height" align="center" justify="center">
                <p class="font-weight-thin" style="font-size:30px">
                  {{ slide.desc }}
                </p>
                <div class="text-center mb-10">
                  <v-btn :to="slide.path" outlined rounded color="white" dark>
                    {{ slide.button }}
                  </v-btn>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Feature Start -->
    <!-- <section class="section pb-10">
      <div class="container">
        <div class="text-center mt-0 mb-10">
          <h3 class="text-center">{{ $t("publicite.titre2") }}</h3>
        </div>
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <i class="uil uil-edit-alt h1 text-primary"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ $t("home.body1") }}</h5>
                <p class="text-muted mb-0">{{ $t("home.body2") }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12 mt-5 mt-sm-0">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <i class="uil uil-vector-square h1 text-primary"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ $t("home.body3") }}</h5>
                <p class="text-muted mb-0">{{ $t("home.body4") }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12 mt-5 mt-sm-0">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <i class="uil uil-file-search-alt h1 text-primary"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ $t("home.body5") }}</h5>
                <p class="text-muted mb-0">{{ $t("home.body6") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!--end section-->
    <section class="section mt-5">
      <div
        class="contain-lg contain-md contain-sm contain-esm contain-xl contain-xxl contain-xxxl"
      >
        <div class="row ml-15 mr-15">
          <div class="auto">
            <div class="border-bottom pb-4">
              <div class="row">
                <div class="col-lg-9 col-md-8">
                  <div v-if="loading == false" class="section-title">
                    <h4 class="title mb-2">
                      {{ categorieBoutique.boutique.nom }}
                    </h4>
                    <p class="text-muted mb-0">
                      {{ $t("shops.adress") }}:
                      {{ categorieBoutique.boutique.adresse }}
                    </p>
                    <p class="text-muted mb-0">
                      {{ $t("shops.contact") }}:
                      {{ categorieBoutique.boutique.telephone }}
                    </p>
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="form custom-form">
                    <div class="" v-if="loading == false">
                      <label class="menu-arrow mb-5"
                        >{{ $t("acceuil.selectcat") }}
                      </label>
                      <v-select
                        v-model="categorie"
                        :items="catBoutiques"
                        item-text="libelle"
                        item-value="libelle"
                        :label="$t('acceuil.allcat')"
                        return-object
                        dense
                        solo
                      ></v-select>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div
          class="row ml-15 mr-15"
          v-for="item in list.albumsList"
          :key="item.idArticle"
        >
          <template v-if="item.albumsList[0] != null">
            <div class="col-12 mt-4 pt-2">
              <div class="section-title">
                <h5 class="mb-0">{{ item.categorie.libelle }}</h5>
              </div>
            </div>
            <!--end col-->

            <div
              class="col-xxs-8r col-xs-8r col-xsm-8r col-sm-8r col-md-8r col-lg-8r col-xlg-8r  mt-4 pt-2"
              style=""
              v-for="album in item.albumsList"
              :key="album.idArticle"
            >
              <album :album="album"></album>
            </div>
            <!--end col-->
          </template>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
  </div>
</template>

<style scoped></style>

<script>
import { mapGetters } from "vuex";
import album from "../../components/Album.vue";
import i18n from "../../i18n";
import axios from "axios";
export default {
  components: { album },
  data() {
    return {
      cycle: true,
      model: null,
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      list: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["catBoutiques", "albumsCatByBoutique", "idBtq"]),

    desc: function() {
      return [
        {
          path: `/${i18n.locale}/login`,
          desc: this.$t("home.desc1"),
          button: this.$t("home.seconnecter1"),
        },
        {
          path: `/${i18n.locale}/signup`,
          desc: this.$t("home.desc2"),
          button: this.$t("home.sinscrire1"),
        },
      ];
    },
    categorieBoutique() {
      // console.log(this.albumsCatByBoutique);
      if (this.albumsCatByBoutique) {
        return this.albumsCatByBoutique;
      } else {
        return {};
      }
    },

    categorie: {
      get() {
        return {};
      },
      set(categorie) {
        var data = Object.assign({}, this.albumsCatByBoutique);
        let t = null;
        data.albumsList.forEach(function(el) {
          if (el.categorie.idCategorie === categorie.idCategorie) {
            t = { albumsList: [el] };
          }
        });
        this.list = Object.assign({}, t);
      },
    },
  },

  created: function() {
    this.loading = true;
    let id = this.$route.params.id;
    axios.get("auth/web/boutiques/nom/" + id).then((response) => {
      let boutique = response.data;
      if (boutique) {
        let bou = boutique.idBoutique;
        this.$store.commit("setIdBoutique", { idBtq: id });
        this.$store.dispatch("getCateBoutiques", bou);
        axios.get("auth/articles_boutique_cat/" + bou).then((respons) => {
          this.list = Object.assign({}, respons.data);
          this.loading = false;
        });
      }
    });
  },
};
</script>

<style scoped>
@media screen and (min-width: 1550px) {
  .contain-xxxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}

@media screen and (min-width: 1400px) {
  .contain-xxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}
@media screen and (max-width: 1200px) {
  .contain-xl {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}

@media screen and (max-width: 992px) {
  .contain-lg {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .contain-md {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

@media screen and (max-width: 576px) {
  .contain-sm {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
}

@media screen and (max-width: 400px) {
  .contain-esm {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
}

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

/* .col-xs-8r {
  width: 38.9%;
  float: left;
  font-size: 7px;
} */

@media (max-width: 450px) {
  .col-xxs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 450px) {
  .col-xs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .col-sm-8r {
    width: 33%;
    float: left;
  }
}

@media (min-width: 750px) {
  .col-xsm-8r {
    width: 25%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-8r {
    width: 16.5%;
    float: left;
  }
}

@media (min-width: 1550px) {
  .col-xlg-8r {
    width: 12.5%;
    float: left;
  }
}
</style>
