<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0 col" style="">
          {{ this.oneAlbum.albums.designation }}
        </h4>
        <div>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                {{ $t("addFile") }}
              </v-btn>
            </template>
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title>
                <span class="text-h5">{{ $t("newFile") }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-file-input
                      v-model="files"
                      accept="image/png, image/jpg, image/jpeg, image/bmp"
                      :label="$t('albu.photo')"
                      multiple
                      prepend-icon="mdi-paperclip"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-row>
                  <v-row>
                    <v-file-input
                      v-model="videos"
                      accept="video/mp4,video/avi,video/webm,video/mkv,video/flv"
                      :label="$t('albu.video')"
                      multiple
                      prepend-icon="mdi-paperclip"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog2"
            persistent
            max-width="600px"
            class="col-auto"
          >
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title>
                <span class="text-h5">{{ $t("delete1") }} ?</span>
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close2">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteFile">
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="row p-4">
        <div class="container pb-10">
          <div class="row">
            <div
              class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
              v-for="image in images"
              :key="image.name"
            >
              <div class="card shop-list border-0 position-relative">
                <div
                  class="shop-image position-relative overflow-hidden rounded shadow"
                  style="box-shadow: 0px 0px 2px 2px rgb(60 72 88 / 15%) !important;"
                >
                  <img
                    :src="image.name"
                    class="img-fluid"
                    alt="fichier introuvable"
                    style="width:100%; min-height: 260px;"
                  />
                  <ul class="list-unstyled shop-icons">
                    <li class="mt-2">
                      <v-btn @click="openDialog(image)" color="red" dark icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end col-->
            <div
              class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
              v-for="video in myvideos"
              :key="video.name"
            >
              <div class="card shop-list border-0 position-relative">
                <div
                  class="shop-image position-relative overflow-hidden rounded shadow"
                  style="box-shadow: 0px 0px 2px 2px rgb(60 72 88 / 15%) !important;"
                >
                  <video
                    controls
                    :src="video.name"
                    class="img-fluid"
                    alt="fichier introuvable"
                    style="width:100%; min-height: 260px; max-height: 260px;"
                  />
                  <ul class="list-unstyled shop-icons">
                    <li class="mt-2">
                      <v-btn @click="openDialog(video)" color="red" dark icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { compressImage } from "../../helper";
export default {
  data: () => {
    return {
      file: {},
      myloading: false,
      alert: false,
      alert1: false,
      alert2: false,
      files: [],
      videos: [],
      file: 0,
      dialog: false,
      dialog2: false,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog1(val) {
      val || this.close1();
    },
    dialog2(val) {
      val || this.close2();
    },
  },
  computed: {
    ...mapGetters(["loading", "oneAlbum"]),
    nom: function() {
      if (this.image.name != undefined) {
        let splitted = this.image.name.split("/");
        return splitted[splitted.length - 1];
      } else return "";
    },
    images: function() {
      let images = [];
      this.oneAlbum.fichiers.forEach((fichier) => {
        let splitted = fichier.name.split(".");
        let ext = splitted[splitted.length - 1];
        if (!(ext == "mkv" || ext == "avi" || ext == "mp4")) {
          images.push(fichier);
        }
      });
      return images;
    },
    myvideos: function() {
      let videos = [];
      this.oneAlbum.fichiers.forEach((fichier) => {
        let splitted = fichier.name.split(".");
        let ext = splitted[splitted.length - 1];
        if (ext == "mkv" || ext == "avi" || ext == "mp4") {
          videos.push(fichier);
        }
      });
      return videos;
    },
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("getOneAlbum", id);
    this.$store.dispatch("userme");
  },
  methods: {
    copyURL() {
      const el = document.createElement("textarea");
      let url = window.location.href;
      let splitted = url.split(".com");
      let domain = splitted[0];
      let finalUrl = `${domain}.com/fr/albums/${this.$route.params.id}`;
      el.value = finalUrl;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    openDialog(file) {
      this.file = file;
      this.dialog2 = true;
    },
    setGallery(image) {
      this.image = image;
    },
    showDelete(id) {
      this.file = id;
      this.close2;
    },
    close() {
      this.myloading = false;
      this.dialog = false;
    },
    close1() {
      this.myloading = false;
      this.dialog1 = false;
    },
    close2() {
      this.myloading = false;
      this.file = {};
      this.dialog2 = false;
    },

    async save() {
      this.myloading = true;
      var file = this.files;
      var video = this.videos;
      var totalFileSize = 0;
      var totalVideoSize = 0;
      if (file.length != 0 || video.length != 0) {
        var max = this.$store.state.auth.file_max;
        var saved = this.$store.state.auth.file_size;
        var left = max - saved;
        let id = this.$route.params.id;
        var compressedFichier = [];
        let lang = this.$i18n.locale;
        if (file.length != 0) {
          compressedFichier = await this.myfunc(file);
          totalFileSize = await this.mysum(compressedFichier);
        }
        if (video.length != 0) {
          video.forEach(async (entry) => {
            console.log(entry.size);
            totalVideoSize = totalVideoSize + entry.size;
            console.log(totalVideoSize);
          });
        }
        var total = totalVideoSize + totalFileSize;
        if (total <= left) {
          try {
            await this.$store.dispatch("addFileToAlbum", {
              album: id,
              images: compressedFichier,
              videos: video,
            });
            await this.$store.dispatch("userme");
            this.$toast.success(this.$t("toast.save"));

            // this.alert = true;
          } catch (e) {
            this.$toast.error(this.$t("toast.error"));
          }
        } else {
          if (lang == "fr")
            this.$toast.error(
              "Vous avez atteint votre limite d'images.Veuillez vous rendre dans l'onglet Message pour sousrire à une offre "
            );

          if (lang == "en")
            this.$toast.error(
              "You have reached your limit of images, please go to the Message tab to subscribe to an offer"
            );
        }

        this.close();
      }
    },
    async myfunc(tab) {
      var myTab = [];
      for (var t in tab) {
        var cmp = await compressImage(tab[t]);
        myTab.push(cmp);
      }
      return myTab;
    },

    async mysum(tab) {
      var sum = 0;
      for (var t in tab) {
        sum = sum + tab[t].size;
      }
      return sum;
    },

    deleteFile() {
      this.myloading = true;
      let id = this.$route.params.id;
      try {
        this.$store
          .dispatch("deleteOneFile", { id: id, file: this.file })
          .then((response) => {
            this.alert2 = true;
            this.close2();
          });
      } catch (e) {}
    },
  },
};
</script>

/* suppression d'un article - selection d'un article - appel de la fonction -
fermeture du dialog - rechargement de la liste des fichiers */
