import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import toastification from './plugins/toastification';
import axios from "axios";
import i18n from "./i18n";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueMoment from "vue-moment";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

// axios.defaults.baseURL = 'http://localhost:9191/api/';
// axios.defaults.baseURL = 'http://192.168.0.107:8080/s2a-catalogue-dev/api/';
axios.defaults.baseURL =
  "https://shopymanager.com:8443/s2a-catalogue-dev-1/api/";

axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push({ name: "Login" }).catch(() => {});
    }
  }
});

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = "fr";
  }
  // set the current language for i18n.
  i18n.locale = language;

  next();
});
Vue.use(BootstrapVue);

const options = {
  maxToasts: 5,
  transition: "Vue-Toastification__fade",
  timeout: 4000,
  closeOnClick: false,
  draggable: false,
  pauseOnHover: true,
  pauseOnFocusLoss: false,
};

Vue.use(Toast, options);
Vue.use(VueMoment);
// Vue.use(VueGoogleAutocomplete, {
//   apiKey: 'AIzaSyB_0BToIz0M_Afw474-V2H9LhpODzrAFs0', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
// });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  // toastification,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
