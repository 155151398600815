<template>
  <div class="col  pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <h4 class="title mb-0 col" style="display: inline-block;">
              {{ $t("evenement.list") }}
            </h4>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.lazy.trim="query"
              append-icon="mdi-magnify"
              :label="$t('shops.search')"
              single-line
              hide-details
              clearable
              outlined
              dense
              rounded
              @input="searcheChange"
            >
            </v-text-field
          ></v-col>
        </v-row>
      </div>

      <v-dialog
        v-model="dialog"
        persistent
        max-width="700px"
        class="col-auto mt-15"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-5 mt-5" color="primary" dark v-bind="attrs" v-on="on">
            {{ $t("evenement.new") }}
          </v-btn>
        </template>

        <v-card :loading="myloading" :disabled="myloading" class="mt-15">
          <v-card-title>
            <span class="text-h5">{{ $t("evenement.new") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="defaultEvenement.nom"
                    :label="$t('evenement.name')"
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="defaultEvenement.contenu"
                    :label="$t('evenement.contenu')"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="defaultEvenement.pieces"
                    accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp"
                    :label="$t('evenement.select')"
                    multiple
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>

            <small>*{{ $t("required") }}</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="dialog = false">
              {{ $t("cancel") }}
            </v-btn>

            <v-btn color="blue darken-1" text @click="save">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog1" persistent max-width="600px" class="col-auto">
        <v-card :loading="myloading" :disabled="myloading">
          <v-card-title>
            <span class="text-h5">{{ $t("delete1") }} ?</span>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="close1">
              {{ $t("cancel") }}
            </v-btn>

            <v-btn color="blue darken-1" text @click="deleteAlbum">
              OK
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog2" persistent max-width="600px" class="col-auto">
        <v-card :loading="myloading" :disabled="myloading">
          <v-card-title>
            <span class="text-h5">{{ $t("evenement.update") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="defaultEvenement.nom"
                    :label="$t('evenement.name')"
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="defaultEvenement.contenu"
                    :label="$t('evenement.contenu')"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="defaultEvenement.pieces"
                    accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp"
                    :label="$t('evenement.select')"
                    multiple
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close2">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="update">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="row p-4">
        <div
          class="col-lg-4 col-md-6 mt-4 pt-2"
          v-for="alb in evenements"
          :key="alb.id"
        >
          <div class="card categories overflow-hidden rounded shadow border-0">
            <img
              :src="alb.pieces[0].url"
              class="img-fluid"
              alt="fichier introuvable"
            />
            <div class="card-body" style="padding: 0;">
              <ul class="list-unstyled d-flex justify-content-between mb-0">
                <li>
                  <a
                    class="title h7 text-dark gallery"
                    style="font-size: 15px !important"
                    >{{ alb.contenu }}</a
                  >
                </li>
                <li>
                  <v-btn @click="openDialog(alb)" color="red" dark icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </li>
                <li>
                  <v-btn @click="openDialog1(alb)" color="green" dark icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--end row-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
import te from "/public/js/plugins.init.js";
import tr from "/public/js/tobii.min.js";
import tt from "/public/js/bootstrap.bundle.min.js";
import td from "/public/js/shuffle.min.js";
import { compressImage } from "../../helper";
export default {
  data: () => {
    return {
      albu: {},
      myloading: false,
      dialog1: false,
      dialog2: false,
      alert1: false,
      alert2: false,
      alert: false,
      files: [],
      dialog: false,
      categories: [1, 2, 3],
      query: "",

      defaultEvenement: {
        nom: "",
        contenu: "",
        pieces: [],
      },

      initialEvenement: {
        id: 0,
        nom: "",
        contenu: "",
        pieces: [],
      },
    };
  },

  watch: {
    dialog1(val) {
      val || this.close1();
    },
  },

  computed: {
    ...mapGetters(["loading", "evenements"]),

    tab: function() {
      return [this.$t("stock"), this.$t("command")];
    },
  },

  created() {
    this.$store.dispatch("getEvenement");
  },

  methods: {
    openDialog(alb) {
      this.albu = alb;
      this.dialog1 = true;
    },

    openDialog1(alb) {
      this.albu = alb;
      this.dialog2 = true;
      this.defaultEvenement = {
        nom: this.albu.nom,
        contenu: this.albu.contenu,
      };
    },

    searcheChange() {
      if (this.query) {
        this.$store.dispatch("getEvenementSearch", this.query);
        // this.$store.dispatch("getAlbumsSearch", {
        //   page: 1,
        //   search: this.query,
        // });
      } else {
        this.$store.dispatch("getEvenement");
        // this.$store.dispatch("getAlbumsByUsers", 1);
      }
    },

    setGallery(image) {
      this.image = image;
    },

    close1() {
      this.myloading = false;
      this.dialog1 = false;
      this.albu = {};
      this.$nextTick(() => {
        this.defaultEvenement = Object.assign({}, this.initialEvenement);
      });
    },

    close2() {
      this.myloading = false;
      this.dialog2 = false;
      this.albu = {};
      this.$nextTick(() => {
        this.defaultEvenement = {};
      });
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.defaultEvenement = Object.assign({}, this.initialEvenement);
      });
    },

    async deleteAlbum() {
      this.myloading = true;
      try {
        console.log(this.initialEvenement);
        await this.$store.dispatch("deleteOneEvenement", this.albu.id);
        this.alert1 = true;
        this.close1();
      } catch (e) {}
    },

    async tr(file) {
      let compressed = await compressImage(file);
      return compressed;
    },

    async save() {
      this.myloading = true;
      var nom = this.defaultEvenement.nom;
      var contenu = this.defaultEvenement.contenu;
      var pieces = this.defaultEvenement.pieces;
      let lang = this.$i18n.locale;
      if (nom != "" && contenu != "" && pieces != null) {
        var compressedFichier = [];
        var totalFileSize = 0;
        var totalVideoSize = 0;

        if (pieces.length != 0) {
          compressedFichier = await this.myfunc(pieces);
          totalFileSize = await this.mysum(compressedFichier);
        }

        let itemToSave = {
          nom: this.defaultEvenement.nom,
          contenu: this.defaultEvenement.contenu,
          pieces: compressedFichier,
        };
        var max = this.$store.state.auth.file_max;
        var saved = this.$store.state.auth.file_size;
        var left = max - saved;
        var total = totalVideoSize + totalFileSize;
        if (total <= left) {
          try {
            await this.$store.dispatch("postEvenement", itemToSave);
            await this.$store.dispatch("userme");
            this.$toast.success(this.$t("toast.save"));
            this.close();
          } catch (e) {
            this.$toast.error(this.$t("toast.error"));
            this.myloading = false;
          }
        } else {
          if (lang == "fr")
            this.$toast.error(
              "Vous avez atteint votre limite d'images.Veuillez vous rendre dans l'onglet Message pour sousrire à une offre "
            );

          if (lang == "en")
            this.$toast.error(
              "You have reached your limit of images, please go to the Message tab to subscribe to an offer"
            );
        }
      }
      this.myloading = false;
    },

    async myfunc(tab) {
      var myTab = [];
      for (var t in tab) {
        var cmp = await compressImage(tab[t]);
        myTab.push(cmp);
      }
      return myTab;
    },

    async mysum(tab) {
      var sum = 0;
      for (var t in tab) {
        sum = sum + tab[t].size;
      }
      return sum;
    },

    async update() {
      this.myloading = true;
      var nom = this.defaultEvenement.nom;
      var contenu = this.defaultEvenement.contenu;
      var pieces = this.defaultEvenement.pieces;
      let itemToSave;
      if (nom != "" && contenu != "") {
        var compressedFichier = [];

        if (pieces) {
          if (pieces.length != 0) {
            compressedFichier = await this.myfunc(pieces);
          }
        }

        itemToSave = {
          id: this.albu.id,
          nom: this.defaultEvenement.nom,
          contenu: this.defaultEvenement.contenu,
          pieces: compressedFichier,
        };

        try {
          await this.$store.dispatch("updateEvenement", itemToSave);
          await this.$store.dispatch("userme");
          this.$toast.success(this.$t("toast.save"));
          this.close2();
        } catch (e) {
          this.$toast.error(this.$t("toast.error"));
        }
        this.myloading = false;
      }
    },
  },
};
</script>
