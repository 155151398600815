import axios from 'axios';

const state = {
	devies: [],
	taux: null,
	loading: true,
};

const getters = {
	devies: (state) => state.devies,
	taux: (state) => state.taux,
};

const actions = {

	async getTaux({ commit, rootState }) {
		let response = await axios.get('http://localhost:9192/web/service/auth/taux/echange/oanke');
		let data = response.data;
		// console.log(data.taux)
		commit('setTaux', { taux: data })
	},
	async getDevies({ commit, rootState }) {
		commit('setDevies', { devies: [] })
		let token = rootState.auth.token;
		let response = await axios.get('devises', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setDevies', { devies: data })
	},
	async updateDevise({ dispatch, rootState }, devise) {
		let token = rootState.auth.token;
		await axios.put('devise/' + devise.id, devise, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getDevies")
	},
	async postDevise({ dispatch, rootState }, devise) {
		let token = rootState.auth.token;
		await axios.post('devise', devise, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getDevies")
	},
	async deleteDevise({ dispatch, rootState }, devise) {
		let token = rootState.auth.token;
		await axios.delete('devise/' + devise.id, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getDevies")
	},
};

const mutations = {
	loading(state) {
		state.loading = true;
	},

	setDevies(state, { devies }) {
		state.devies = devies;
		state.loading = false;
	},
	setTaux(state, { taux }) {
		state.taux = taux;
		state.loading = false;
	},
	setCheckCategorieName(state, { checkCategorieName }) {
		state.checkCategorieName = checkCategorieName;
		state.loading = false;
	},


};

export default {
	state,
	getters,
	actions,
	mutations,
};