import axios from 'axios';

const state = {
	evenements: [],
	evenementPage: [],
	oneEvenement: {},
	loading: true
};

const getters = {
	oneEvenement: (state) => state.oneEvenement,
	evenements: (state) => state.evenements,
	evenementPage: (state) => state.evenementPage,
};

const actions = {
	async getEvenement({ commit, rootState }) {
		let token = rootState.auth.token;
		let response = await axios.get('evenements', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setEvenements', { evenements: data })
	},
	async getEvenementSearch({ commit, rootState }, s) {
		let token = rootState.auth.token;
		let response = await axios.get('evenements/search/'+s, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setEvenements', { evenements: data })
	},
	async getEvenementPage({ commit, rootState }, page) {
		let token = rootState.auth.token;
		let response = await axios.get('evenements/page/' + page, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setEvenementPage', { evenementPage: data })
	},
	async getOneEvenement({ commit, rootState }, id) {
		let token = rootState.auth.token;
		let response = await axios.get('evenements/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;

		commit('setOneEvenement', { oneEvenement: data })
	},
	async deleteOneEvenement({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		let response = await axios.delete('evenement/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		dispatch('getEvenement')
	},
	async postEvenement({ dispatch, rootState }, evenement) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('nom', evenement.nom);
		bodyFormData.append('contenu', evenement.contenu);
		evenement.pieces.forEach(element => {
			bodyFormData.append('pieces', element, element.name);
		});
		await axios.post('evenement',
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getEvenement")
	},
	async updateEvenement({ dispatch, rootState }, evenement) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('nom', evenement.nom);
		bodyFormData.append('contenu', evenement.contenu);
		evenement.pieces.forEach(element => {
			bodyFormData.append('pieces', element, element.name);
		});
		await axios.put('evenement/' + evenement.id,
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getEvenement")
	},
};

const mutations = {
	loading(state) {
		state.loading = true;
	},

	setEvenements(state, { evenements }) {
		state.evenements = evenements;
		state.loading = false;
	},
	setEvenementPage(state, { evenementPage }) {
		state.evenementPage = evenementPage;
		state.loading = false;
	},
	setOneEvenement(state, { oneEvenement }) {
		state.oneEvenement = oneEvenement;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};