<template>
  <div>
    <!-- Hero Start -->
    <section
      class="bg-half d-table w-100 h-1"
      style="background-color: #256d7b"
    >
      <div class="container" align="center">
        <v-carousel
          :continuous="true"
          :cycle="cycle"
          :show-arrows="false"
          hide-delimiter-background
          delimiter-icon="mdi-minus"
          height="350"
        >
          <v-carousel-item v-for="(slide, i) in desc" :key="i">
            <v-sheet color="#256d7b" height="100%" tile>
              <v-row class="fill-height" align="center" justify="center">
                <p class="font-weight-thin" style="font-size:40px">
                  {{ slide.desc }}
                </p>
                <div class="text-center mb-10">
                  <v-btn :to="slide.path" outlined rounded color="white" dark>
                    {{ slide.button }}
                  </v-btn>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Feature Start -->
    <section class="section mt-5 mr-5 ml-5">
      <div class="container">
        <div class="text-center  mb-10 mt-5">
          <h3 class="text-center">{{ $t('publicite.titre2') }}</h3>
        </div>
        <div class="row">
          <div class="col-md-4 col-12 mt-5 mt-sm-0">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <i class="uil uil-edit-alt h1 text-primary"></i>
              </div>
              <div>
                <h5>{{ $t('home.body1') }}</h5>
                <p class="text-muted mb-0">{{ $t('home.body2') }}</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5 mt-sm-0">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <i class="uil uil-vector-square h1 text-primary"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ $t('home.body3') }}</h5>
                <p class="text-muted mb-0">{{ $t('home.body4') }}</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5 mt-sm-0">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <i class="uil uil-file-search-alt h1 text-primary"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ $t('home.body5') }}</h5>
                <p class="text-muted mb-0">{{ $t('home.body6') }}</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature Start -->
    <section class="section pt-2 mt-0 pt-0">
      <div class="container">
        <div class="text-center  mb-10 mt-5">
          <h3 class="text-center">{{ $t('publicite.titre4') }}</h3>
        </div>
        <v-row>
          <v-col
            v-for="n in matchedAlbumNew"
            :key="n.albums.idArticle"
            cols="12"
            md="4"
          >
            <album :album="n.albums"></album>

            <!-- <router-link
              :to="{ path: `/${$i18n.locale}/albums/${+n.albums.idArticle}` }"
              class="i-album text-primary "
            >
              <v-card class="ma-4" dark height="280">
                <v-img
                  :src="n.albums.photo_entete"
                  class="fill-height repeating-gradient"
                  alt="fichier introuvable"
                >
                  <div class="fill-height repeating-gradient"></div>
                </v-img>
              </v-card>
            </router-link> -->
          </v-col>
        </v-row>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature Start -->
    <section class="section pt-2 mt-5 mr-5 ml-5">
      <div class="container">
        <div class="text-center mb-10">
          <h3 class="text-center">{{ $t('publicite.titre') }}</h3>
        </div>
        <v-row>
          <v-col v-for="n in matchedPub" :key="n.id" cols="12" md="6">
            <v-card class="ma-4" dark>
              <a class="text-dark fw-bold" :href="n.lien">
                <v-img
                  :src="n.pieces[0].url"
                  class="fill-height repeating-gradient white lighten-2"
                  alt="fichier introuvable"
                  aspect-ratio="1"
                  contain
                >
                  <!-- <div class="fill-height repeating-gradient"></div> -->
                </v-img>
              </a>
            </v-card>
          </v-col>
        </v-row>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature Start -->
    <section class="section pt-2 mt-0 pt-0">
      <div class="container">
        <div class="text-center  mb-10 mt-5">
          <h3 class="text-center">{{ $t('publicite.titre3') }}</h3>
        </div>
        <v-row>
          <v-col
            v-for="n in matchedAlbumLick"
            :key="n.albums.idArticle"
            cols="12"
            md="4"
          >
            <album :album="n.albums"></album>

            <!-- <router-link
              :to="{ path: `/${$i18n.locale}/albums/${+n.albums.idArticle}` }"
              class="i-album text-primary "
            >
              <v-card class="ma-4" dark height="280">
                <v-img
                  :src="n.albums.photo_entete"
                  class="fill-height repeating-gradient"
                  alt="fichier introuvable"
                >
                  <div class="fill-height repeating-gradient"></div>
                </v-img>
              </v-card>
            </router-link> -->
          </v-col>
        </v-row>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
  </div>
</template>

<style scoped>
h3 {
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-weight: 500;
  position: relative;
  color: #256d7b;
}
h3::before {
  content: '';
  position: absolute;
  display: block;
  width: 100px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
h3::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #061ee4;
  bottom: 0;
  left: calc(50% - 20px);
}
</style>

<script>
import { mapGetters } from 'vuex';
import i18n from '../../i18n';
import axios from 'axios';
import album from '../../components/AlbumAcceuil.vue';

export default {
  components: { album },

  data: () => {
    return {
      cycle: true,
      model: null,
      slides: ['First', 'Second', 'Third', 'Fourth', 'Fifth'],
    };
  },
  computed: {
    desc: function() {
      return [
        {
          path: `/${i18n.locale}/login`,
          desc: this.$t('home.desc1'),
          button: this.$t('home.seconnecter1'),
        },
        {
          path: `/${i18n.locale}/signup`,
          desc: this.$t('home.desc2'),
          button: this.$t('home.sinscrire1'),
        },
      ];
    },

    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },

    matchedAlbumLick() {
      // console.log(this.albumLick);

      if (this.albumLick) {
        return this.albumLick;
      } else {
        return [];
      }
    },

    matchedAlbumNew() {
      // console.log(this.albumsNew);
      if (this.albumsNew) {
        return this.albumsNew;
      } else {
        return [];
      }
    },

    matchedPub() {
      if (this.authPublicitesPage) {
        return this.authPublicitesPage;
      } else {
        return [];
      }
    },

    ...mapGetters([
      'albumsByCategories',
      'categories',
      'albums',
      'categorie',
      'lng',
      'albumLick',
      'albumNew',
      'albumsNew',
      'authPublicitesPage',
    ]),
  },

  methods: {
    changedtofr: function() {
      i18n.locale = 'fr';
      this.$store.commit('setlng', { lang: this.$t('lang1') });

      let language = this.$route.params.lang;
      if (language === 'en') {
        let splitted = this.$route.path.split(`${language}`);
        let url = '/fr' + splitted[1];
        this.$router.push(url);
      }
    },
    changedtoen: function() {
      i18n.locale = 'en';
      this.$store.commit('setlng', { lang: this.$t('lang2') });

      let language = this.$route.params.lang;
      if (language != 'en') {
        let splitted = this.$route.path.split(`${language}`);
        let url = '/en' + splitted[1];
        this.$router.push(url);
      }
    },
    toggleMenu: function() {
      document.getElementById('isToggle').classList.toggle('open');
      var isOpen = document.getElementById('navigation');
      if (isOpen.style.display === 'block') {
        isOpen.style.display = 'none';
      } else {
        isOpen.style.display = 'block';
      }
    },
    closetoggle: function() {
      var $isOpen = document.getElementById('navigation');
      if ($isOpen.style.display === 'block') {
        document.getElementById('isToggle').classList.toggle('open');
      }
      $isOpen.style.display = 'none';
    },
    goTo(url) {
      this.closetoggle();
      this.$router.push(url);
      window.scrollTo(0, 0);
    },

    async fetch() {
      await Promise.all([
        this.$store.dispatch('getCategories'),
        this.$store.dispatch('getAlbums'),

        this.$store.dispatch('getAlbumsLick', 1),
        this.$store.dispatch('getAlbumsNews', 1),
        this.$store.dispatch('getAuthPublicitesPage', 1),
      ]);
    },
  },
  mounted: function() {
    console.log(this.$route.query);
    if (this.$route.query.PayerID) {
      this.$store.dispatch('confPaied', this.$route.query);
    }
    this.fetch();
    // await Promise.all([

    // this.$store.dispatch("getCategories");
    // this.$store.dispatch("getAlbums");

    // this.$store.dispatch("getAlbumsLick", 1);
    // this.$store.dispatch("getAlbumsNew", 1);
    // this.$store.dispatch("getAuthPublicitePage", 2);
    // ]);
    let language = this.$route.params.lang;
    if (language === 'en') {
      this.$store.commit('setlng', { lang: 'English' });
    } else if (language === 'fr') {
      this.$store.commit('setlng', { lang: 'Français' });
    }
  },
};
</script>
