<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t("shops.list") }}</h4>
      </div>

      <div class="p-1">
        <v-card>
          <div class="row">
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
            <div class="col-12 col-sm-6 text-right pr-5">
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="dialog = true">
                {{ $t("shops.new") }}
              </v-btn>
            </div>
          </div>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="boutiquesByUsers"
            class="elevation-1"
          >
            <template #[`item.actif`]="{ item }">
              <v-chip v-if="item.actif">
                {{ $t("shops.etat") }}
              </v-chip>
              <v-chip color="red" text-color="white" v-else>
                {{ $t("shops.etat1") }}
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
              <v-icon small class="ml-2" @click="copyLink(item)">
                mdi-content-copy
              </v-icon>
            </template>
          </v-data-table>
          <v-dialog v-model="dialog" max-width="700px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.nom"
                        :label="$t('shops.nameshop')"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.adresse"
                        :label="$t('shops.adressshop')"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.telephone"
                        :label="$t('shops.contactshop')"
                        type="number"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.lien"
                        :label="$t('shops.link')"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="editedItem.pays"
                        :items="listPays"
                        item-text="nom"
                        item-value="id"
                        autocomplete="off"
                        autofocus
                        :label="$t('shops.country')"
                        return-object
                        chips
                        deletable-chips
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="editedItem.typeBoutique"
                        :items="listTypes"
                        item-text="libelle"
                        item-value="id"
                        autocomplete="off"
                        autofocus
                        :label="$t('shops.type')"
                        return-object
                        chips
                        deletable-chips
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="editedItem.image"
                        accept="image/png, image/jpg, image/jpeg, image/bmp, image/webp"
                        prepend-icon="mdi-camera"
                        :label="$t('albu.header')"
                        required
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.description"
                        :label="$t('shops.description')"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5">{{ $t("delete1") }} ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { compressImage } from "../../helper";

export default {
  data: () => {
    return {
      myloading: false,
      alert: false,
      search: "",
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        nom: "",
        adresse: "",
        telephone: "",
        lien: "",
        description: "",
        pays: null,
        typeBoutique: null,
        image: null,
      },
      defaultItem: {
        id: 0,
        nom: "",
        adresse: "",
        telephone: "",
        lien: "",
        description: "",
        pays: null,
        typeBoutique: null,
        image: null,
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("shops.name"),
          align: "center",
          sortable: false,
          value: "nom",
        },
        {
          text: this.$t("shops.adress"),
          align: "center",
          sortable: false,
          value: "adresse",
        },
        {
          text: this.$t("shops.contact"),
          align: "center",
          sortable: false,
          value: "telephone",
        },
        {
          text: this.$t("shops.activer"),
          align: "center",
          sortable: false,
          value: "actif",
        },
        { text: this.$t("shops.actions"), value: "actions", sortable: false },
      ];
    },

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("shops.newshop")
        : this.$t("shops.updateshop");
    },

    ...mapGetters(["loading", "boutiquesByUsers", "listPays", "listTypes"]),
  },
  created() {
    this.initialize();
    this.$store.dispatch("getBoutiquesByUsers");
    this.$store.dispatch("getAllPays");
    this.$store.dispatch("getAllTypes");
  },
  methods: {
    initialize() {},

    editItem(item) {
      this.editedIndex = this.boutiquesByUsers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    copyLink(item) {
      const el = document.createElement("textarea");
      let url = window.location.href;
      let splitted = url.split(".com");
      let domain = splitted[0];
      let btname = item.nom;
      let btnamereplace = btname.replaceAll(" ", "%20");
      // let tb = btname.trim().split(" ");
      // let space = " ";
      // let tb1 = [];
      // for (let index = 0; index < tb.length; index++) {
      //   if (tb[index] === "") {
      //   } else {
      //     tb1.push(tb[index]);
      //   }
      // }
      // let bn = tb1.join("%20");
      let finalUrl = `${domain}.com/fr/shopHome/${btnamereplace}`;
      el.value = finalUrl;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$toast.success(this.$t("copyed"));
    },

    deleteItem(item) {
      this.editedIndex = this.boutiquesByUsers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.myloading = true;
      await this.$store.dispatch("deleteBoutique", this.editedItem);
      this.closeDelete();
    },

    editItem(item) {
      this.editedIndex = this.boutiquesByUsers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.myloading = false;
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.myloading = true;
      var nom = this.editedItem.nom;
      var adresse = this.editedItem.adresse;
      var tel = this.editedItem.telephone;
      var tof = this.editedItem.image;

      if (nom != "" && adresse != "" && tel != "") {
        let lang = this.$i18n.locale;
        let token = this.$store.state.auth.token;

        let imageCompressed = null;
        if (tof) {
          imageCompressed = await compressImage(tof);
        } else {
          imageCompressed = null;
        }

        let itemToSave = {
          idBoutique: this.editedItem.idBoutique,
          typeBoutique: this.editedItem.typeBoutique,
          pays: this.editedItem.pays,
          nom: this.editedItem.nom,
          telephone: this.editedItem.telephone,
          adresse: this.editedItem.adresse,
          lien: this.editedItem.lien,
          description: this.editedItem.description,
          image: imageCompressed,
        };

        if (this.editedIndex > -1) {
          await axios
            .get(
              `check/update/nom/${itemToSave.idBoutique}/` + itemToSave.nom,
              {
                headers: { Authorization: "Bearer " + token },
              }
            )
            .then((response) => {
              let pass = !response.data;

              if (pass) {
                this.$store.dispatch("updateBoutique", itemToSave);
                this.$toast.success(this.$t("toast.update"));
              }
              if (!pass) {
                if (lang == "fr")
                  this.$toast.error(
                    "Le nom de la categorie existe déjà.Veuillez ajouter une description supplémentaire"
                  );

                if (lang == "en")
                  this.$toast.error(
                    "The name of the catogery already exists.Please add a description to it"
                  );
              }
            });
        } else {
          try {
            await axios
              .get(`check/nom/` + itemToSave.nom, {
                headers: { Authorization: "Bearer " + token },
              })
              .then((response) => {
                let pass = !response.data;

                if (pass) {
                  this.$store.dispatch("postBoutique", itemToSave);
                  this.$toast.success(this.$t("toast.save"));
                }
                if (!pass) {
                  if (lang == "fr")
                    this.$toast.error(
                      "Le nom de la categorie existe déjà.Veuillez ajouter une description supplémentaire"
                    );

                  if (lang == "en")
                    this.$toast.error(
                      "The name of the catogery already exists.Please add a description to it"
                    );
                }
              });

            this.alert = true;
          } catch (e) {
            console.log(e);
          }
        }
        this.close();
      }
    },
  },
};
</script>
