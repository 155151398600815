<template>
  <v-app>
    <div class="mt-10" id="">
      <!-- Job List Start -->
      <section class="section">
        <div
          class="contain-lg contain-md contain-sm contain-esm contain-xl contain-xxl contain-xxxl"
        >
          <div class="row ml-15 mr-15">
            <div class="col-12">
              <div class="border-bottom pb-4">
                <div class="row">
                  <div class="col-12 col-sm-3">
                    <div class="section-title">
                      <h4 class="title mb-2">{{ $t("album.allalb") }}</h4>
                      <p class="text-muted mb-0">{{ $t("album.listalb") }}</p>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <v-text-field
                      v-model.lazy.trim="query"
                      append-icon="mdi-magnify"
                      :label="$t('shops.search')"
                      single-line
                      hide-details
                      clearable
                      outlined
                      dense
                      rounded
                      @input="searcheChange"
                    >
                    </v-text-field>
                  </div>
                  <!--end col-->

                  <div class="col-12 col-sm-3 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="form custom-form">
                      <div class="">
                        <label class="menu-arrow mb-5"
                          >{{ $t("acceuil.selectcat") }}
                        </label>
                        <v-select
                          v-model="categorie"
                          :items="types"
                          item-text="libelle"
                          item-value="libelle"
                          :label="$t('acceuil.allcat')"
                          return-object
                          dense
                          solo
                        ></v-select>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row ml-15 mr-15">
            <div
              class="col-xxs-8r col-xs-8r col-xsm-8r col-sm-8r col-md-8r col-lg-8r col-xlg-8r  mt-4 pt-2"
              style=""
              v-for="album in albums.data"
              :key="album.albums.idArticle"
            >
              <album :album="album.albums"></album>
            </div>

            <div class="d-flex flex-column mt-5">
              <b-pagination
                class="align-self-center"
                @change="handlePageChange"
                :total-rows="totalRow"
                :per-page="perPage"
                v-model="page"
              ></b-pagination>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Job List End -->
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "../i18n";
import axios from "axios";
import album from "../components/Album.vue";
export default {
  components: { album },
  name: "Album",
  data: () => {
    return {
      page: 1,
      query: "",
      // pageSize: 12,
      // totalRow: 0,
      date: new Date().getFullYear(),
    };
  },
  methods: {
    toggleMenu: function() {
      document.getElementById("isToggle").classList.toggle("open");
      var isOpen = document.getElementById("navigation");
      if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
      } else {
        isOpen.style.display = "block";
      }
    },
  },
  computed: {
    ...mapGetters(["categories", "types", "albums"]),

    totalRow() {
      if (this.albums) {
        return this.albums.total;
      } else {
        return 0;
      }
    },

    perPage() {
      if (this.albums) {
        return this.albums.per_page;
      } else {
        return 0;
      }
    },

    categorie: {
      get() {
        return {};
      },
      set(categorie) {
        this.$store.commit("setType", categorie);
        this.$router
          .push(`/${i18n.locale}/categorie/` + categorie.id)
          .catch(() => {});
        // this.$store.dispatch('getAlbumsByCategorie', categorie.idCategorie);
      },
    },
  },
  methods: {
    handlePageChange(page) {
      if (this.query) {
        if (page) {
          this.$store.dispatch("getAlbumsSearch", { page, search: this.query });
        } else {
          this.$store.dispatch("getAlbumsSearch", {
            page: 1,
            search: this.query,
          });
        }
      } else {
        if (page) {
          this.$store.dispatch("getAlbums", page);
        } else {
          this.$store.dispatch("getAlbums", 1);
        }
      }
    },

    searcheChange() {
      if (this.query) {
        this.$store.dispatch("getAlbumsSearch", {
          page: 1,
          search: this.query,
        });
      } else {
        this.$store.dispatch("getAlbums", 1);
      }
    },
  },
  mounted: function() {
    this.$store.dispatch("getAlbums", 1);
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getAllTypeBoutique");
  },
};
</script>

<style scoped>
@media screen and (min-width: 1550px) {
  .contain-xxxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}

@media screen and (min-width: 1400px) {
  .contain-xxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}
@media screen and (max-width: 1200px) {
  .contain-xl {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}

@media screen and (max-width: 992px) {
  .contain-lg {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .contain-md {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

@media screen and (max-width: 576px) {
  .contain-sm {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
}

@media screen and (max-width: 400px) {
  .contain-esm {
    margin-right: -60px !important;
    margin-left: -60px !important;
  }
}

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

/* .col-xs-8r {
  width: 38.9%;
  float: left;
  font-size: 7px;
} */

@media (max-width: 450px) {
  .col-xxs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 450px) {
  .col-xs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .col-sm-8r {
    width: 33%;
    float: left;
  }
}

@media (min-width: 750px) {
  .col-xsm-8r {
    width: 25%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-8r {
    width: 16.5%;
    float: left;
  }
}

@media (min-width: 1550px) {
  .col-xlg-8r {
    width: 12.5%;
    float: left;
  }
}
</style>
