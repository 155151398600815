<template>
  <v-app>
    <div class="" id="">
      <!-- Start Terms & Conditions -->
      <section class="section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div class="card shadow border-0 rounded">
                <div class="card-body">
                  <h5 class="card-title">Introduction :</h5>
                  <p class="text-muted">{{ $t('contact.introdesc') }}.</p>

                  <h5 class="card-title">{{ $t('contact.us') }} :</h5>
                  <p class="text-muted">{{ $t('contact.desc') }}.</p>

                  <h5 class="card-title">{{ $t('contact.condition') }} :</h5>
                  <p class="text-muted">{{ $t('contact.conditiondesc') }}.</p>

                  <h5 class="card-title">{{ $t('contact.restrictions') }} :</h5>
                  <p class="text-muted">
                    {{ $t('contact.restrictionsdesc') }} :
                  </p>
                  <ul class="list-unstyled text-muted">
                    <li>
                      <i data-feather="arrow-right" class="fea icon-sm me-2"></i
                      >{{ $t('contact.restrictions1') }}
                    </li>
                    <li>
                      <i data-feather="arrow-right" class="fea icon-sm me-2"></i
                      >{{ $t('contact.restrictions2') }}
                    </li>
                    <li>
                      <i data-feather="arrow-right" class="fea icon-sm me-2"></i
                      >{{ $t('contact.restrictions3') }}
                    </li>
                    <li>
                      <i data-feather="arrow-right" class="fea icon-sm me-2"></i
                      >{{ $t('contact.restrictions4') }}
                    </li>
                  </ul>
                  <h5 class="card-title mt-10">{{ $t('contact.tarif') }}</h5>
                  <template>
                    <v-simple-table class="mb-5">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left text-muted">
                              {{ $t('contact.offre') }}
                            </th>
                            <th class="text-left text-muted">
                              {{ $t('contact.free') }}<br />
                            </th>
                            <th class="text-left text-muted">
                              {{ $t('contact.elementary') }}
                            </th>
                            <th class="text-left text-muted">
                              {{ $t('contact.elite') }}
                            </th>
                            <th class="text-left text-muted">
                              {{ $t('contact.enterprise') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ $t('contact.limitsite') }}</td>
                            <td>10M</td>
                            <td>1G</td>
                            <td>5G</td>
                            <td>{{ $t('contact.quantite') }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t('contact.limitimg') }}</td>
                            <td>10M</td>
                            <td>10M</td>
                            <td>10M</td>
                            <td>{{ $t('contact.quantite') }}</td>
                          </tr>

                          <tr>
                            <td>{{ $t('contact.souscrire') }}</td>
                            <td>
                              <router-link
                                class="text-dark fw-bold"
                                :to="{ path: `/${$i18n.locale}/login` }"
                              >
                                <v-chip
                                  small
                                  class="ma-2"
                                  color="#256d7b"
                                  text-color="white"
                                >
                                  {{ $t('login') }}
                                </v-chip></router-link
                              >
                            </td>
                            <td>
                              <!-- <router-link
                                class="text-dark fw-bold"
                                :to="{
                                  path: `/${
                                    $i18n.locale
                                  }/souscription/${+elementaire}`,
                                }"
                              >
                                <v-chip
                                  small
                                  class="ma-2"
                                  color="#256d7b"
                                  text-color="white"
                                >
                                  {{ $t("contact.souscrire") }}
                                </v-chip></router-link
                              > -->
                            </td>
                            <td>
                              <!-- <router-link
                                class="text-dark fw-bold"
                                :to="{
                                  path: `/${
                                    $i18n.locale
                                  }/souscription/${+elite}`,
                                }"
                              >
                                <v-chip
                                  small
                                  class="ma-2"
                                  color="#256d7b"
                                  text-color="white"
                                >
                                  {{ $t("contact.souscrire") }}
                                </v-chip></router-link
                              > -->
                            </td>
                            <td>
                              <!-- <router-link
                                class="text-dark fw-bold"
                                :to="{
                                  path: `/${
                                    $i18n.locale
                                  }/souscription/${+entreprise}`,
                                }"
                              >
                                <v-chip
                                  small
                                  class="ma-2"
                                  color="#256d7b"
                                  text-color="white"
                                >
                                  {{ $t("contact.souscrire") }}
                                </v-chip></router-link
                              > -->
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                  <!-- <h5 class="card-title mt-10">{{ $t("contact.mode") }}</h5>
                  <template>
                    <v-row>
                      <v-col>
                        <v-card class="mx-auto" outlined>
                          <v-list-item two-line>
                            <v-list-item-content>
                              <div class="text-overline mb-4">
                                {{ $t("contact.transfert") }}
                              </div>
                              <v-list-item-title class="text-h5 mb-1">
                                {{ $t("contact.togocom") }}
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-avatar tile size="80">
                              <v-img src="/tmoney.png" contain></v-img>
                            </v-list-item-avatar>
                          </v-list-item>

                          <v-card-text>{{ $t("contact.depot") }}</v-card-text>

                          <v-card-actions>
                            <v-btn outlined rounded text>
                              (+228) 92254104
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card class="mx-auto" outlined>
                          <v-list-item two-line>
                            <v-list-item-content>
                              <div class="text-overline mb-4">
                                {{ $t("contact.transfert") }}
                              </div>
                              <v-list-item-title class="text-h5 mb-1">
                                {{ $t("contact.flooz") }}
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-avatar tile size="80">
                              <v-img src="/flooz.png" contain></v-img>
                            </v-list-item-avatar>
                          </v-list-item>

                          <v-card-text>{{ $t("contact.depot") }}</v-card-text>

                          <v-card-actions>
                            <v-btn outlined rounded text>
                              (+228) 97133838
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card class="mx-auto" max-height="400" outlined>
                          <v-list-item>
                            <v-list-item-content>
                              <div class="text-overline mb-4">
                                {{ $t("contact.transfert") }}
                              </div>
                              <v-list-item-title class="text-h5 mb-1">
                                {{ $t("contact.banque") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-card-text class="pt-6">{{
                            $t("contact.cont")
                          }}</v-card-text>

                          <v-card-actions>
                            <v-btn outlined rounded text>
                              (+228) 92254104
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template> -->
                  <h5 class="card-title mt-10">
                    {{ $t('contact.questions') }} :
                  </h5>

                  <div class="accordion mt-4 pt-2" id="buyingquestion">
                    <div class="accordion-item rounded">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button border-0 bg-light"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {{ $t('contact.questions1') }} ?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse border-0 collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#buyingquestion"
                      >
                        <div class="accordion-body text-muted bg-white">
                          {{ $t('contact.answer1') }}.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button border-0 bg-light collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {{ $t('contact.questions2') }} ?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse border-0 collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#buyingquestion"
                      >
                        <div class="accordion-body text-muted bg-white">
                          {{ $t('contact.answer2') }}.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item rounded mt-2">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button border-0 bg-light collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {{ $t('contact.questions3') }} ?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse border-0 collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#buyingquestion"
                      >
                        <div class="accordion-body text-muted bg-white">
                          {{ $t('contact.answer3') }}.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- End Terms & Conditions -->
    </div>
  </v-app>
</template>

<script>
import de from '../../public/js/app.js';
import da from '../../public/js/feather.min.js';
export default {
  name: 'Home',
  data: () => {
    return {
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      categories: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      elementaire: 1,
      elite: 2,
      entreprise: 3,
      date: new Date().getFullYear(),
    };
  },
  methods: {
    contact: function() {
      window.open('https://wa.me/22892254104');
    },
    toggleMenu: function() {
      document.getElementById('isToggle').classList.toggle('open');
      var isOpen = document.getElementById('navigation');
      if (isOpen.style.display === 'block') {
        isOpen.style.display = 'none';
      } else {
        isOpen.style.display = 'block';
      }
    },
  },
};
</script>

<style scoped></style>
