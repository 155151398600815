<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t("commande.title") }}</h4>
      </div>

      <div class="p-1">
        <v-card
          ><div class="row">
            <div class="col-12 col-sm-6 text-right pr-5"></div>
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
          </div>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="commandeList"
            class="elevation-1"
          >
            <template #[`item.montant`]="{ item }">
              <span>{{ item.article.prix * item.quantite }}</span>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                color="red"
                class="text-white"
                small
                v-if="item.etats == 1"
                >{{ $t("commande.encoure") }}</v-chip
              >
              <v-chip
                color="red"
                class="text-white"
                small
                v-else-if="item.etats == 2"
                >{{ $t("commande.livrer") }}</v-chip
              >
              <v-chip
                color="red"
                class="text-white"
                small
                v-else-if="item.etats == 4"
                >{{ $t("commande.annule") }}</v-chip
              >
              <!-- <v-chip color="blue" class="text-white" small v-else>{{
                $t('commande.encoure')
              }}</v-chip> -->
            </template>

            <template #[`item.createdAt`]="{ item }">
              <span>{{ convertDate(item.createdAt) }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
              <div>
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      small
                      @click="detailItem(item)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>

                  <span>
                    {{ $t("dashboard.detailcommande") }}
                  </span>
                </v-tooltip>
              </div>
              <div v-if="item.etats == 0">
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("commande.annuler") }}
                  </span>
                </v-tooltip>
              </div>
              <div v-if="item.etats == 2">
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      small
                      @click="openNotation(item)"
                    >
                      mdi-note-plus
                    </v-icon>
                  </template>

                  <span>
                    {{ $t("dashboard.notation") }}
                  </span>
                </v-tooltip>
              </div>
              <!-- <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon> -->
            </template>
          </v-data-table>
          <v-dialog v-model="dialogDetail" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5"
                >{{ $t("dashboard.detailcommande") }} ?</v-card-title
              >

              <div class="modal-body p-4">
                <div class="container-fluid px-0">
                  <div v-if="detail.user" class="row">
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("user.nom") }} :
                      </h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.user.nom }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("user.prenom") }} :
                      </h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.user.prenom }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("commande.adresseLiv") }} :
                      </h5>

                      <a
                        v-if="addressIncMaps(detail.adresseLivraison)"
                        :href="detail.adresseLivraison"
                      >
                        <v-icon color="red">
                          mdi-map
                        </v-icon></a
                      >
                      <span v-else class="text-muted" style="font-size: 19px">
                        {{ detail.adresseLivraison }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("commande.numerro") }} :
                      </h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.numeroDestinataire }}</span
                      >
                      <br />
                    </div>
                    <div v-if="detail.detailCommande" class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("commande.detail") }} :
                      </h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.detailCommande }}</span
                      >
                      <br />
                    </div>
                    <!--end row-->
                  </div>
                  <!--end row-->
                </div>
                <!--end container-->
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDetail">{{
                  $t("commande.fermer")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogNotation" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5"
                >{{ $t("dashboard.notation") }} ?</v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-radio-group v-model="notation.satisfait">
                        <v-radio
                          :label="$t('notation.satisfaction')"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          :label="$t('notation.satisfactionno')"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <v-icon large :color="color_ic1" @click="setNot(1)"
                        >mdi-emoticon-happy-outline</v-icon
                      >
                      <v-icon large :color="color_ic2" @click="setNot(2)"
                        >mdi-emoticon-happy-outline</v-icon
                      >
                      <v-icon large :color="color_ic3" @click="setNot(3)"
                        >mdi-emoticon-happy-outline</v-icon
                      >
                      <v-icon large :color="color_ic4" @click="setNot(4)"
                        >mdi-emoticon-happy-outline</v-icon
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="notation.commentaire"
                        :label="$t('notation.commentaire')"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeNotation">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="saveNotation"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogAnnuler" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5"
                >{{ $t("commande.msgAnn") }} ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeAnnuler">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="annuleItemConfirm">{{
                  $t("yes")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
import { convertDate } from "../../helper";
import commande from "../../store/modules/commande";
export default {
  data: () => {
    return {
      myloading: false,
      alert: false,
      search: "",
      color_ic1: "gray",
      color_ic2: "gray",
      color_ic3: "gray",
      color_ic4: "gray",
      dialog: false,
      dialogDelete: false,
      dialogNotation: false,
      visible: false,
      dialogAnnuler: false,
      dialogDetail: false,
      detail: {},
      notation: { commentaire: "", satisfait: true, commande: null },
      id: 0,
      idOp: 0,
      note: 0,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    headers() {
      return [
        // {
        //   text: this.$t('commande.num'),
        //   align: 'center',
        //   sortable: false,
        //   value: 'num',
        // },
        {
          text: this.$t("commande.article"),
          align: "center",
          sortable: false,
          value: "article.designation",
        },
        {
          text: this.$t("commande.prix"),
          align: "center",
          sortable: false,
          value: "article.prix",
        },
        {
          text: this.$t("commande.quantite"),
          align: "center",
          sortable: false,
          value: "quantite",
        },
        {
          text: this.$t("commande.detail"),
          align: "center",
          sortable: false,
          value: "detailCommande",
        },
        {
          text: this.$t("commande.montant"),
          align: "center",
          sortable: false,
          value: "montant",
        },
        {
          text: this.$t("commande.status"),
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: this.$t("commande.dateCommande"),
          align: "center",
          sortable: false,
          value: "createdAt",
        },
        {
          text: this.$t("shops.actions"),
          align: "center",
          value: "actions",
          sortable: false,
        },
      ];
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("category.newcat")
        : this.$t("category.updatecat");
    },
    commandeList() {
      if (this.mesCommandes) {
        // console.log(this.mesCommandes);
        return this.mesCommandes;
      } else {
        return [];
      }
    },

    ...mapGetters(["loading", "mesCommandes"]),
  },
  created() {
    this.initialize();
    this.$store.dispatch("getMesCommandes");
    // this.$store.dispatch("getBoutiquesByUsersConnect");
  },
  methods: {
    convertDate(str) {
      if (str) {
        return convertDate(str);
      } else {
        return 0;
      }
    },
    addressIncMaps(str) {
      if (str) {
        let result = str.includes("https://maps.google.com");
        return result;
      } else {
        return false;
      }
    },
    // async check(libelle) {
    //   await this.$store.dispatch("checkCategorie", libelle);
    // },

    initialize() {},

    setSelected(value) {},

    setNot(note) {
      this.color_ic1 = "gray";
      this.color_ic2 = "gray";
      this.color_ic3 = "gray";
      this.color_ic4 = "gray";
      if (note === 1) {
        this.color_ic1 = "primary";
      } else if (note === 2) this.color_ic2 = "primary";
      else if (note === 3) this.color_ic3 = "primary";
      else if (note === 4) this.color_ic4 = "primary";
      this.note = note;
    },

    detailItem(item) {
      this.detail = item;
      this.id = item.idCommande;
      this.dialogDetail = true;
    },
    closeDetail() {
      this.detail = {};
      this.dialogDetail = false;
    },
    openNotation(item) {
      this.notation.commentaire = "";
      this.notation.satisfait = false;
      this.notation.commande = item;
      this.dialogNotation = true;
    },
    closeNotation() {
      this.notation.commentaire = "";
      this.notation.satisfait = false;
      this.dialogNotation = false;
      this.myloading = false;
    },
    editItem(item) {
      this.id = item.idCommande;
      this.idOp = item.idOperation;
      this.dialogAnnuler = true;
    },

    deleteItem(item) {
      this.editedIndex = this.categoriesByBoutiques.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.myloading = true;
      await this.$store.dispatch("deleteCategorie", this.editedItem);
      this.closeDelete();
    },

    async annuleItemConfirm() {
      this.myloading = true;
      await this.$store.dispatch("annulerCommande", this.id);
      // await this.$store.dispatch('annuleOperation', this.idOp);
      await this.$store.dispatch("getMesCommandes");
      this.closeAnnuler();
    },

    async saveNotation() {
      this.myloading = true;
      const notated = {
        id_commande: this.notation.commande.idCommande,
        note: this.note,
        commentaire: this.notation.commentaire,
        satisfait: this.notation.satisfait,
      };

      await this.$store.dispatch("saveNotationCommande", notated);
      this.closeNotation();
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.visible = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.defaultBoutique = Object.assign({}, this.initialBoutique);
        this.editedIndex = -1;
      });
    },

    closeAnnuler() {
      this.myloading = false;
      this.dialogAnnuler = false;
    },
  },
};
</script>
