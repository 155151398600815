<template>
  <v-app>
    <div class="" id="">
      <!-- Navbar STart -->
      <header
        id="topnav"
        class="defaultscroll sticky"
        style="background-color: #256d7b"
      >
        <div class="container">
          <!-- Logo container-->
          <router-link
            class="logo"
            :to="{ path: `/${$i18n.locale}/shopHome/${idRoute}` }"
            style="position:absolute"
          >
            <span class="logo-light-mode">
              <img
                src="/logo.png"
                class="l-dark"
                style="height: 70px; width: 150px; padding: 10px;"
                alt=""
              />
              <img
                src="/logo.png"
                class="l-light"
                style="height: 70px; width: 150px; padding: 10px;"
                alt=""
              />
            </span>
            <img
              src="/logo.png"
              style="height: 90px; width: 150px; padding: 10px;"
              class="logo-dark-mode"
              alt=""
            />
          </router-link>
          <!-- End Logo container-->
          <div class="menu-extras">
            <div class="menu-item">
              <!-- Mobile menu toggle-->
              <a class="navbar-toggle" id="isToggle" @click="toggleMenu()">
                <div class="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
              <!-- End mobile menu toggle-->
            </div>
          </div>

          <div id="navigation">
            <!-- Navigation Menu-->
            <ul class="navigation-menu nav-light">
              <li>
                <router-link
                  :to="{ path: `/${$i18n.locale}/shopHome/${idRoute}` }"
                  >{{ $t("nav.acceuil") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{
                    path: `/${$i18n.locale}/shopHome/${idRoute}/shopAlbum`,
                  }"
                  >{{ $t("nav.album") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{
                    path: `/${$i18n.locale}/shopHome/${idRoute}/contact-us`,
                  }"
                  >{{ $t("nav.contact") }}</router-link
                >
              </li>
              <!-- <li>
                <router-link :to="{ path: `/${$i18n.locale}/login` }">{{
                  $t("login")
                }}</router-link>
              </li> -->
              <li class="has-submenu parent-menu-item">
                <a>{{ lng }}</a
                ><span class="menu-arrow"></span>
                <ul class="submenu">
                  <li>
                    <a @click="changedtofr">{{ $t("lang1") }}</a>
                  </li>
                  <li>
                    <a @click="changedtoen">{{ $t("lang2") }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <!--end navigation menu-->
          </div>
          <!--end navigation-->
        </div>
        <!--end container-->
      </header>
      <!--end header-->

      <!-- Job List Start -->
      <router-view />
      <!-- Job List End -->
      <!-- <v-footer color="#256d7b">
        <v-col class="text-center white--text" cols="12">
          © 2019-2021 copyright S2A - {{ $t("allright") }}
        </v-col>
      </v-footer> -->
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
import dd from "/public/js/bootstrap.bundle.min.js";
import dc from "/public/js/plugins.init.js";
import axios from "axios";
import i18n from "../i18n";
export default {
  name: "Home",
  data() {
    return {};
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },
    idRoute: function() {
      console.log(this.$store.state.home.idBtq);
      return this.$store.state.home.idBtq;
    },
    ...mapGetters(["lng"]),
  },
  methods: {
    changedtofr: function() {
      i18n.locale = "fr";
      this.$store.commit("setlng", { lang: this.$t("lang1") });

      let language = this.$route.params.lang;
      if (language === "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
    changedtoen: function() {
      i18n.locale = "en";
      this.$store.commit("setlng", { lang: this.$t("lang2") });

      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
    toggleMenu: function() {
      document.getElementById("isToggle").classList.toggle("open");
      var isOpen = document.getElementById("navigation");
      if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
      } else {
        isOpen.style.display = "block";
      }
    },
  },
  mounted: function() {
    let id = this.$route.params.id;

    axios.get("auth/web/boutiques/nom/" + id).then((response) => {
      let boutique = response.data;
      if (boutique) {
        let bou = boutique.idBoutique;
        this.$store.commit("setIdBoutique", { idBtq: boutique.nom });
        this.$store.dispatch("getAlbumsCatByBoutique", bou);
      }
    });

    let language = this.$route.params.lang;
    if (language === "en") {
      this.$store.commit("setlng", { lang: "English" });
    } else if (language === "fr") {
      this.$store.commit("setlng", { lang: "Français" });
    }
  },
};
</script>

<style scoped></style>
