<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t('commande.title') }}</h4>
      </div>

      <div class="p-1">
        <v-card
          ><div class="row">
            <div class="col-12 col-sm-6 pl-5"></div>
            <div class="col-12 col-sm-6 text-right pr-5">
              <v-spacer></v-spacer>
              <!-- <v-btn color="primary" dark class="mb-2" @click="dialog = true">
                {{ $t('category.new') }}
              </v-btn> -->
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
          </div>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="commandeList"
            class="elevation-1"
          >
            <template #[`item.montant`]="{ item }">
              <span>{{ item.article.prix * item.quantite }}</span>
            </template>
            <template #[`item.etats`]="{ item }">
              <v-chip color="red" class="text-white" small v-if="item.etats == 1">{{
                $t('commande.encoure')
              }}</v-chip>
              <v-chip
                color="red"
                class="text-white"
                small
                v-else-if="item.etats == 2"
                >{{ $t('commande.livrer') }}</v-chip
              >
              <v-chip
                color="red"
                class="text-white"
                small
                v-else-if="item.etats == 4"
                >{{ $t('commande.annule') }}</v-chip
              >
            </template>
            <template #[`item.createdAt`]="{ item }">
              <span>{{convertDate(item.createdAt)}}</span>
            </template>
            <template #[`item.actions`]="{ item }">
              <div>
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      small
                      @click="detailItem(item)"
                    >
                    mdi-eye
                    </v-icon>
                  </template>

                  <span>
                    {{ $t('dashboard.detailcommande') }}
                  </span>
                </v-tooltip>
              </div>
              <div v-if="item.etats == 0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- {{ item }} -->
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      small
                      @click="editItem(item)"
                    >
                      mdi-package-variant-closed-check
                    </v-icon>
                  </template>

                  <span>
                    {{ $t('commande.accepter') }}
                  </span>
                </v-tooltip>
              </div>
              <div v-if="item.etats == 1">
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      small
                      @click="editItemLiv(item)"
                    >
                      mdi-truck-delivery
                    </v-icon>
                  </template>

                  <span>
                    {{ $t('commande.livrer') }}
                  </span>
                </v-tooltip>
              </div>
              <div v-if="item.etats == 0">
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      small
                      @click="editItemAn(item)"
                    >
                    mdi-close-circle
                    </v-icon>
                  </template>

                  <span>
                    {{ $t('commande.annuler') }}
                  </span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
          <v-dialog v-model="dialogDetail" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5">{{$t('dashboard.detailcommande') }} ?</v-card-title>

              <div class="modal-body p-4">
                <div class="container-fluid px-0">
                  <div v-if="detail.user" class="row">
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">{{$t('user.nom')}} :</h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.user.nom }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">{{$t('user.prenom')}} :</h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.user.prenom }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">{{$t('commande.adresseLiv')}} :</h5>
                      
                      <a v-if="addressIncMaps(detail.adresseLivraison)" :href="detail.adresseLivraison">
                        <v-icon
                          color="red"
                        >
                        mdi-map
                        </v-icon></a>
                        <span v-else class="text-muted" style="font-size: 19px">
                        {{ detail.adresseLivraison }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">{{$t('commande.numerro')}} :</h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.numeroDestinataire }}</span
                      >
                      <br />
                    </div>
                    <div v-if="detail.detailCommande" class="">
                      <h5 class="mt-2" style="display: inline-block">{{$t('commande.detail')}} :</h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.detailCommande }}</span
                      >
                      <br />
                    </div>
                    <!--end row-->
                  </div>
                  <!--end row-->
                </div>
                <!--end container-->
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDetail">{{
                  $t('commande.fermer')
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5">{{ $t('delete1') }} ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{
                  $t('cancel')
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogAccept" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5"
                >{{ $t('commande.msgAcc') }} ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeAccept">{{
                  $t('cancel')
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="acceptItem">{{
                  $t('yes')
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogLiv" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5"
                >{{ $t('commande.msgLiv') }} ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeLiv">{{
                  $t('cancel')
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="livreItem">{{
                  $t('yes')
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogAnnuler" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5"
                >{{ $t('commande.msgAnn') }} ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeAnnuler">{{
                  $t('cancel')
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="annuleItemConfirm">{{
                  $t('yes')
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import de from '/public/js/app.js';
import da from '/public/js/feather.min.js';
import { convertDate } from '../../helper';
export default {
  data: () => {
    return {
      myloading: false,
      alert: false,
      search: '',
      dialog: false,
      dialogDelete: false,
      visible: false,
      dialogAccept: false,
      dialogLiv: false,
      id: 0,
      dialogAnnuler: false,
      dialogDetail : false,
      detail:{},
      idOp: 0,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('commande.num'),
          align: 'center',
          sortable: false,
          value: 'num',
        },
        {
          text: this.$t('commande.article'),
          align: 'center',
          sortable: false,
          value: 'article.designation',
        },
        {
          text: this.$t('commande.quantite'),
          align: 'center',
          sortable: false,
          value: 'quantite',
        },
        {
          text: this.$t('commande.detail'),
          align: 'center',
          sortable: false,
          value: 'detailCommande',
        },
        {
          text: this.$t('commande.status'),
          align: 'center',
          sortable: false,
          value: 'etats',
        },
        {
          text: this.$t('commande.dateCommande'),
          align: 'center',
          sortable: false,
          value: 'createdAt',
        },
        { text: this.$t('shops.actions'), value: 'actions', sortable: false },
      ];
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.$t('category.newcat')
        : this.$t('category.updatecat');
    },

    commandeList() {
      if (this.commandeFour) {
        return this.commandeFour;
      } else {
        return [];
      }
    },

    ...mapGetters(['loading', 'commandeFour']),
    // ...mapGetters(["loading", "boutiquesByUsersCon"]),
  },
  created() {
    this.initialize();
    this.$store.dispatch('getCommandeFour');
    // this.$store.dispatch("getBoutiquesByUsersConnect");
  },
  methods: {
    convertDate(str) {
      if (str) {
        return convertDate(str);
      } else {
        return 0;
      }
    },

    addressIncMaps(str){
      if(str){
        let result = str.includes("https://maps.google.com");
        return result
      } else{
        return false
      }
    },
    // async check(libelle) {
    //   await this.$store.dispatch("checkCategorie", libelle);
    // },

    initialize() {},

    setSelected(value) {},

    async acceptItem() {
      // console.log('test');
      await this.$store.dispatch('acceptCommande', this.id);
      await this.$store.dispatch('getCommandeFour');
      this.closeAccept();
    },

    async livreItem() {
      // console.log('test');
      await this.$store.dispatch('enableFourCommande', this.id);
      await this.$store.dispatch('getCommandeFour');
      this.closeLiv();
    },
    detailItem(item) {
      this.detail = item
      this.id = item.idCommande;
      this.dialogDetail = true;
    },
    closeDetail(){
      this.detail = {};
      this.dialogDetail = false;
    },
    editItem(item) {
      this.id = item.idCommande;
      this.dialogAccept = true;
    },

    editItemLiv(item) {
      this.id = item.idCommande;
      this.dialogLiv = true;
    },
    editItemAn(item) {
      this.id = item.idCommande;
      this.idOp = item.idOperation;
      this.dialogAnnuler = true;
    },

    deleteItem(item) {
      this.editedIndex = this.categoriesByBoutiques.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.myloading = true;
      await this.$store.dispatch('deleteCategorie', this.editedItem);
      this.closeDelete();
    },
    async annuleItemConfirm() {
      this.myloading = true;
      await this.$store.dispatch('annulerCommande', this.id);
      await this.$store.dispatch('getCommandeFour');
      // await this.$store.dispatch('annuleOperation', this.idOp);
      this.closeAnnuler();
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.visible = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.defaultBoutique = Object.assign({}, this.initialBoutique);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.myloading = false;
      this.dialogDelete = false;
      this.visible = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeAccept() {
      this.myloading = false;
      this.dialogAccept = false;
    },
    closeLiv() {
      this.myloading = false;
      this.dialogLiv = false;
    },

    closeAnnuler() {
      this.myloading = false;
      this.dialogAnnuler = false;
    },
  },
};
</script>
