import axios from 'axios';

const state = {
  listVideos: []
};
const getters = {
  listVideos: (state) => state.listVideos,
};
const actions = {
  async updateVideos({ commit, rootState }, form) {
    let id = rootState.auth.id;
    let token = rootState.auth.token;
    try {
      axios
        .put('video', form, { headers: { 'Authorization': 'Bearer ' + token } })
        .then(response => {
          console.log(response && response.status === 201)
          if (response) {
            commit("updateTypes", { libelle: form.libelle });
          }
          else {
            console.log(rootState.home.lng);
            if (rootState.home.lng === 'English')
              alert("The username already exists, please change it")
            else
              alert("Cet username existe déjà, veuillez changez le username saisi")
          }
        }
        )
    } catch (error) {
      console.log(error)
    }

  },
  async postVideos({ dispatch, rootState }, type) {
    let token = rootState.auth.token;
    var bodyFormData = new FormData();
    bodyFormData.append('contenu', type.contenu);
    bodyFormData.append('video', type.video);

    await axios.post('video',
      bodyFormData, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data'
      }
    });
    dispatch("getAllVideos")
  },
  async updateVideos({ dispatch, rootState }, type) {
    let token = rootState.auth.token;
    var bodyFormData = new FormData();

    bodyFormData.append('contenu', type.contenu);
    bodyFormData.append('video', type.video);

    await axios.put('video/' + type.id,
      bodyFormData, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data'
      }
    });
    dispatch("getAllVideos")
  },
  async deleteVideos({ rootState }, type) {
    let token = rootState.auth.token;
    await axios.delete('video/' + type.id, { headers: { 'Authorization': 'Bearer ' + token } });
    dispatch("getAllVideos")

  },
  async getAllVideos({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('video', { headers: { 'Authorization': 'Bearer ' + token } });
    commit("setListVideos", { listVideos: response.data });
  },

  async LogOut({ commit }) {
    commit("logout");
  },
};

const mutations = {
  setListVideos(state, { listVideos }) {
    state.listVideos = listVideos;
  },


};

export default {
  state,
  getters,
  actions,
  mutations,
};