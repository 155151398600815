import axios from 'axios';

const state = {
  listPays: []
};
const getters = {
  listPays: (state) => state.listPays,
};
const actions = {
  async updatePays({ commit, rootState }, form) {
    let id = rootState.auth.id;
    let token = rootState.auth.token;
    try {
      axios
        .put('pays', form, { headers: { 'Authorization': 'Bearer ' + token } })
        .then(response => {
          console.log(response && response.status === 201)
          if (response) {
            commit("updatePays", { nom: form.nom, code: form.code, indicatif: form.indicatif });
          }
          else {
            console.log(rootState.home.lng);
            if (rootState.home.lng === 'English')
              alert("The username already exists, please change it")
            else
              alert("Cet username existe déjà, veuillez changez le username saisi")
          }
        }
        )
    } catch (error) {
      console.log(error)
    }

  },
  async deleteUser({ rootState }) {
    let token = rootState.auth.token;
    await axios.delete('pays', { headers: { 'Authorization': 'Bearer ' + token } });
  },
  async getAllPays({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('pays', { headers: { 'Authorization': 'Bearer ' + token } });
    commit("setListPays", { listPays: response.data });
  },

  async LogOut({ commit }) {
    commit("logout");
  },
};

const mutations = {
  setListPays(state, { listPays }) {
    state.listPays = listPays;
  },


};

export default {
  state,
  getters,
  actions,
  mutations,
};