import Compressor from "compressorjs";
import moment from "moment";

export function compressImage(
    image,
    defaultQuality = 0.6,
    switchQuality = true) {
    let quality;

    if (switchQuality) {
        const fileSize = (image.size / 1024 / 1024).toFixed(1);

        if (fileSize < 2) {
            quality = 0.8;
        } else if (fileSize > 2 && fileSize < 8) {
            quality = 0.6;
        } else {
            quality = 0.4;
        }
    } else {
        quality = defaultQuality;
    }

    return new Promise((resolve) => {
        compress(image, {
            quality,
            maxWidth: 1000,
            success(result) {
                resolve(result)
            },
            error() {
                resolve(image)
            }
        });
    })

    function compress(image, options) {
        return new Compressor(image, options);
    }

}

export function dayBeetwen1(start, end) {
    var st = moment(start, "YYYY-MM-DD");
    var en = moment(end, "YYYY-MM-DD");

    return moment.duration(st.diff(en)).asDays();
}

export function dayBeetwen(end) {
    // var st = moment(start, "YYYY-MM-DD");
    var current = moment().startOf('day');
    var en = moment(end, "YYYY-MM-DD");

    return moment.duration(en.diff(current)).asDays();
}

export function isBefore(end) {
    const result = moment(new Date()).isBefore(moment(end, "YYYY-MM-DD"));
    return result;
}

export function convertDate(date){
    const result = moment(date).format("DD/MM/YYYY");
    return result;
}
