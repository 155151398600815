<template>
  <v-app>
    <div class="mt-10" id="">
      <!-- Job List Start -->
      <section class="section">
        <div
          class="contain-lg contain-md contain-sm contain-esm contain-xl contain-xxl contain-xxxl pb-10 mb-5"
        >
          <div class="row ml-15 mr-15">
            <div class="auto">
              <div class="border-bottom pb-4">
                <div class="row">
                  <div class="col-12 col-sm-3">
                    <div class="section-title">
                      <h4 class="title mb-2" v-if="categorie">
                        {{ categorie.libelle }}
                      </h4>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 col-sm-6">
                    <v-text-field
                      v-model.lazy.trim="query"
                      append-icon="mdi-magnify"
                      :label="$t('shops.search')"
                      single-line
                      hide-details
                      outlined
                      dense
                      rounded
                      @input="searcheChange"
                    >
                    </v-text-field>
                  </div>

                  <div class="col-12 col-sm-3 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="form custom-form">
                      <div class="">
                        <label class="menu-arrow mb-5"
                          >{{ $t("acceuil.selectcat") }}
                        </label>
                        <v-select
                          v-model="categorie"
                          :items="types"
                          item-text="libelle"
                          item-value="libelle"
                          :label="$t('acceuil.allcat')"
                          return-object
                          dense
                          solo
                        ></v-select>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row mr-15 ml-15">
            <div v-if="loading" style="height: 200px;"></div>
            <div
              class="col-xxs-8r col-xs-8r col-xsm-8r col-sm-8r col-md-8r col-lg-8r col-xlg-8r mt-4 pt-2"
              style=""
              v-for="album in albums.data"
              :key="album.albums.idAlbum"
            >
              <album :album="album.albums"></album>
            </div>
            <div class="d-flex flex-column mt-5" v-if="isVisible">
              <b-pagination
                class="align-self-center"
                @change="handlePageChange"
                :total-rows="totalRow"
                :per-page="perPage"
                v-model="page"
              ></b-pagination>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Job List End -->
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "../../i18n";
import axios from "axios";
import album from "../../components/Album.vue";
export default {
  components: { album },
  data: () => {
    return {
      loading: true,
      page: 1,
      query: "",
      albums: [],
    };
  },
  computed: {
    ...mapGetters(["types"]),
    categorie: {
      get() {
        return this.$store.state.home.categorie;
      },
      set(categorie) {
        this.loading = true;
        this.$store.commit("setType", categorie);
        this.$router
          .push(`/${i18n.locale}/categorie/` + categorie.id)
          .catch(() => {});
        // this.$store.dispatch("getAlbumsByCategorie", categorie.idCategorie);
        axios
          .get("auth/web/articles/type/boutique/page/" + categorie.id + "/" + 1)
          .then((response) => {
            this.albums = response.data;
          });
        this.loading = false;
      },
    },

    isVisible() {
      if (this.albums) {
        if (this.albums.total > this.albums.per_page) return true;
        else return false;
      } else {
        return false;
      }
    },

    totalRow() {
      if (this.albums) {
        return this.albums.total;
      } else {
        return 0;
      }
    },

    perPage() {
      if (this.albums) {
        return this.albums.per_page;
      } else {
        return 0;
      }
    },
  },
  methods: {
    handlePageChange(page) {
      let id;
      if (this.$route.params.id) {
        id = this.$route.params.id;
      } else {
        id = 0;
      }
      this.$store.dispatch("getByTypes", id);

      if (this.query) {
        if (page) {
          axios
            .get(
              "auth/web/articles/type/boutique/page/" +
                id +
                "/" +
                page +
                "/" +
                this.query
            )
            .then((response) => {
              this.albums = response.data;
              this.loading = false;
            });
        } else {
          axios
            .get(
              "auth/web/articles/type/boutique/page/" +
                id +
                "/" +
                1 +
                "/" +
                this.query
            )
            .then((response) => {
              this.albums = response.data;
              this.loading = false;
            });
        }
      } else {
        if (page) {
          axios
            .get("auth/web/articles/type/boutique/page/" + id + "/" + page)
            .then((response) => {
              this.albums = response.data;
              this.loading = false;
            });
        } else {
          axios
            .get("auth/web/articles/type/boutique/page/" + id + "/" + 1)
            .then((response) => {
              this.albums = response.data;
              this.loading = false;
            });
        }
      }
    },

    searcheChange() {
      let id;
      if (this.$route.params.id) {
        id = this.$route.params.id;
      } else {
        id = 0;
      }

      if (this.query) {
        axios
          .get(
            "auth/web/articles/type/boutique/page/" +
              id +
              "/" +
              1 +
              "/" +
              this.query
          )
          .then((response) => {
            this.albums = response.data;
            this.loading = false;
          });
      } else {
        axios
          .get("auth/web/articles/type/boutique/page/" + id + "/" + 1)
          .then((response) => {
            this.albums = response.data;
            this.loading = false;
          });
      }
    },
  },
  mounted: function() {
    let id;
    if (this.$route.params.id) {
      id = this.$route.params.id;
    } else {
      id = 0;
    }

    this.loading = true;
    this.$store.dispatch("getByTypes", id);
    axios
      .get("auth/web/articles/type/boutique/page/" + id + "/" + 1)
      .then((response) => {
        this.albums = response.data;
        this.loading = false;
      });
  },
};
</script>
<style scoped>
@media screen and (min-width: 1500px) {
  .contain-xxxl {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}

@media screen and (min-width: 1400px) {
  .contain-xxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}

@media screen and (max-width: 1200px) {
  .contain-xl {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}

@media screen and (max-width: 992px) {
  .contain-lg {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .contain-md {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

@media screen and (max-width: 576px) {
  .contain-sm {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
}

@media screen and (max-width: 360px) {
  .contain-esm {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

@media (max-width: 450px) {
  .col-xxs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 450px) {
  .col-xs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .col-sm-8r {
    width: 33%;
    float: left;
  }
}

@media (min-width: 750px) {
  .col-xsm-8r {
    width: 25%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-8r {
    width: 16.5%;
    float: left;
  }
}

/* @media (min-width: 1240px) {
  .col-lg-8r {
    width: 12.5%;
    float: left;
  }
} */

@media (min-width: 1550px) {
  .col-xlg-8r {
    width: 12.5%;
    float: left;
  }
}
</style>

/* afficher la liste des categories dans le v-select Redirection vers cette page
à chaque modification de "categorie" verifier que la liste des catégories n'est
pas vide Chargement des albums selon la categorie affichage des albums */
