<template>
  <div class="mt-5">
    <div style="padding-top: 130px;">
      <div
        class="contain-lg contain-md contain-sm contain-esm contain-xl contain-xxl contain-xxxl"
      >
        <div class="row ml-15 mr-15">
          <div class="cols-12 col-sm-12 col-md-6" style="padding-top:0">
            <img
              :src="album.photo_entete"
              class="img-fluid rounded"
              style="height: auto; with:auto; max-height: 400px;"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="detail cols-12 col-sm-12 col-md-6">
            <h4 class="title">{{ album.designation }}</h4>
            <div>
              <span
                ><b>{{ $t("shops.title") }} :</b></span
              >{{ album.boutique.nom }}
              <span class="vl"></span>
              <i class="icon-lick fa fa-heart"></i>
              ({{ album.likee }} {{ $t("shops.lick") }})
              <v-btn
                color="green"
                small
                outlined
                class="no-active"
                active-class="green"
                @click="openDialog"
              >
                {{ $t("shops.lick") }}
              </v-btn>
              <v-btn
                color="green"
                small
                outlined
                class="no-active ml-5"
                active-class="green"
                @click="openDialogCommade"
              >
                {{ $t("commande.bay") }}
              </v-btn>
            </div>
            <hr />
            <h4>{{ album.prix }} {{ album.devise.libelle }}</h4>
            <ul class="desc">
              <li>
                <h5>{{ album.categorie.libelle }}</h5>
              </li>
              <li>
                <p>
                  {{ album.description }}
                </p>
              </li>
            </ul>
            <p class="addres">
              <i class="fa fa-globe"></i> {{ album.boutique.pays.nom }}
              {{ album.boutique.adresse }}
            </p>
            <p class="addres">
              <i class="fa fa-phone-square"></i>
              {{ album.boutique.pays.indicatif }}
              {{ album.boutique.telephone }}
            </p>
            <!-- <a href="https://whatsapp.multivariate.ai/instance/+22896747253/send-message">Lien whatsapp</a> -->
            <div class="discussion justify-center">
              <a :href="`${weme}`" class="justify-center"
                ><i class="fab fa-whatsapp fa-2x"></i
                ><span class="disc-text">{{ $t("shops.discuter") }}</span>
              </a>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>

      <div
        class="contain-lg contain-md contain-sm contain-esm contain-xl contain-xxl contain-xxxl pb-10 mb-5 mt-10"
      >
        <div class="ml-15 mr-15 mb-10"><hr /></div>
        <div class="row ml-15 mr-15">
          <div
            class="col-xs-8r col-sm-8r col-md-8r col-lg-8r col-xlg-8r mt-4 pt-2"
            v-for="image in images"
            :key="image.id"
          >
            <div
              class="card shop-list border-0 position-relative"
              style="height: 130px; width: 100%"
            >
              <div
                class="shop-image position-relative overflow-hidden rounded shadow"
                style="box-shadow: 0px 0px 2px 2px rgb(60 72 88 / 15%) !important; height: 100%; width:100%"
              >
                <img
                  :src="image.name"
                  class="img-fluid img"
                  alt="fichier introuvable"
                  @click="setImage(image)"
                  data-bs-toggle="modal"
                  data-bs-target="#productview"
                  style="width:100%; height: 130px"
                />
                <!-- <ul class="list-unstyled shop-icons">
                  <li class="mt-2">
                    <a
                      @click="setImage(image)"
                      data-bs-toggle="modal"
                      data-bs-target="#productview"
                      class="btn btn-icon btn-pills btn-soft-primary"
                      ><i class="fa fa-eye icon"></i
                    ></a>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
          <!--end col-->
          <div
            class="col-xs-8r col-sm-8r col-md-8r col-lg-8r col-xlg-8r mt-4 pt-2"
            v-for="video in videos"
            :key="video.id"
          >
            <div
              class="card shop-list border-0 position-relative"
              style="height: 130px; width: 100%"
            >
              <div
                class="shop-image position-relative overflow-hidden rounded shadow"
                style="box-shadow: 0px 0px 2px 2px rgb(60 72 88 / 15%) !important;"
              >
                <video
                  controls
                  :src="video.name"
                  class="img-fluid"
                  alt="fichier introuvable"
                  style="width:100%; min-height: 130px;"
                />
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <!-- Product View Start -->
      <div
        class="modal fade"
        id="productview"
        tabindex="-1"
        aria-labelledby="productview-title"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-lg modal-dialog-centered">
          <div class="modal-content rounded shadow border-0">
            <div class="modal-header border-bottom">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body p-4">
              <div class="container-fluid px-0">
                <div class="row">
                  <div class="col-12">
                    <div class="tiny-slide">
                      <img :src="image.name" class="img-fluid rounded" alt="" />
                    </div>
                  </div>
                  <!--end col-->

                  <!-- <div class="col-lg-7 mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <h5 class="mt-4" style="display: inline-block">Album :</h5>
                    <span class="text-muted" style="font-size: 19px">
                      {{ album.designation }}</span
                    >
                    <br />

                    <h5 class="mt-4" style="display: inline-block">
                      {{ $t("shop") }} :
                    </h5>
                    <span class="text-muted" style="font-size: 19px">
                      {{ album.boutique.nom }}</span
                    ><br />

                    <h5 class="mt-4" style="display: inline-block">
                      {{ $t("publication") }} :
                    </h5>
                    <span class="text-muted" style="font-size: 19px">
                      {{ getDate(album.createdAt) }}</span
                    >
                  </div> -->
                  <!--end row-->
                </div>
                <!--end row-->
              </div>
              <!--end container-->
            </div>
          </div>
        </div>
      </div>
      <!-- Product View End -->

      <div>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <v-tabs centered>
                <v-tab>{{ $t("login") }}</v-tab>
                <v-tab>{{ $t("signup") }}</v-tab>
                <v-spacer />
                <v-btn icon large @click.stop="dialog = false">
                  <v-icon large>mdi-close</v-icon>
                </v-btn>

                <v-tab-item v-for="n in tabNbr" :key="n">
                  <template v-if="n === 1">
                    <div>
                      <form class="login-form mt-4" @submit.prevent="login">
                        <div class="row">
                          <div class="col-12">
                            <div class="mb-1">
                              <label for="username" class="form-label"
                                >{{ $t("username") }}
                                <span class="text-danger">*</span></label
                              >
                              <div class="input-icons">
                                <i class="fa fa-user icon"></i>
                                <input
                                  type="text"
                                  class="form-control ps-10"
                                  :placeholder="$t('username')"
                                  name="u"
                                  required=""
                                  v-model="form.username"
                                />
                              </div>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-12">
                            <div class="mb-1">
                              <label for="password" class="form-label"
                                >{{ $t("password") }}
                                <span class="text-danger">*</span></label
                              >
                              <div class="input-icons">
                                <i class="fa fa-key icon"></i>
                                <input
                                  type="password"
                                  class="form-control ps-10"
                                  :placeholder="$t('password')"
                                  required=""
                                  v-model="form.password"
                                />
                              </div>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12 mb-5">
                            <div class="d-grid">
                              <button type="submit" class="btn btn-primary">
                                {{ $t("connexion") }}
                              </button>
                            </div>
                          </div>

                          <!--end col-->
                        </div>
                        <!--end row-->
                      </form>
                    </div>
                  </template>
                  <template v-else-if="n === 2">
                    <div class="mt-5 mb-5">
                      <v-card-text class="px-3 px-md-5 pt-3">
                        <form class="login-form mt-4" @submit.prevent="submit">
                          <div class="row">
                            <div class="col-12 col-sm-6">
                              <div class="mb-1">
                                <label class="form-label" for="nom"
                                  >{{ $t("nom") }}
                                  <span class="text-danger">*</span></label
                                >
                                <div class="input-icons">
                                  <i class="fa fa-user icon"></i>
                                  <input
                                    type="text"
                                    class="form-control ps-10"
                                    :placeholder="$t('nom')"
                                    name="s"
                                    required=""
                                    v-model="form.nom"
                                  />
                                </div>
                              </div>
                            </div>
                            <!--end col-->

                            <div class="col-12 col-sm-6">
                              <div class="mb-1">
                                <label class="form-label" for="prenom"
                                  >{{ $t("prenom") }}
                                  <span class="text-danger">*</span></label
                                >
                                <div class="input-icons">
                                  <i class="fa fa-user icon"></i>
                                  <input
                                    type="text"
                                    class="form-control ps-10"
                                    :placeholder="$t('prenom')"
                                    name="s"
                                    required=""
                                    v-model="form.prenom"
                                  />
                                </div>
                              </div>
                            </div>
                            <!--end col-->

                            <div class="col-12 col-sm-6">
                              <div class="mb-1">
                                <label for="username" class="form-label"
                                  >{{ $t("email") }}
                                  <span class="text-danger">*</span></label
                                >
                                <div class="input-icons">
                                  <i class="fa fa-envelope icon"></i>
                                  <input
                                    type="text"
                                    class="form-control ps-10"
                                    :placeholder="$t('email')"
                                    name="u"
                                    required=""
                                    v-model="form.email"
                                  />
                                </div>
                              </div>
                            </div>
                            <!--end col-->

                            <div class="col-12 col-sm-6">
                              <div class="mb-1">
                                <label class="form-label" for="username"
                                  >{{ $t("username") }}
                                  <span class="text-danger">*</span></label
                                >
                                <div class="input-icons">
                                  <i class="fa fa-user icon"></i>
                                  <input
                                    type="text"
                                    class="form-control ps-10"
                                    :placeholder="$t('username')"
                                    name="username"
                                    required=""
                                    v-model="form.username"
                                  />
                                </div>
                              </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                              <div class="mb-1">
                                <label class="form-label" for="password"
                                  >{{ $t("password") }}
                                  <span class="text-danger">*</span></label
                                >
                                <div class="input-icons">
                                  <i class="fa fa-key icon"></i>
                                  <input
                                    type="password"
                                    class="form-control ps-10"
                                    :placeholder="$t('password')"
                                    required=""
                                    v-model="form.password"
                                  />
                                </div>
                              </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                              <div class="mb-1">
                                <label class="form-label" for="password"
                                  >{{ $t("confpass")
                                  }}<span class="text-danger">*</span></label
                                >
                                <div class="input-icons">
                                  <i class="fa fa-key icon"></i>
                                  <input
                                    type="password"
                                    class="form-control ps-10"
                                    :placeholder="$t('confpass')"
                                    required=""
                                    v-model="form.confirmPassword"
                                  />
                                </div>
                              </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                              <div class="mb-1">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                    >{{ $t("accept") }}</label
                                  >
                                </div>
                              </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                              <div class="d-grid">
                                <button class="btn btn-primary" type="submit">
                                  {{ $t("save") }}
                                </button>
                              </div>
                            </div>
                            <!--end col-->
                          </div>
                          <!--end row-->
                        </form>
                      </v-card-text>
                    </div>
                  </template>
                </v-tab-item>
              </v-tabs>
            </v-card-title>
          </v-card>
        </v-dialog>
      </div>
      <v-dialog v-model="dialogCommande" max-width="400px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim.lazy="formCommad.quantite"
              type="number"
              :label="$t('commande.quantite')"
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="formCommad.adresseLivraison"
              :label="$t('commande.adresseLiv')"
              type=""
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="formCommad.numeroDestinataire"
              :label="$t('commande.numerro')"
              type="number"
            ></v-text-field>

            <v-text-field
              v-model.trim.lazy="formCommad.detailCommande"
              :label="$t('commande.detail')"
              type=""
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="openDialogCash">
              {{ $t("commande.cash") }}
            </v-btn>
            <v-btn color="primary" depressed @click="openDialogS2apay">
              {{ $t("commande.s2apay") }}
            </v-btn>

            <!-- <v-btn color="primary" depressed @click="openDialogPaypal">
              {{ $t('commande.paypal') }}
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogS2apay" max-width="400px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <!-- <v-text-field
              v-model.trim.lazy="formCommad.quantite"
              type="number"
              :label="$t('commande.quantite')"
            ></v-text-field> -->
            <!-- <v-text-field
              v-model.trim.lazy="formCommad.adresseLivraison"
              :label="$t('commande.adresseLiv')"
              type=""
            ></v-text-field> -->
            <v-text-field
              v-model.trim.lazy="portefeuille"
              :label="$t('commande.portefeuil')"
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="total"
              disabled
              :label="$t('commande.total')"
              suffix="$ (XOF)"
            ></v-text-field>
            <v-text-field
              v-if="this.album.promotion"
              v-model.trim.lazy="totalPromo"
              disabled
              :label="$t('commande.totalpromo')"
              suffix="$ (XOF)"
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="formS2apay.pin"
              :label="$t('commande.pin')"
              type="number"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="submitCommande">
              {{ $t("commande.valide") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCash" max-width="400px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim.lazy="total"
              disabled
              :label="$t('commande.total')"
              suffix="$ (XOF)"
            ></v-text-field>
            <v-text-field
              v-if="this.album.promotion"
              v-model.trim.lazy="totalPromo"
              disabled
              :label="$t('commande.totalpromo')"
              suffix="$ (XOF)"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="submitCommandeCash">
              {{ $t("commande.valide") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPaypal" max-width="400px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim.lazy="formCommad.quantite"
              type="number"
              :label="$t('commande.quantite')"
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="formCommad.adresseLivraison"
              :label="$t('commande.adresseLiv')"
              type=""
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="formCommad.detail"
              :label="$t('commande.detail')"
              type=""
            ></v-text-field>
            <!-- <vue-google-autocomplete
              ref="address"
              id="map"
              classname="form-control"
              placeholder="Please type your address"
              v-on:placechanged="getAddressData"
              country="sg"
            >
            </vue-google-autocomplete> -->
            <v-text-field
              v-model.trim.lazy="totalUsd"
              disabled
              :label="$t('commande.total')"
              suffix="$ (USD)"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="submitCommande">
              {{ $t("commande.valide") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  // components: { VueGoogleAutocomplete },
  data: () => {
    return {
      image: {},
      yourValue: null,
      loading: true,
      dialog: false,
      dialogCommande: false,
      dialogS2apay: false,
      dialogPaypal: false,
      dialogCash: false,
      address: "",
      tabNbr: 2,
      form: {
        username: "",
        nom: "",
        prenom: "",
        password: "",
        confirmPassword: "",
        email: "",
      },
      formCommad: {
        article: null,
        user: 0,
        quantite: 0,
        adresseLivraison: "",
        numeroDestinataire: 0,
        detailCommande: "",
      },
      formS2apay: {
        numero: "",
        montant: 0,
        pin: 0,
      },
      formPaypal: {
        montant: 0,
      },
      showError: false,
      paypal: {
        intent: "sale",
        payer: {
          payment_method: "paypal",
        },
        transactions: [],
        note_to_payer: "Contacter nous pour avoir plus d'explication.",
        redirect_urls: {
          return_url: "http://localhost:8081/",
          cancel_url: "http://localhost:8081/",
        },
      },

      transaction: {
        amount: {
          total: "0",
          currency: "USD",
          details: {
            subtotal: "100.00",
            tax: "0",
            shipping: "0",
            handling_fee: "0",
            shipping_discount: "0",
            insurance: "0",
          },
        },
        description: "Abonnement aux service oanke.",
        custom: "EBAY_EMS_90048630024435",
        // invoice_number: '3',
        payment_options: {
          allowed_payment_method: "INSTANT_FUNDING_SOURCE",
        },
        soft_descriptor: "ECHI5786786",
        item_list: {
          items: [],
          shipping_address: {
            recipient_name: "Kofi Nutefe",
            line1: "Be Gbegnedi ecole Cheikh Anta Diop",
            line2: "Rue #34",
            city: "Lome Togo",
            country_code: "US",
            postal_code: "00228",
            phone: "92254104",
            state: "TG",
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters(["album", "fichiers", "taux"]),
    ...mapActions(["registerUser", "saveCommande", "getTaux"]),

    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },
    weme() {
      if (this.album.boutique.pays) {
        let ind = this.album.boutique.pays.indicatif.split("+");
        let indicatif = ind[ind.length - 1];
        return (
          "https://wa.me/" +
          indicatif +
          this.album.boutique.telephone +
          "?text=Votre%20publication%20%20sur%20https://oanke.com/fr/albums/" +
          this.album.idArticle +
          "%20m%20'%20interesse"
        );
      }
    },

    portefeuille() {
      if (this.$store.getters.portefeuille) {
        return this.$store.getters.portefeuille;
      } else {
        return null;
      }
    },

    totalUsd() {
      if (this.album) {
        return this.album.prix * this.formCommad.quantite;
      } else {
        return 0;
      }
    },

    // tauxEchange() {
    //   if (this.taux) {
    //     console.log(this.taux.taux);
    //     return this.taux.taux;
    //   } else {
    //     return 0;
    //   }
    // },

    total() {
      if (this.album) {
        return this.album.prix * this.formCommad.quantite;
      } else {
        return 0;
      }
    },

    totalPromo() {
      if (this.album.promotion) {
        // "promotion": false,
        // "pourcentage": 0,
        return (
          (this.album.prix - (this.album.prix * this.album.pourcentage) / 100) *
          this.formCommad.quantite
        );
      } else {
        return 0;
      }
    },

    nom: function() {
      if (this.image.name != undefined) {
        let splitted = this.image.name.split("/");
        return splitted[splitted.length - 1];
      } else return "";
    },
    images: function() {
      // console.log(this.album);
      let images = [];
      this.fichiers.forEach((fichier) => {
        let splitted = fichier.name.split(".");
        let ext = splitted[splitted.length - 1];
        if (!(ext == "mkv" || ext == "avi" || ext == "mp4")) {
          images.push(fichier);
        }
      });
      return images;
    },
    videos: function() {
      let videos = [];
      this.fichiers.forEach((fichier) => {
        let splitted = fichier.name.split(".");
        let ext = splitted[splitted.length - 1];
        if (ext == "mkv" || ext == "avi" || ext == "mp4") {
          videos.push(fichier);
        }
      });
      return videos;
    },
  },
  created: function() {
    let id = this.$route.params.id;
    this.$store.dispatch("getAlbum", id);
    this.$store.dispatch("getTaux");
  },

  methods: {
    setImage(image) {
      this.image = image;
    },
    copy() {
      let url = window.location.href;
      navigator.clipboard.writeText(url);
    },
    openDialog() {
      if (!this.isAuthenticated) {
        this.dialog = true;
      } else {
        this.lick();
      }
    },
    getAddressData: function(addressData, placeResultData, id) {
      this.address = addressData;
    },
    closeDialog() {
      this.dialog = false;
      this.form = {
        username: "",
        nom: "",
        prenom: "",
        password: "",
        confirmPassword: "",
        email: "",
      };
    },
    openDialogCommade() {
      if (!this.isAuthenticated) {
        this.dialog = true;
        this.$store.dispatch("getTaux");
      } else {
        this.dialogCommande = true;
      }
    },
    closeDialogCommade() {
      this.dialogCommande = false;
      this.formCommad = {
        article: 0,
        user: 0,
        quantite: 0,
        adresseLivraison: "",
        numeroDestinataire: 0,
      };
      this.closeDialogS2apay();
      this.closeDialogPaypal();
    },
    openDialogS2apay() {
      if (this.portefeuille) {
        if (
          this.formCommad.quantite > 0 &&
          this.formCommad.adresseLivraison != "" &&
          this.formCommad.numeroDestinataire != 0 &&
          this.album
        ) {
          this.dialogS2apay = true;
          this.dialogCommande = false;
        } else {
          this.$toast.error(this.$t("commande.msg3"));
          this.dialogS2apay = false;
          this.dialogCommande = false;
        }
      } else {
        this.$toast.error(this.$t("commande.msg1"));
        this.dialogS2apay = false;
        this.dialogCommande = false;
      }
    },
    closeDialogS2apay() {
      this.dialogS2apay = false;
      this.dialogCommande = false;

      this.formS2apay = {
        montant: "",
        numero: 0,
      };
    },
    openDialogCash() {
      if (
        this.formCommad.quantite > 0 &&
        this.formCommad.adresseLivraison != "" &&
        this.formCommad.numeroDestinataire != 0 &&
        this.album
      ) {
        this.dialogCash = true;
        this.dialogCommande = false;
      } else {
        this.$toast.error(this.$t("commande.msg3"));
        this.dialogCash = false;
        this.dialogCommande = false;
      }
    },
    closeDialogCash() {
      this.dialogCash = false;
      this.dialogCommande = false;

      this.formS2apay = {
        montant: "",
        numero: 0,
      };
    },
    openDialogPaypal() {
      if (this.album.devise.libelle === "USD") {
        this.dialogPaypal = true;
        this.dialogCommande = false;
      } else {
        this.$toast.error(this.$t("commande.msg2"));
        this.dialogPaypal = false;
        this.dialogCommande = false;
      }
    },
    closeDialogPaypal() {
      this.dialogPaypal = false;
      this.dialogCommande = false;
      this.formPaypal = {
        numero: 0,
      };
    },

    getDate(mydate) {
      if (mydate) {
        let splitted = mydate.split(`T`);
        let date = splitted[0];
        if (i18n.locale === "en") {
          return date;
        } else {
          let split = date.split(`-`);
          let dt = `${split[2]}-${split[1]}-${split[0]}`;
          return dt;
        }
      }
    },
    async lick() {
      try {
        this.$store.dispatch("lickOneAlbum", this.album);
        this.closeDialog();
        this.showError = false;
      } catch (error) {
        // console.log(error);
        this.showError = true;
      }
    },
    async submit() {
      try {
        if (
          this.form.username != "" &&
          this.form.nom != "" &&
          this.form.email != "" &&
          this.form.password != ""
        ) {
          if (this.form.password === this.form.confirmPassword) {
            // console.log(this.form);

            // await this.registerUser(this.form);
            await this.$store.dispatch("registerUser", this.form);

            // console.log("ok");
            this.$toast.success(this.$t("toast.save"));
            this.tabNbr = 1;
            // this.closeDialog();
            this.showError = false;
          } else {
            if (this.$store.state.home.lng === "English")
              alert("Passwords entered do not match");
            else alert("Les mots de passe saisis ne sont pas conformes");
          }
        } else {
          if (this.$store.state.home.lng === "English")
            alert("Please fill all the fields");
          else alert("Veuillez remplir tous les champs");
        }
      } catch (error) {
        this.showError = true;
      }
    },
    async login() {
      try {
        this.$store.dispatch("logIn", this.form);
        this.closeDialog();
        this.dialogCommande = true;
        this.showError = false;
      } catch (error) {
        console.log(error);
        this.showError = true;
      }
    },

    async submitCommande() {
      try {
        if (
          this.formCommad.quantite != 0 &&
          this.formCommad.adresseLivraison != "" &&
          this.formCommad.numeroDestinataire != 0 &&
          this.album
        ) {
          // console.log(this.album.boutique.user.portefeuille);

          this.formCommad.article = this.album;
          console.log(this.portefeuille);
          console.log(this.album.boutique.user.portefeuille);
          const paiement = {};
          paiement.envoyeur = this.portefeuille;
          paiement.receveur = this.album.boutique.user.portefeuille;
          paiement.typeOperation = 2;
          // paiement.montant = this.total * this.tauxEchange;
          paiement.montant = this.total;
          paiement.pin = this.formS2apay.pin;

          const dataCom = {
            comm: this.formCommad,
            pai: paiement,
          };
          // console.log(paiement);
          // await this.$store.dispatch('saveCommande', this.formCommad);
          if (this.isAuthenticated) {
            await this.$store.dispatch("savePaiement", dataCom);
            this.closeDialogCommade();
            this.$toast.success(this.$t("toast.save"));
          }
        } else {
          this.$toast.error(this.$t("toast.error"));
        }
      } catch (error) {
        this.$toast.error(this.$t("toast.error"));
        // this.showError = true;
      }
    },

    async submitCommandeCash() {
      try {
        if (
          this.formCommad.quantite != 0 &&
          this.formCommad.adresseLivraison != "" &&
          this.formCommad.numeroDestinataire != 0 &&
          this.album
        ) {
          // console.log(this.album.boutique.user.portefeuille);

          this.formCommad.article = this.album;
          // console.log(this.portefeuille);
          // console.log(this.album.boutique.user.portefeuille);
          // const paiement = {};
          // paiement.envoyeur = this.portefeuille;
          // paiement.receveur = this.album.boutique.user.portefeuille;
          // paiement.typeOperation = 2;
          // paiement.montant = this.total * this.tauxEchange;
          // paiement.montant = this.total;
          // paiement.pin = this.formS2apay.pin;

          // const dataCom = {
          //   comm: this.formCommad,
          //   pai: paiement,
          // };
          console.log(this.formCommad);
          await this.$store.dispatch("saveCommande", this.formCommad);
          // if (this.isAuthenticated) {
          //   await this.$store.dispatch("savePaiement", dataCom);
          this.closeDialogCommade();
          this.closeDialogCash();
          this.$toast.success(this.$t("toast.save"));
          // }
        } else {
          this.$toast.error(this.$t("toast.error"));
        }
      } catch (error) {
        this.$toast.error(this.$t("toast.error"));
        // this.showError = true;
      }
    },

    async submitCommandePay() {
      try {
        if (
          this.formCommad.quantite != 0 &&
          this.formCommad.adresseLivraison != "" &&
          this.formCommad.numeroDestinataire != 0 &&
          this.album
        ) {
          this.formCommad.article = this.album;

          const paiement = {};
          paiement.envoyeur = this.portefeuille;
          paiement.receveur = "166A63B8-942B-486F-9A1D-D07D6DC9A76F";
          paiement.typeOperation = 1;
          paiement.montant = this.album.promotion
            ? this.totalPromo * this.tauxEchange
            : this.total * this.tauxEchange;
          paiement.pin = this.formS2apay.pin;
          // console.log(paiement);
          await this.$store.dispatch("saveCommande", this.formCommad);

          // this.transaction.invoice_number = 20;
          this.transaction.amount = {
            total: this.album.promotion
              ? this.totalPromo + ""
              : this.total + "",
            currency: "USD",
            details: {
              subtotal: this.album.promotion
                ? this.totalPromo + ""
                : this.total + "",
              tax: "0",
              shipping: "0",
              handling_fee: "0",
              shipping_discount: "0",
              insurance: "0",
            },
          };
          this.transaction.item_list.items.push({
            name: "Abonnement aux service oanke",
            description: "",
            quantity: "1",
            price: this.album.promotion
              ? this.totalPromo + ""
              : this.total + "",
            tax: "0",
            sku: "0",
            currency: "USD",
          });
          this.paypal.transactions.push(this.transaction);
          // console.log(this.paypal);
          await this.$store.dispatch("loginPaypal", this.paypal);

          this.closeDialogCommade();
          this.$toast.success(this.$t("toast.save"));
        } else {
          this.$toast.error(this.$t("toast.error"));
        }
      } catch (error) {
        this.$toast.error(this.$t("toast.error"));
        // this.showError = true;
      }
    },
  },

  // mounted() {
  //   // To demonstrate functionality of exposed component functions
  //   // Here we make focus on the user input
  //   this.$refs.address.focus();
  // },
};
</script>

<style scoped>
.icon {
  padding: 10px;
  min-width: 40px;
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}
.img {
  animation: pulse-bg 1s infinite;
}

@keyframes pulse-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #eee;
  }
}
/* .btnimg {
  display: flex !important;
  justify-content: space-between !important;
} */
.detail a {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  margin-right: 10px;
  border-radius: 34px;
}
.detail a i {
  /* background: white; */
  color: green;
}

.vl {
  border-left: 1px solid rgb(77, 74, 74);
  height: 500px;
  margin-left: 10px;
  margin-right: 10px;
}
.icon-lick {
  background: white;
  color: red;
}
.desc {
  margin-left: 20px;
}
.addres i {
  background: white;
  color: green;
  margin-right: 10px;
}

.aime {
  color: green;
}

.discussion i {
  display: inline;
  text-decoration: none;
  text-align: center;
  font-size: 25px;
}

.discussion a span {
  font-size: 18px;
  margin-left: 5px;
  color: green;
}
.discussion a {
  display: inline;
  text-decoration: none;
  text-align: center;
  font-size: 25px;
  border: 1px solid green;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 1500px) {
  .contain-xxxl {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}

@media screen and (min-width: 1400px) {
  .contain-xxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}
@media screen and (max-width: 1200px) {
  .contain-xl {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}

@media screen and (max-width: 992px) {
  .contain-lg {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .contain-md {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

@media screen and (max-width: 576px) {
  .contain-sm {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
}

@media screen and (max-width: 360px) {
  .contain-esm {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

.col-xs-8r {
  width: 32.9%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-8r {
    width: 24.8%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-8r {
    width: 16.5%;
    float: left;
  }
}

@media (min-width: 1240px) {
  .col-lg-8r {
    width: 16.5%;
    float: left;
  }
}

@media (min-width: 1400px) {
  .col-xlg-8r {
    width: 12.5%;
    float: left;
  }
}
</style>
