import axios from 'axios';

const state = {
	albumsByUsers: [],
	albumsPage: [],
	albumLick: [],
	albumNew: [],
	albumsNew: [],
	oneAlbum: {},
	loading: true
};

const getters = {
	albumsByUsers: (state) => state.albumsByUsers,
	albumsPage: (state) => state.albumsPage,
	oneAlbum: (state) => state.oneAlbum,
	albumLick: (state) => state.albumLick,
	albumNew: (state) => state.albumNew,
	albumsNew: (state) => state.albumsNew,
};

const actions = {
	async getAlbumsByUsers({ commit, rootState }) {
		let token = rootState.auth.token;
		let response = await axios.get('articles_user', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAlbumByUser', { albumsByUsers: data })
	},
	async getAlbumSearchByUsers({ commit, rootState }, s) {
		let token = rootState.auth.token;
		let response = await axios.get('articles/user/search/' + s, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAlbumSearchByUser', { albumsByUsers: data })
	},
	async getAlbumPageByUsers({ commit, rootState }, page) {
		let token = rootState.auth.token;
		let response = await axios.get('articles/user/page/' + page, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAlbumByUserPage', { albumsPage: data })
	},
	async getAlbumSearchPageByUsers({ commit, rootState }, { page, search }) {
		let token = rootState.auth.token;
		let response = await axios.get('articles/user/search/page/' + page + "/" + search, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		// console.log(data)
		commit('setAlbumSearchByUserPage', { albumsPage: data })
	},
	async getAlbumsLick({ commit, rootState }, page) {
		let token = rootState.auth.token;
		let response = await axios.get('/auth/web/articles/likee/page/' + page, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAlbumLick', { albumLick: data })
	},

	async getAlbumsNew({ commit, rootState }, page) {
		let token = rootState.auth.token;
		let response = await axios.get('/auth/web/articles/new/post/page/' + page, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAlbumNew', { albumNew: data })
	},
	async getAlbumsNews({ commit, rootState }, page) {
		let token = rootState.auth.token;
		let response = await axios.get('/auth/articles/new/posts/page/' + page, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		// console.log(data)
		commit('setAlbumsNew', { albumsNew: data })
	},
	async getOneAlbum({ commit, rootState }, id) {
		let token = rootState.auth.token;
		let response = await axios.get('articles_optional/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;

		commit('setOneAlbum', { oneAlbum: data })
	},
	async addFileToAlbum({ dispatch, rootState }, dat) {
		let album = dat.album;
		let images = dat.images;
		let videos = dat.videos;
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		images.forEach(element => {
			bodyFormData.append('images', element, element.name);
		});
		videos.forEach(element => {
			bodyFormData.append('videos', element, element.name);
		});
		let response = await axios.post('fichiers_articles/' + album, bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'

			}
		});
		let data = response.data;
		dispatch('getOneAlbum', album)
	},
	async deleteOneAlbum({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		let response = await axios.delete('articles/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		dispatch('getAlbumsByUsers')
	},
	async deleteOneFile({ dispatch, rootState }, dat) {
		let file = dat.file;
		let id = dat.id;
		let token = rootState.auth.token;
		let response = await axios.delete('fichiers_articles/' + file.id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		dispatch('getOneAlbum', id)
	},
	async postAlbum({ dispatch, rootState }, album) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('boutique', album.boutique);
		bodyFormData.append('description', album.description);
		bodyFormData.append('prix', album.prix);
		bodyFormData.append('categorie', album.categorie);
		bodyFormData.append('enstock', album.enstock);
		bodyFormData.append('pourcentage', album.pourcentage);
		bodyFormData.append('promotion', album.promotion);
		bodyFormData.append('devise', album.devise);
		album.images.forEach(element => {
			bodyFormData.append('images', element, element.name);
		});
		album.videos.forEach(element => {
			bodyFormData.append('videos', element, element.name);
		});
		bodyFormData.append('designation', album.titre);
		bodyFormData.append('photo_entete', album.photo_entete);
		bodyFormData.append('dateDebutPromo', album.dateDebutPromo);
		bodyFormData.append('dateFinPromo', album.dateFinPromo);
		await axios.post('articles_file',
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getAlbumsByUsers")
	},
	async updateAlbum({ dispatch, rootState }, album) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('prix', album.prix);
		bodyFormData.append('enstock', album.enstock);
		bodyFormData.append('boutique', album.boutique);
		bodyFormData.append('categorie', album.categorie);
		bodyFormData.append('designation', album.titre);
		if (album.promotion)
			bodyFormData.append('pourcentage', album.pourcentage);
		else
			bodyFormData.append('pourcentage', 0);
		bodyFormData.append('promotion', album.promotion);
		bodyFormData.append('description', album.description);
		bodyFormData.append('photo_entete', album.photo_entete);
		bodyFormData.append('devise', album.devise);
		bodyFormData.append('dateDebutPromo', album.dateDebutPromo);
		bodyFormData.append('dateFinPromo', album.dateFinPromo);
		await axios.put('articles_file/' + album.id,
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getAlbumsByUsers")
	},
	async updateAlbumFile({ dispatch, rootState }, id, file) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('fichier', file, file.name);
		await axios.post('articles_file/' + id,
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getAlbumsByUsers")
	},
	async lickOneAlbum({ commit, rootState }, data) {
		let token = rootState.auth.token;
		let favori = {
			article: data
		}
		let response = await axios.post('favori', favori, { headers: { 'Authorization': 'Bearer ' + token } });
	},
};

const mutations = {
	loading(state) {
		state.loading = true;
	},

	setAlbumByUser(state, { albumsByUsers }) {
		state.albumsByUsers = albumsByUsers;
		state.loading = false;
	},
	setAlbumSearchByUser(state, { albumsByUsers }) {
		state.albumsByUsers = albumsByUsers;
		state.loading = false;
	},
	setAlbumByUserPage(state, { albumsPage }) {
		state.albumsPage = albumsPage;
		state.loading = false;
	},
	setAlbumSearchByUserPage(state, { albumsPage }) {
		state.albumsPage = albumsPage;
		state.loading = false;
	},
	setAlbumLick(state, { albumLick }) {
		state.albumLick = albumLick;
		state.loading = false;
	},
	setAlbumNew(state, { albumNew }) {
		state.albumNew = albumNew;
		state.loading = false;
	},
	setAlbumsNew(state, { albumsNew }) {
		state.albumsNew = albumsNew;
		state.loading = false;
	},
	setOneAlbum(state, { oneAlbum }) {
		state.oneAlbum = oneAlbum;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};