import axios from 'axios';

const state = {
	publicites: [],
	publicitesPage: [],
	publicitesHome: [],
	onePublicite: {},
	loading: true,
	authPublicitesPage: [],
};

const getters = {
	onePublicite: (state) => state.onePublicite,
	publicites: (state) => state.publicites,
	publicitesPage: (state) => state.publicitesPage,
	authPublicitesPage: (state) => state.authPublicitesPage,
};

const actions = {
	async getPublicite({ commit, rootState }) {
		let token = rootState.auth.token;
		let response = await axios.get('pubs', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setPublicites', { publicites: data })
	},
	async getPubliciteSearch({ commit, rootState }, s) {
		let token = rootState.auth.token;
		let response = await axios.get('pubs/search/'+s, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setPublicites', { publicites: data })
	},
	async getPublicitesPage({ commit, rootState }, page) {
		let token = rootState.auth.token;
		let response = await axios.get('pub/page/' + page, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setPublicitesPage', { publicitesPage: data })
	},
	async getAuthPublicitesPage({ commit, rootState }, page) {
		let token = rootState.auth.token;
		let response = await axios.get('auth/pub/page/' + page, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAuthPublicitesPage', { authPublicitesPage: data })
	},
	async getOnePublicite({ commit, rootState }, id) {
		let token = rootState.auth.token;
		let response = await axios.get('publicites/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;

		commit('setOnePublicite', { onePublicite: data })
	},
	async deleteOnePublicite({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		let response = await axios.delete('pub/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		dispatch('getPublicite')
	},
	async postPublicite({ dispatch, rootState }, publicite) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		console.log(publicite)
		bodyFormData.append('contenu', publicite.contenu);
		bodyFormData.append('lien', publicite.lien);
		publicite.pieces.forEach(element => {
			bodyFormData.append('pieces', element, element.name);
		});
		await axios.post('pub',
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getPublicite")
	},
	async updatePublicite({ dispatch, rootState }, publicite) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		console.log(publicite)
		bodyFormData.append('contenu', publicite.contenu);
		bodyFormData.append('lien', publicite.lien);
		publicite.pieces.forEach(element => {
			bodyFormData.append('pieces', element, element.name);
		});
		await axios.put('pub/' + publicite.id,
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getPublicite")
	},
};

const mutations = {
	loading(state) {
		state.loading = true;
	},

	setPublicites(state, { publicites }) {
		state.publicites = publicites;
		state.loading = false;
	},
	setPublicitesPage(state, { publicitesPage }) {
		state.publicitesPage = publicitesPage;
		state.loading = false;
	},
	setAuthPublicitesPage(state, { authPublicitesPage }) {
		state.authPublicitesPage = authPublicitesPage;
		state.loading = false;
	},
	setOnePublicite(state, { onePublicite }) {
		state.onePublicite = onePublicite;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};