<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t("msg") }}</h4>
      </div>

      <div class="p-4">
        <div class="d-flex border-bottom p-3">
          <div class="d-flex ms-2">
            <div class="flex-1 ms-3">
              <h6 class="text-dark">{{ $t("team") }}</h6>
              <p class="text-muted mb-0">{{ $t("msgtest") }}</p>
            </div>
          </div>
        </div>
        <h6 class="text-dark mt-4 ml-9">{{ $t("contact.tarif") }}</h6>
        <template>
          <v-simple-table class="mb-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left text-muted">
                    {{ $t("contact.offre") }}
                  </th>
                  <th class="text-left text-muted">
                    {{ $t("contact.free") }}<br />
                  </th>
                  <th class="text-left text-muted">
                    {{ $t("contact.elementary") }}
                  </th>
                  <th class="text-left text-muted">
                    {{ $t("contact.elite") }}
                  </th>
                  <th class="text-left text-muted">
                    {{ $t("contact.enterprise") }}
                  </th>
                  <th class="text-left text-muted">
                    {{ $t("contact.souscrire") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("contact.limitsite") }}</td>
                  <td>100M</td>
                  <td>1G</td>
                  <td>5G</td>
                  <td>{{ $t("contact.quantite") }}</td>
                  <td>
                    <v-chip
                      @click="contact"
                      small
                      class="ma-2"
                      color="#256d7b"
                      text-color="white"
                    >
                      {{ $t("contact.con") }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("contact.limitimg") }}</td>
                  <td>10M</td>
                  <td>10M</td>
                  <td>10M</td>
                  <td>{{ $t("contact.quantite") }}</td>
                  <td>
                    <v-chip
                      @click="contact"
                      small
                      class="ma-2"
                      color="#256d7b"
                      text-color="white"
                    >
                      {{ $t("contact.con") }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
export default {
  data: () => {
    return {};
  },
  methods: {
    contact: function() {
      window.open("https://wa.me/22892254104");
    },
  },
};
</script>
