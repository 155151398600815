import axios from 'axios'
const state = {
    lng: 'Français',
    categories: [],
    albumsSearch: [],
    categorie: {},
    albums: {},
    albumsByCategories: [],
    albumsCatByBoutique: [],
    albumsBoutiqueData: [],
    idBtq: null,
    album: {
        categorie: {
            libelle: ''
        }
    },
    fichiers: [],
    types: [],
    type: {},
    boutiquePage: {},
    boutique: {}
}

const getters = {
    categories: (state) => state.categories,
    categorie: (state) => state.categorie,
    albums: (state) => state.albums,
    albums2: (state) => state.albums2,
    albumsByCategories: (state) => state.albumsByCategories,
    albumsCatByBoutique: (state) => state.albumsCatByBoutique,
    albumsBoutiqueData: (state) => state.albumsBoutiqueData,
    album: (state) => state.album,
    fichiers: (state) => state.fichiers,
    lng: (state) => state.lng,
    idBtq: (state) => state.idBtq,
    types: (state) => state.types,
    type: (state) => state.type,
    boutiquePage: (state) => state.boutiquePage,
    boutique: (state) => state.boutique,
    albumsSearch: (state) => state.albumsSearch,
}

const mutations = {
    setlng: function (state, { lang }) {
        state.lng = lang;
    },

    setCategories: function (state, { categories }) {
        state.categories = categories;
    },

    setCategorie: function (state, { categorie }) {
        state.categorie = categorie;
    },

    setAlbums: function (state, { albums }) {
        state.albums = albums;
    },

    setAlbum: function (state, { album, fichiers }) {
        state.album = album;
        state.fichiers = fichiers;
    },

    setAlbumsByCategories: function (state, { categories }) {
        state.albumsByCategories = categories;
    },

    setAlbumsByCategorie: function (state, { albumsCatByBoutique }) {
        state.albumsCatByBoutique = albumsCatByBoutique;
    },

    setAlbumsBoutiqueData: function (state, { albumsBoutiqueData }) {
        state.albumsBoutiqueData = albumsBoutiqueData;
    },

    setIdBoutique: function (state, { idBtq }) {
        state.idBtq = idBtq;
    },

    setTypes: function (state, { types }) {
        state.types = types
    },

    setType: function (state, { type }) {
        state.type = type;
    },

    setBoutiques: function (state, { boutiquePage }) {
        state.boutiquePage = boutiquePage;
    },

    setAlbumsSearch: function (state, { albums }) {
        state.albums = albums;
    },


    setBoutiqueSearche: function (state, { boutiquePage }) {
        state.boutiquePage = boutiquePage;
    },

}

const actions = {

    async getAlbumsByCategories({ commit }, id) {
        try {
            let response = await axios.get('/auth/articles_categorie/' + id);
            commit('setAlbumsByCategories', { categories: response.data });
        }
        catch (error) { console.log('error when getting AlbumsByCategories') }
    },

    async getCategories({ commit }) {
        let response;
        try {
            response = await axios.get('auth/categories');
            let data = response.data;
            commit('setCategories', { categories: data });
        }
        catch (error) { console.log('error when getting categories') }
        //let token = rootState.auth.token;
        //let response = await axios.get('categories', {headers:{'Authorization': 'Bearer '+token}});
    },

    async getAlbums({ commit }, page) {
        let albums = [];
        try {
            if (page) {
                page = page
            } else {
                page = 1
            }
            let response = await axios.get('auth/web/articles_page/' + page);
            commit('setAlbums', { albums: response.data });
        }
        catch (error) { console.log('error when getting albums') }
        //let token = rootState.auth.token;
        //let response = await axios.get('albums', {headers:{'Authorization': 'Bearer '+token}});
    },

    async getAlbumsSearch({ commit }, { page, search }) {
        let albums = [];
        try {
            if (page) {
                page = page
            } else {
                page = 1
            }
            let response = await axios.get('auth/web/articles/search/page/' + page + '/' + search);
            commit('setAlbumsSearch', { albums: response.data });
        }
        catch (error) { console.log('error when getting albums' + error) }
    },

    async getAlbum({ commit }, id) {
        // commit('setAlbum', { album: { categorie: { libelle: '' }, boutique: { nom: '', pays: { nom: '', code: '', indicatif: '' } } }, fichiers: [] });
        let response = await axios.get('/auth/articles/' + id);
        commit('setAlbum', { album: response.data.albums, fichiers: response.data.fichiers });
    },

    async getAlbumsByCategorie({ commit, state }, id) {
        if (state.categorie == null || state.categorie.idCategorie != id) {
            let categorie = state.categories.find(c => c.idCategorie == id)
            commit('setCategorie', { categorie: categorie });
        }
    },

    async getAlbumsCatByBoutique({ commit }, id) {
        commit('setAlbumsByCategorie', { albumsCatByBoutique: [] });
        let response = await axios.get('auth/articles_boutique_cat/' + id);
        commit('setAlbumsByCategorie', { albumsCatByBoutique: response.data });
    },
    async getHomeAlbumsBoutique({ commit }, id) {
        commit('setAlbumsBoutiqueData', { albumsBoutiqueData: [] });
        let response = await axios.get('auth/articles_boutique/' + id);
        commit('setAlbumsBoutiqueData', { albumsBoutiqueData: response.data });
    },

    async getAllTypeBoutique({ commit, rootState }) {
        let token = rootState.auth.token;
        let response = await axios.get('auth/web/types/boutique', { headers: { 'Authorization': 'Bearer ' + token } });
        commit("setTypes", { types: response.data });
    },

    async getByTypes({ commit, state }, id) {
        if (state.type == null || state.type.id != id) {
            let type = state.types.find(c => c.id == id)
            commit('setType', { type: type });
        }
    },

    async getBoutiquePage({ commit }, page) {
        try {
            if (page) {
                page = page
            } else {
                page = 1
            }
            let response = await axios.get('auth/web/boutiques/page/' + page);
            commit('setBoutiques', { boutiquePage: response.data });
        }
        catch (error) { console.log('error when getting albums') }
    },

    async getBoutiqueSearchPage({ commit }, { page, search }) {
        try {
            if (page) {
                page = page
            } else {
                page = 1
            }
            let response = await axios.get('auth/web/boutiques/searchs/page/' + page + '/' + search);
            commit('setBoutiqueSearche', { boutiquePage: response.data });
        }
        catch (error) { console.log('error when getting albums') }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
