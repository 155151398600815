import axios from 'axios';

import router from './../../router'

const state = {
  id: null,
  username: null,
  nom: null,
  prenom: null,
  email: null,
  dateFin: null,
  token: null,
  usernameExists: null,
  file_max: null,
  file_size: null,
  role: null,
  pieces: [],
  paypalToken: null,
  portefeuille: null,
};

const getters = {
  id: (state) => state.id,
  nom: (state) => state.nom,
  prenom: (state) => state.prenom,
  email: (state) => state.email,
  token: (state) => state.token,
  username: (state) => state.username,
  dateFin: (state) => state.dateFin,
  isAuthenticated: (state) => !!state.token,
  StatePosts: (state) => state.posts,
  StateUser: (state) => state.user,
  usernameExists: (state) => state.usernameExists,
  file_max: (state) => state.file_max,
  file_size: (state) => state.file_size,
  role: (state) => state.role,
  pieces: (state) => state.pieces,
  paypalToken: (state) => state.paypalToken,
  portefeuille: (state) => state.portefeuille,
};

const actions = {
  async ResetPwd({ dispatch, rootState }, form) {
    let itemToSave = {}
    if (rootState.home.lng === 'English') {
      itemToSave = { email: form.email, langue: "EN" }
    } else {
      itemToSave = { email: form.email, langue: "FR" }
    }
    axios
      .post('auth/forget_password', itemToSave)
      .then(response => {
        router.push({ name: 'Login' });
      }
      );
  },

  async Register({ dispatch, rootState }, form) {
    axios
      .post('auth/signin-up-all', form)
      .then(response => {

        router.push({ name: 'Login', query: { alert: '1' } });
        if (response) {
          commit("updateUser", { nom: form.nom, prenom: form.prenom, username: form.username, email: form.email });
        }
        else {
          if (rootState.home.lng === 'English')
            alert("The username already exists, please change it")
          else
            alert("Cet username existe déjà, veuillez changez le username saisi")
        }
      }
      );
  },

  async registerUser({ commit, rootState }, user) {
    // console.log(user);
    axios
      .post('auth/signin-up-all', user)
      .then(response => {
        // console.log(response);
        // router.push({ name: 'Login' });
        if (response!=null) {
          // console.log(response);
          commit("updateUser", { nom: user.nom, prenom: user.prenom, username: user.username, email: user.email });
        }
        else {
          if (rootState.home.lng === 'English')
            alert("The username already exists, please change it")
          else
            alert("Cet username existe déjà, veuillez changez le username saisi")
        }
      }
      );
  },
  async registerUser1({ dispatch, rootState }, user) {
    console.log(user.form);
    axios
      .post('auth/signin-up-all', user.form)
      .then(response => {
        // console.log(response);
        // router.push({ name: 'Login' });
        if (response) {
          // console.log(response);
          commit("updateUser", { nom: user.nom, prenom: user.prenom, username: user.username, email: user.email });
        }
        else {
          if (rootState.home.lng === 'English')
            alert("The username already exists, please change it")
          else
            alert("Cet username existe déjà, veuillez changez le username saisi")
        }
      }
      );
  },
  async LogIn({ commit, rootState }, user) {
    axios
      .post("/auth/signin", { username: user.username, password: user.password })
      .then(response => {

        if (response) {
          let u = response.data.user;
          // console.log(u)

          commit("setPortefeuille", u.portefeuille);
          commit("setUser", { id: u.idUser, username: u.username, nom: u.nom, prenom: u.prenom, token: response.data.accessToken, role: u.role, email: u.email, dateFin: u.dateFin, portefeuille: u.portefeuille });
          commit("updateFiles", { file_max: u.file_max, file_size: u.file_size });
          router.push({ name: 'PersonalDetails' });
        }
      }).catch(error => {
        if (rootState.home.lng === 'English')
          alert("Your credentials are incorrect, please change it")
        else
          alert("Vos identifiants sont incorrects")
      }
      );

  },

  async logIn({ commit, rootState }, user) {
    axios
      .post("/auth/signin", { username: user.username, password: user.password })
      .then(response => {

        if (response) {
          let u = response.data.user;
          // console.log(u)
          commit("setUser", { id: u.idUser, username: u.username, nom: u.nom, prenom: u.prenom, token: response.data.accessToken, role: u.role, email: u.email, pieces: u.pieces, dateFin: u.dateFin, portefeuille: u.portefeuille, });
          commit("updateFiles", { file_max: u.file_max, file_size: u.file_size });
          // router.push({ name: 'PersonalDetails' });
        }
      }).catch(error => {
        if (rootState.home.lng === 'English')
          alert("Your credentials are incorrect, please change it")
        else
          alert("Vos identifiants sont incorrects")
      }
      );

  },

  async logInS2apay({ commit, rootState }, user) {
    let token = rootState.auth.token;
    // https://shopymanager.com:8443/s2apay_dev/web/service/auth/oanke/signin
    // http://localhost:9193/web/service/auth/oanke/signin
    console.log(user);
    axios
      .post("https://shopymanager.com:8443/s2apay/web/service/auth/oanke/signin", { username: user.username, password: user.password })
      .then(response => {
        console.log(response);
        if (response) {
          let u = response.data;
          commit("setPortefeuille", u.portefeuille.numero);
          axios
            .put("users/portefeuille",
              { portefeuille: u.portefeuille.numero },
              {
                headers: { 'Authorization': 'Bearer ' + token }
              }
            )
            .then(response => {

              if (response) {
                // let u = response.data;
                // console.log(u)
              }
            }).catch(error => {
              if (rootState.home.lng === 'English')
                alert("Your credentials are incorrect, please change it")
              else
                alert("Vos identifiants sont incorrects")
            }
            );
        }
      }).catch(error => {
        if (rootState.home.lng === 'English')
          alert("Your credentials are incorrect, please change it")
        else
          alert("Vos identifiants sont incorrects")
      }
      );
  },
  // /users/portefeuille
  async loginPaypal({ commit, rootState }, paiement) {
    const params = new URLSearchParams()
    params.append('grant_type', 'client_credentials')
    axios
      .post("https://api-m.sandbox.paypal.com/v1/oauth2/token", params, {
        auth: {
          username: "ATNuFlOkiehKNKLDsvNHxu9ynWLkUltC6OMagOeE_X-EFnjDkgiKhdUxUL91YXFqZSFoPXvJWBaFmnHi",
          password: "EAlU4lxcZYmxaiRZDsYQVQeghI7oYdJ18lJaGlkgGygpxn-YwOsg3GTPU2iywlBKw3Aeuq0xjD2ZZ5z_"
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(response => {

        if (response) {

          // console.log(response.data.access_token)
          // console.log(paiement);
          commit("setPaypalToken", response.data)
          // this.$store.dispatch('paied', { paiement: paiement, token: response.data.access_token });
          // paied(paiement, response.data.access_token)

          axios
            .post("https://api-m.sandbox.paypal.com/v1/payments/payment", paiement, {
              headers: {
                'Authorization': 'Bearer ' + response.data.access_token,
              }
            })
            .then(response => {
              if (response) {
                // console.log(response)
                window.open(response.data.links[1].href);
              }
            }).catch(error => {
              console.log(error)
              if (rootState.home.lng === 'English')
                alert("Your credentials are incorrect, please change it")
              else
                alert("Vos identifiants sont incorrects")
            }
            );
        }
      }).catch(error => {
        console.log(error)

        if (rootState.home.lng === 'English')
          alert("Your credentials are incorrect, please change it")
        else
          alert("Vos identifiants sont incorrects")
      }
      );

  },

  async confPaied({ commit, state }, paiement) {
    // console.log(state.paypalToken.access_token)
    let req = {
      "payer_id": paiement.PayerID
    }
    // console.log(req)
    axios
      .post("https://api-m.sandbox.paypal.com/v1/payments/payment/" + paiement.paymentId + "/execute", req, {
        headers: {
          'Authorization': 'Bearer ' + state.paypalToken.access_token,
        }
      })
      .then(response => {
        if (response) {
          console.log(response)
        }
      }).catch(error => {
        console.log(error)
        if (rootState.home.lng === 'English')
          alert("Your credentials are incorrect, please change it")
        else
          alert("Vos identifiants sont incorrects")
      }
      );

  },

  async userme({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get("/users/me", { headers: { 'Authorization': 'Bearer ' + token } });
    let u = response.data;
    commit("updateFiles", { file_max: u.file_max, file_size: u.file_size });
  },

  async checkUsername({ commit }, form) {
    let response = await axios.get("check_username/" + form.username);
    let u = response.data;
    commit("setUsernameExists", { usernameExists: u })
  },

  async checkVerifier({ commit }, id) {
    await axios.get("auth/verifier/" + id);
    console.log("verifier");
    // let u = response.data;
    // commit("setUsernameExists", { usernameExists: u })
  },

  async LogOut({ commit }) {
    commit("logout");
  },
};

const mutations = {
  setPaypalToken(state, paypalToken) {
    state.paypalToken = paypalToken
  },
  setUser(state, { id, username, nom, prenom, token, role, email, pieces, dateFin, portefeuille }) {
    state.id = id;
    state.username = username;
    state.nom = nom;
    state.prenom = prenom;
    state.token = token;
    state.role = role;
    state.email = email;
    state.pieces = pieces;
    state.dateFin = dateFin;
    state.portefeuille = portefeuille;
  },

  setPortefeuille(state, portefeuille) {
    state.portefeuille = portefeuille;
  },

  setUsernameExists(state, { usernameExists }) {
    state.usernameExists = usernameExists;
  },

  updateUser(state, { nom, prenom, username, email }) {
    state.nom = nom;
    state.prenom = prenom;
    state.username = username;
    state.email = email;
  },

  updateFiles(state, { file_max, file_size }) {
    state.file_max = file_max;
    state.file_size = file_size;
  },

  logout(state) {
    state.id = null;
    state.username = null;
    state.nom = null;
    state.prenom = null;
    state.token = null;
    state.email = null;
    state.dateFin = null;
    state.portefeuille = null;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};