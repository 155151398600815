<template>
  <v-app>
    <div>
      <div class="back-to-home rounded d-none d-sm-block">
        <router-link :to="{ path: `/${$i18n.locale}` }"
          ><div class="btn btn-icon btn-primary"><i class="fa fa-home"></i></div
        ></router-link>
      </div>

      <!-- Hero Start -->
      <!--end section-->
    </div>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        pwd: '',
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(['ResetPwd']),
    async submit() {
      try {
        this.$store.dispatch('ResetPwd', this.form);
        this.showError = false;
      } catch (error) {
        console.log(error);
        this.showError = true;
      }
    },
  },

  mounted() {
    let params = this.$route.params.id;
    this.$store.dispatch('checkVerifier', params);
    // console.log(params);
  },
};
</script>
<style scoped>
.icon {
  padding: 10px;
  min-width: 40px;
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}
</style>
