<template>
  <div class="card border-0 mb-5">
    <div
      class="work-container work-modern position-relative d-block overflow-hidden rounded"
    >
      <router-link
        :to="{ path: `/${$i18n.locale}/albums/${+album.idArticle}` }"
        class="i-album text-primary "
      >
        <div
          class="work-container work-modern portfolio-box-img position-relative overflow-hidden"
          style="height: 100%"
        >
          <img
            class="item-container img-fluid mx-auto "
            :src="album.photo_entete"
            alt="fichier introuvable"
            style="width:100%; height: 140px;"
          />

          <div class="overlay-work bg-dark"></div>
          <!-- <div class="icons text-center" style="opacity:1">
          <router-link
            :to="{ path: `/${$i18n.locale}/albums/${+album.idArticle}` }"
            class="i-album text-primary "
          >
          </router-link>
        </div> -->
          <div class="content" style="opacity:1">
            <p class="b-stock text-left badge badge-pill">
              {{ cafouillage(album.enstock) }}
            </p>
          </div>
        </div>
      </router-link>
    </div>
    <div class="">
      <div
        class="content ml-1 mr-1"
        style="width: 90%; height: 90%;  display: flex; flex-direction: column; opacity:1"
      >
        <div
          class="mb-0 mt-2 dark d-flex justify-content-between m-0"
          style="height: 100%;"
        >
          <h6 class="p-titre text-capitalize m-0">
            {{ album.designation }}
          </h6>
        </div>
        <p
          class="p-price font-weight-light text-primary d-flex justify-content-between m-0"
        >
          <span class="text-left"
            >{{ album.devise.libelle }} {{ album.prix }}</span
          >
          <span
            v-if="
              isBefore(album.dateFinPromo) && !isBefore(album.dateDebutPromo)
            "
            class="b-pourcentage align-self-center badge badge-pill "
            >-{{ album.pourcentage }}%</span
          >
        </p>
        <p
          v-if="album.likee !== 0"
          class="p-price text-primary d-flex justify-content-start m-0"
        >
          <span class="p-lick font-italic m-0"
            ><i class="icon-like fa fa-heart"></i>{{ album.likee }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../i18n";
import { isBefore } from "../helper";

export default {
  name: "album",
  props: ["album"],

  data: () => {
    return {
      dialog: false,
    };
  },

  methods: {
    isBefore(str) {
      if (str) {
        console.log(isBefore(str));
        return isBefore(str);
      } else {
        return false;
      }
    },
    cafouillage: function(endstock) {
      if (endstock[0] == "E" || endstock[0] == "I" || endstock == "")
        return this.$t("stock");
      else return this.$t("command");
    },
    getDate(mydate) {
      if (mydate) {
        let splitted = mydate.split(`T`);
        let date = splitted[0];
        if (i18n.locale === "en") {
          return date;
        } else {
          let split = date.split(`-`);
          let dt = `${split[2]}-${split[1]}-${split[0]}`;
          return dt;
        }
      }
    },
  },
};
</script>
<style scoped>
.dark {
  color: black !important;
}

.icon-like {
  color: rgb(218, 66, 66);
}

.p-titre {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.p-desc {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.b-stock {
  background-color: #256d7b;
}

.b-pourcentage {
  background-color: #256d7b;
  font-size: 10px;
}

.p-price {
  font-size: 16px;
}
.p-lick {
  font-size: 13px;
  color: rgb(100, 101, 105);
}
</style>
