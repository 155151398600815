<template>
  <v-app>
    <div class="" id="">
      <!-- Navbar STart -->
      <header
        id="topnav"
        class="defaultscroll sticky"
        style="background-color: #256d7b"
      >
        <div class="container">
          <!-- Logo container-->
          <a
            class="logo"
            @click="goTo(`/${$i18n.locale}`)"
            style="position:absolute"
          >
            <span class="logo-light-mode">
              <img
                src="/logo.png"
                class="l-dark"
                style="height: 70px; width: 150px; padding: 10px;"
                alt=""
              />
              <img
                src="/logo.png"
                class="l-light"
                style="height: 70px; width: 150px; padding: 10px;"
                alt=""
              />
            </span>
            <img
              src="/logo.png"
              style="height: 90px; width: 150px; padding: 10px;"
              class="logo-dark-mode"
              alt=""
            />
          </a>
          <!-- End Logo container-->
          <div class="menu-extras">
            <div class="menu-item">
              <!-- Mobile menu toggle-->
              <a class="navbar-toggle" id="isToggle" @click="toggleMenu()">
                <div class="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
              <!-- End mobile menu toggle-->
            </div>
          </div>

          <div id="navigation">
            <!-- Navigation Menu-->
            <ul class="navigation-menu nav-light">
              <li>
                <a @click="goTo(`/${$i18n.locale}`)">{{ $t('nav.acceuil') }}</a>
              </li>
              <li v-if="isAuthenticated">
                <a @click="goTo(`/${$i18n.locale}/dashboard`)">{{
                  $t('nav.dashboard')
                }}</a>
              </li>
              <li>
                <a @click="goTo(`/${$i18n.locale}/albums`)">{{
                  $t('nav.album')
                }}</a>
              </li>
              <li>
                <a @click="goTo(`/${$i18n.locale}/boutiques`)">{{
                  $t('nav.boutique')
                }}</a>
              </li>
              <li>
                <a @click="goTo(`/${$i18n.locale}/contact`)">{{
                  $t('nav.contact')
                }}</a>
              </li>
              <li class="has-submenu parent-menu-item">
                <a>{{ lng }}</a
                ><span class="menu-arrow"></span>
                <ul class="submenu">
                  <li>
                    <a @click="changedtofr">{{ $t('lang1') }}</a>
                  </li>
                  <li>
                    <a @click="changedtoen">{{ $t('lang2') }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <!--end navigation menu-->
          </div>
          <!--end navigation-->
          <v-alert
            v-show="mobile"
            type="info"
            dense
            dismissible
            icon="mdi-google-play"
            class="mt-15"
            elevation="1"
          >
            <a :href="url_app" class="text-white">{{ $t('brande.dow') }}</a>
          </v-alert>
        </div>
        <!--end container-->
      </header>
      <!--end header-->
      <!-- Navbar End -->

      <!-- Job List Start -->
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import de from '/public/js/app.js';
import da from '/public/js/feather.min.js';
import dd from '/public/js/bootstrap.bundle.min.js';
import dc from '/public/js/plugins.init.js';
import i18n from '../i18n';
export default {
  name: 'Home',
  data() {
    return {
      mobile: false,
      url_app:
        'https://play.google.com/store/apps/details?id=com.s2atechnology.oanke&hl=fr',
    };
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },
    ...mapGetters([
      'albumsByCategories',
      'categories',
      'albums',
      'albumsByCategorie',
      'categorie',
      'lng',
    ]),
  },
  methods: {
    changedtofr: function() {
      i18n.locale = 'fr';
      this.$store.commit('setlng', { lang: this.$t('lang1') });

      let language = this.$route.params.lang;
      if (language === 'en') {
        let splitted = this.$route.path.split(`${language}`);
        let url = '/fr' + splitted[1];
        this.$router.push(url);
      }
    },
    changedtoen: function() {
      i18n.locale = 'en';
      this.$store.commit('setlng', { lang: this.$t('lang2') });

      let language = this.$route.params.lang;
      if (language != 'en') {
        let splitted = this.$route.path.split(`${language}`);
        let url = '/en' + splitted[1];
        this.$router.push(url);
      }
    },
    toggleMenu: function() {
      document.getElementById('isToggle').classList.toggle('open');
      var isOpen = document.getElementById('navigation');
      if (isOpen.style.display === 'block') {
        isOpen.style.display = 'none';
      } else {
        isOpen.style.display = 'block';
      }
    },
    closetoggle: function() {
      var $isOpen = document.getElementById('navigation');
      if ($isOpen.style.display === 'block') {
        document.getElementById('isToggle').classList.toggle('open');
      }
      $isOpen.style.display = 'none';
    },
    goTo(url) {
      this.closetoggle();
      this.$router.push(url);
      window.scrollTo(0, 0);
    },

    userdevices() {
      if (/Android/i.test(navigator.userAgent)) {
        this.mobile = true;
        this.url_app =
          'https://play.google.com/store/apps/details?id=com.s2atechnology.oanke&hl=fr';
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        this.mobile = true;
        this.url_app = ''
      }
    },

    downloadMobil() {
      this.$router.push(
        'https://play.google.com/store/apps/details?id=com.s2atechnology.oanke&hl=fr'
      );
      window.scrollTo(0, 0);
    },
  },
  mounted: function() {
    // console.log(this.$route.query)
    // this.$store.dispatch('getAlbumsByCategories');k
    this.userdevices();
    this.$store.dispatch('getCategories');
    this.$store.dispatch('getAlbums');
    let language = this.$route.params.lang;
    if (language === 'en') {
      this.$store.commit('setlng', { lang: 'English' });
    } else if (language === 'fr') {
      this.$store.commit('setlng', { lang: 'Français' });
    }
  },
};
</script>

<style scoped></style>
