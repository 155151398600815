import axios from 'axios';

const state = {
	categoriesByBoutiques: [],
	categoriesBoutiques: [],
	loading: true,
	checkCategorieName: null
};

const getters = {
	categoriesByBoutiques: (state) => state.categoriesByBoutiques,
	categoriesBoutiques: (state) => state.categoriesBoutiques,
	checkCategorieName: (state) => state.checkCategorieName,
};

const actions = {
	//
	///categories/s2a/{s}
	async checkCategorie({ commit, rootState }, libelle) {
		commit('setCheckCategorieName', { checkCategorieName: null })
		let token = rootState.auth.token;
		let response = await axios.get('check_libelle/' + libelle, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCheckCategorieName', { checkCategorieName: data })
	},
	async checkUpdateCategorie({ commit, rootState }, categorie) {
		commit('setCheckCategorieName', { checkCategorieName: null })
		let token = rootState.auth.token;
		let response = await axios.get(`check_libelle/${categorie.idCategorie}` + categorie.libelle, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCheckCategorieName', { checkCategorieName: data })
	},
	async getCategorieGrand({ commit, rootState }, libelle) {
		commit('setCategoriesBoutiques', { categoriesBoutiques: [] })
		commit('setCategoriesBoutiques', { categoriesBoutiques: null })
		let token = rootState.auth.token;
		let response = await axios.get('categories/s2a/' + libelle, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCategoriesBoutiques', { categoriesBoutiques: data })
	},
	async getCategoriesByBoutiques({ commit, rootState }) {
		commit('setCategoriesByBoutiques', { categoriesByBoutiques: [] })
		let token = rootState.auth.token;
		let response = await axios.get('categories_user', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCategoriesByBoutiques', { categoriesByBoutiques: data })
	},

	async getCategoriesBoutiques({ commit, rootState }, id) {
		commit('setCategoriesBoutiques', { categoriesBoutiques: [] })
		let token = rootState.auth.token;
		let response = await axios.get('categories_boutique/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCategoriesBoutiques', { categoriesBoutiques: data })
	},
	async updateCategorie({ dispatch, rootState }, categorie) {
		let token = rootState.auth.token;
		await axios.put('categories/' + categorie.id, categorie, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getCategoriesByBoutiques")
	},
	async postCategorie({ dispatch, rootState }, categorie) {
		let token = rootState.auth.token;
		await axios.post('categories', categorie, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getCategoriesByBoutiques")
	},
	async deleteCategorie({ dispatch, rootState }, categorie) {
		let token = rootState.auth.token;
		await axios.delete('categories/' + categorie.idCategorie, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getCategoriesByBoutiques")
	},
};

const mutations = {
	loading(state) {
		state.loading = true;
	},

	setCategoriesByBoutiques(state, { categoriesByBoutiques }) {
		state.categoriesByBoutiques = categoriesByBoutiques;
		state.loading = false;
	},
	setCategoriesBoutiques(state, { categoriesBoutiques }) {
		state.categoriesBoutiques = categoriesBoutiques;
		state.loading = false;
	},
	setCheckCategorieName(state, { checkCategorieName }) {
		state.checkCategorieName = checkCategorieName;
		state.loading = false;
	},


};

export default {
	state,
	getters,
	actions,
	mutations,
};