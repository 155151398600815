<template>
  <div class="card border-0 ">
    <div
      class="work-container work-modern position-relative d-block overflow-hidden rounded"
    >
      <router-link
        :to="{ path: `shopHome/${btqName}` }"
        class="i-album text-primary "
      >
        <div
          class="work-container work-modern portfolio-box-img position-relative overflow-hidden"
          style="height: 100%"
        >
          <img
            class="item-container img-fluid mx-auto "
            :src="boutique.fileName"
            alt="fichier introuvable"
            style="width:100%; height: 130px;"
          />
          <div class="overlay-work bg-dark"></div>
          <div class="icons text-center" style="opacity:1"></div>
          <div class="content text-left" style="opacity:1">
            <p class="b-stock badge badge-pill"></p>
          </div>
        </div>
      </router-link>
    </div>
    <div class="">
      <div
        class="content ml-1 mr-1"
        style="width: 90%; height: 90%;  display: flex; flex-direction: column; opacity:1"
      >
        <div
          class="mb-0 mt-2 dark d-flex justify-content-between m-0"
          style="height: 100%;"
        >
          <h6 class="p-titre m-0">
            {{ boutique.nom }}
          </h6>
          <span class="text-primary m-0"
            ><i class="icon-like fa fa-heart"></i> 0
          </span>
        </div>
        <div class="p-0 m-0">
          <h6
            class="p-titre text-primary tag mb-0 p-0"
            style="display: inline-block;"
          >
            {{ boutique.adresse }}
          </h6>
        </div>
        <!-- <p class="p-desc m-0">{{ album.description }}</p> -->
        <p class="p-price text-primary m-0">{{ boutique.telephone }}</p>

        <v-rating
          class="rating-note"
          length="5"
          :value="ratingNote(boutique)"
          color="red"
          background-color="red"
          small
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../i18n";
export default {
  name: "boutique",
  props: ["boutique"],

  computed: {
    btqName() {
      let btname = this.boutique.nom;
      let btnamereplace = btname.replace(" ", "%20");
      return btnamereplace;
    },
  },

  methods: {
    cafouillage: function(endstock) {
      if (endstock[0] == "E" || endstock[0] == "I" || endstock == "")
        return this.$t("stock");
      else return this.$t("command");
    },
    getDate(mydate) {
      if (mydate) {
        let splitted = mydate.split(`T`);
        let date = splitted[0];
        if (i18n.locale === "en") {
          return date;
        } else {
          let split = date.split(`-`);
          let dt = `${split[2]}-${split[1]}-${split[0]}`;
          return dt;
        }
      }
    },

    ratingNote(boutique) {
      if (boutique.note && boutique.nbr_note) {
        return boutique.note / boutique.nbr_note;
      } else {
        return 0;
      }
    },
  },
};
</script>
<style scoped lang="scss">
$rating-padding: 0rem !important;

.rating-note {
  padding: 0 !important;
}

.dark {
  color: black !important;
}

.icon-like {
  color: brown;
}

.p-titre {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.p-desc {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.b-stock {
  background-color: #256d7b;
}

.p-price {
  font-size: 20px;
}
</style>
