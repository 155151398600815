<template>
  <v-app>
    <div class="mt-10" id="">
      <!-- Job List Start -->
      <section class="section">
        <v-container>
          <div>
            <v-card class="mx-auto" elevation="0">
              <v-card-title class="font-weight-regular justify-space-between">
                <span class="text-h4">{{ $t('abonnement.mode') }}</span>
                <v-avatar
                  color="primary lighten-2"
                  class="subheading white--text"
                  size="24"
                  v-text="steps"
                ></v-avatar>
              </v-card-title>

              <v-window v-model="steps">
                <v-window-item :value="1">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card
                          class="mx-auto"
                          outlined
                          @click="selectedItem(5)"
                        >
                          <v-card-actions>
                            <v-icon :color="selected === 5 ? 'primary' : ''">
                              {{
                                selected === 5
                                  ? 'mdi-radiobox-marked'
                                  : 'mdi-radiobox-blank'
                              }}
                            </v-icon>
                            <v-spacer />
                            <div class="text-overline">
                              {{ $t('contact.transfert') }}
                            </div>
                          </v-card-actions>
                          <v-list-item>
                            <v-spacer />
                            <v-list-item-avatar tile size="80">
                              <v-img src="/s2apay.png" contain></v-img>
                            </v-list-item-avatar>
                          </v-list-item>

                          <v-card-text>{{ $t('contact.cont') }}</v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn outlined rounded text small>
                              (+228) 97133838
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card
                          class="mx-auto"
                          outlined
                          @click="selectedItem(1)"
                        >
                          <v-card-actions>
                            <v-icon :color="selected === 1 ? 'primary' : ''">
                              {{
                                selected === 1
                                  ? 'mdi-radiobox-marked'
                                  : 'mdi-radiobox-blank'
                              }}
                            </v-icon>
                            <v-spacer />
                            <div class="text-overline">
                              {{ $t('contact.transfert') }}
                            </div>
                          </v-card-actions>
                          <v-list-item>
                            <v-spacer />
                            <v-list-item-avatar tile size="80">
                              <v-img src="/tmoney.png" contain></v-img>
                            </v-list-item-avatar>
                          </v-list-item>

                          <v-card-text>{{ $t('contact.depot') }}</v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn outlined rounded text small>
                              (+228) 92254104
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card
                          class="mx-auto"
                          outlined
                          @click="selectedItem(2)"
                        >
                          <v-card-actions>
                            <v-icon :color="selected === 2 ? 'primary' : ''">
                              {{
                                selected === 2
                                  ? 'mdi-radiobox-marked'
                                  : 'mdi-radiobox-blank'
                              }}
                            </v-icon>
                            <v-spacer />
                            <div class="text-overline">
                              {{ $t('contact.transfert') }}
                            </div>
                          </v-card-actions>
                          <v-list-item>
                            <v-spacer />
                            <v-list-item-avatar tile size="80">
                              <v-img src="/flooz.png" contain></v-img>
                            </v-list-item-avatar>
                          </v-list-item>

                          <v-card-text>{{ $t('contact.depot') }}</v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn outlined rounded text small>
                              (+228) 97133838
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <!-- <v-col>
                        <v-card
                          class="mx-auto"
                          outlined
                          @click="selectedItem(3)"
                        >
                          <v-card-actions>
                            <v-icon :color="selected === 3 ? 'primary' : ''">
                              {{
                                selected === 3
                                  ? 'mdi-radiobox-marked'
                                  : 'mdi-radiobox-blank'
                              }}
                            </v-icon>
                            <v-spacer />
                            <div class="text-overline">
                              {{ $t('contact.transfert') }}
                            </div>
                          </v-card-actions>
                          <v-list-item>
                            <v-spacer />
                            <v-list-item-avatar tile size="80">
                              <v-img src="/paypal.png" contain></v-img>
                            </v-list-item-avatar>
                          </v-list-item>

                          <v-card-text>{{ $t('contact.cont') }}</v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn outlined rounded text small>
                              (+228) 97133838
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col> -->
                      <v-col>
                        <v-card
                          class="mx-auto"
                          outlined
                          @click="selectedItem(4)"
                        >
                          <v-card-actions>
                            <v-icon :color="selected === 4 ? 'primary' : ''">
                              {{
                                selected === 4
                                  ? 'mdi-radiobox-marked'
                                  : 'mdi-radiobox-blank'
                              }}
                            </v-icon>
                            <v-spacer />
                            <div class="text-overline">
                              {{ $t('contact.transfert') }}
                            </div>
                          </v-card-actions>
                          <v-list-item>
                            <v-spacer />
                            <v-list-item-avatar tile size="70">
                              <v-img src="/banque.png" contain></v-img>
                            </v-list-item-avatar>
                          </v-list-item>

                          <v-card-text class="pt-6">{{
                            $t('contact.cont')
                          }}</v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn outlined rounded text small>
                              (+228) 92254104
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                  <div class="mt-5">
                    <v-row>
                      <v-col cols="12" sm="8">
                        <v-card class="mx-auto" max-width="500">
                          <v-card-title
                            class="font-weight-regular justify-space-between"
                          >
                            <span>Modifier à votre selection</span>
                          </v-card-title>

                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <v-select
                                  v-model="defaultAbonnement.pack"
                                  :label="$t('abonnement.pack')"
                                  :items="packs"
                                  item-text="libelle"
                                  item-value="value"
                                  @change="packChange"
                                  return-object
                                ></v-select>
                              </v-col>
                              <v-col cols="12">
                                <template v-if="id == 3">
                                  <v-text-field
                                    v-model="defaultAbonnement.an"
                                    :label="$t('abonnement.annee')"
                                    type="number"
                                    @input="inputAnnee"
                                  ></v-text-field>
                                </template>
                                <template v-else>
                                  <v-select
                                    v-model="defaultAbonnement.annee"
                                    :label="$t('abonnement.annee')"
                                    :items="annees"
                                    item-text="libelle"
                                    item-value="value"
                                    @change="anneeChange"
                                    return-object
                                  ></v-select>
                                </template>
                              </v-col>
                              <v-col cols="12">
                                <template v-if="selected !== 3">
                                  <template v-if="id === 3">
                                    <v-text-field
                                      v-model="form.space"
                                      :label="$t('abonnement.space')"
                                      type="number"
                                    ></v-text-field>
                                  </template>
                                  <template v-else>
                                    <v-file-input
                                      v-model="defaultAbonnement.pieces"
                                      accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp"
                                      :label="$t('abonnement.select')"
                                      multiple
                                      prepend-icon="mdi-paperclip"
                                    >
                                      <template v-slot:selection="{ text }">
                                        <v-chip small label color="primary">
                                          {{ text }}
                                        </v-chip>
                                      </template>
                                    </v-file-input>
                                  </template>
                                </template>
                              </v-col>
                            </v-row>
                          </v-card-text>

                          <v-divider></v-divider>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-card>
                          <v-card-title
                            class="text-h6 font-weight-regular justify-space-between"
                          >
                            <span>votre pack selectionne</span>
                          </v-card-title>
                          <v-card-actions>
                            <span>{{ form.libelle }}</span>
                            <v-spacer />
                            <v-chip>$ USD {{ form.price }} / an</v-chip>
                          </v-card-actions>
                          <v-card-actions>
                            <span>Annee</span>
                            <v-spacer />
                            <v-chip>{{ form.annee }}</v-chip>
                          </v-card-actions>
                          <v-card-actions>
                            <v-spacer />
                            <template v-if="id == 3">
                              <v-btn
                                color="primary"
                                :disabled="!isEnablePer"
                                @click="save"
                              >
                                {{ $t('abonnement.valide') }}
                              </v-btn>
                            </template>
                            <template v-else>
                              <v-btn
                                color="primary"
                                :disabled="!isEnable && selected !== 3"
                                @click="save"
                              >
                                {{ $t('abonnement.valide') }}
                              </v-btn>
                            </template>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-window-item>
                <!-- 
                <v-window-item :value="3">
                  <div class="pa-4 text-center">
                    <v-img
                      class="mb-4"
                      contain
                      height="128"
                      src="https://cdn.vuetifyjs.com/images/logos/v.svg"
                    ></v-img>
                    <h3 class="text-h6 font-weight-light mb-2">
                      Welcome to Vuetify
                    </h3>
                    <span class="text-caption grey--text"
                      >Thanks for signing up!</span
                    >
                  </div>
                </v-window-item> -->
              </v-window>

              <!-- <v-divider></v-divider> -->

              <v-card-actions class="mt-15">
                <v-btn :disabled="steps === 1" text @click="steps--">
                  {{ $t('abonnement.back') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="steps === 2 || selected === 0 || selected === 4"
                  color="primary"
                  depressed
                  @click="openAuthDialog"
                >
                  {{ $t('abonnement.next') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-container>
      </section>
      <v-dialog
        v-model="dialog"
        max-width="600px"
        class="col-auto"
        persistent
        scrollable
      >
        <v-card>
          <template>
            <v-card-title
              class="text-h6 font-weight-regular justify-space-between"
            >
              <span>{{ currentTitle }}</span>
              <v-spacer />
              <v-icon @click="closeAuthDialog">mdi-close</v-icon>
            </v-card-title>

            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text>
                  <v-text-field
                    v-model.trim.lazy="formLogin.username"
                    :label="$t('username')"
                  ></v-text-field>
                  <v-text-field
                    v-model.trim.lazy="formLogin.password"
                    :label="$t('password')"
                    type="password"
                  ></v-text-field>
                  <span class="text-caption grey--text text--darken-1">
                    {{ $t('createtext') }}?
                  </span>
                  <span
                    class="text-caption primary--text text--darken-1"
                    @click="step++"
                  >
                    {{ $t('signup') }}
                  </span>
                </v-card-text>
              </v-window-item>

              <v-window-item :value="2">
                <v-card-text>
                  <v-text-field
                    v-model.trim.lazy="formSignup.nom"
                    :label="$t('nom')"
                  ></v-text-field>
                  <v-text-field
                    v-model="formSignup.prenom"
                    :label="$t('prenom')"
                  ></v-text-field>
                  <v-text-field
                    v-model="formSignup.username"
                    :label="$t('username')"
                  ></v-text-field>
                  <v-text-field
                    v-model="formSignup.email"
                    :label="$t('email')"
                    type="email"
                  ></v-text-field>
                  <v-text-field
                    v-model="formSignup.tel"
                    :label="$t('tel')"
                    type="number"
                  ></v-text-field>
                  <v-text-field
                    v-model.trim.lazy="formSignup.password"
                    :label="$t('password')"
                    type="password"
                  ></v-text-field>
                  <span class="text-caption grey--text text--darken-1">
                    {{ $t('already') }}?
                  </span>
                  <span
                    class="text-caption primary--text text--darken-1"
                    @click="step--"
                  >
                    {{ $t('login') }}
                  </span>
                </v-card-text>
              </v-window-item>
            </v-window>

            <v-divider></v-divider>

            <v-card-actions>
              <!-- <v-btn :disabled="step === 1" text @click="step--">
                      Back
                    </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
                v-if="step !== 2"
                color="primary"
                depressed
                @click="submit"
              >
                {{ $t('home.seconnecter1') }}
              </v-btn>
              <v-btn v-else color="primary" depressed @click="submitSignup">
                {{ $t('home.sinscrire1') }}
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import i18n from '../../i18n';
import axios from 'axios';
import boutique from '../../components/Boutique.vue';
import { compressImage } from '../../helper';
export default {
  components: { boutique },
  data: () => {
    return {
      loading: true,
      dialog: false,
      e1: 1,
      step: 1,
      steps: 1,
      id: 0,
      selected: 0,
      annees: [
        {
          id: 1,
          libelle: '1 an',
        },
        {
          id: 2,
          libelle: '2 ans',
        },
      ],
      defaultAbonnement: {
        annee: {},
        pack: {},
        pieces: [],
        an: 0,
      },

      packs: [
        {
          id: 1,
          libelle: 'Elementaire',
          value: 'Elementaire',
          space: 1,
          price: 25,
        },
        {
          id: 2,
          libelle: 'Elite',
          value: 'Elite',
          space: 5,
          price: 70,
        },
        {
          id: 3,
          libelle: 'Entreprise',
          value: 'Entreprise',
          space: 0,
          price: 0,
        },
      ],

      form: {
        libelle: '',
        space: 0,
        price: 0,
        annee: 0,
        preuve: [],
        user: null,
      },

      formLogin: {
        username: '',
        password: '',
      },

      formSignup: {
        nom: '',
        prenom: '',
        username: '',
        email: '',
        tel: '',
        password: '',
      },

      paypal: {
        intent: 'sale',
        payer: {
          payment_method: 'paypal',
        },
        transactions: [],
        note_to_payer: "Contacter nous pour avoir plus d'explication.",
        redirect_urls: {
          return_url: 'http://localhost:8081/',
          cancel_url: 'http://localhost:8081/',
        },
      },

      transaction: {
        amount: {
          total: '0',
          currency: 'USD',
          details: {
            subtotal: '100.00',
            tax: '0',
            shipping: '0',
            handling_fee: '0',
            shipping_discount: '0',
            insurance: '0',
          },
        },
        description: 'Abonnement aux service oanke.',
        custom: 'EBAY_EMS_90048630024435',
        // invoice_number: '3',
        payment_options: {
          allowed_payment_method: 'INSTANT_FUNDING_SOURCE',
        },
        soft_descriptor: 'ECHI5786786',
        item_list: {
          items: [],
          shipping_address: {
            recipient_name: 'Kofi Nutefe',
            line1: 'Be Gbegnedi ecole Cheikh Anta Diop',
            line2: 'Rue #34',
            city: 'Lome Togo',
            country_code: 'US',
            postal_code: '00228',
            phone: '92254104',
            state: 'TG',
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['types', 'paypalToken', 'countAbonnement']),

    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },

    isEnable() {
      if (this.defaultAbonnement.pieces) {
        if (
          this.form.libelle != '' &&
          this.form.space != 0 &&
          this.form.price != 0 &&
          this.form.annee != 0 &&
          this.defaultAbonnement.pieces.length > 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    isEnablePer() {
      // console.log(this.form);
      if (
        this.form.libelle != '' &&
        this.form.space != '' &&
        this.form.annee != ''
      ) {
        return true;
      } else {
        return false;
      }
    },

    portefeuille() {
      if (this.$store.getters.portefeuille) {
        return this.$store.getters.portefeuille;
      } else {
        return null;
      }
    },

    currentTitle() {
      switch (this.step) {
        case 1:
          return this.$t('login');
        case 2:
          return this.$t('signup');
      }
    },

    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Sign-up';
        case 2:
          return 'Create a password';
        default:
          return 'Account created';
      }
    },
  },
  methods: {
    ...mapActions(['logIn', 'registerUser', 'checkUsername']),

    selectedItem(item) {
      this.selected = item;
      if (this.selected == 5) {
        if (!this.portefeuille) {
          this.$toast.error(this.$t('commande.msg1'));
        }
      }
    },
    openAuthDialog() {
      if (!this.isAuthenticated) {
        this.dialog = true;
        this.steps++;
      } else {
        this.steps++;
      }
    },

    closeAuthDialog() {
      if (!this.isAuthenticated) {
        this.dialog = false;
        this.steps--;
      } else {
        this.dialog = false;
      }
    },

    packChange() {
      if (this.defaultAbonnement.pack) {
        this.form = {
          libelle: this.defaultAbonnement.pack.libelle,
          space: this.defaultAbonnement.pack.space,
          price: this.defaultAbonnement.pack.price,
          annee: this.form.annee,
        };

        this.id = this.defaultAbonnement.pack.id;
      }
    },
    anneeChange() {
      if (this.defaultAbonnement.annee) {
        this.form.annee = this.defaultAbonnement.annee.id;
      }
    },

    inputAnnee() {
      this.form.annee = this.defaultAbonnement.an;
    },

    async submit() {
      try {
        this.$store.dispatch('logIn', this.formLogin);
        this.showError = false;
      } catch (error) {
        console.log(error);
        this.showError = true;
      }
    },

    async submitSignup() {
      try {
        // console.log(this.formSignup);
        if (
          this.formSignup.username != '' &&
          this.formSignup.nom != '' &&
          this.formSignup.tel != '' &&
          this.formSignup.email != '' &&
          this.formSignup.password != ''
        ) {
          await this.registerUser(this.formSignup);
          this.formSignup.username = '';
          this.formSignup.nom = '';
          this.formSignup.prenom = '';
          this.formSignup.password = '';
          this.formSignup.email = '';
          this.formSignup.tel = '';
          this.showError = false;
          this.step = 1;
        } else {
          if (this.$store.state.home.lng === 'English')
            this.$toast.error('Please fill all the fields');
          else this.$toast.error('Veuillez remplir tous les champs');
        }
      } catch (error) {
        this.showError = true;
      }
    },

    async save() {
      this.myloading = true;
      // console.log('CountAbonnement');
      // console.log(this.countAbonnement);
      let lang = this.$i18n.locale;

      if (this.selected !== 5) {
        if (
          this.form.libelle != '' &&
          this.form.space != 0 &&
          this.form.annee != 0
        ) {
          var compressedFichier = [];
          var totalFileSize = 0;
          var totalVideoSize = 0;
          if (this.defaultAbonnement.pieces) {
            if (this.defaultAbonnement.pieces.length != 0) {
              compressedFichier = await this.myfunc(
                this.defaultAbonnement.pieces
              );
              totalFileSize = await this.mysum(compressedFichier);
            }
          }

          let itemToSave = {
            pack: this.form.libelle,
            annee: this.form.annee,
            price: this.form.price * this.form.annee,
            space: this.form.space,
            pieces: compressedFichier,
          };

          // console.log(itemToSave);
          var max = this.$store.state.auth.file_max;
          var saved = this.$store.state.auth.file_size;
          var left = max - saved;
          var total = totalVideoSize + totalFileSize;
          if (total <= left) {
            try {
              await this.$store.dispatch('postAbonnement', itemToSave);
              await this.$store.dispatch('userme');
              this.$toast.success(this.$t('toast.save'));
            } catch (e) {
              console.log(e);
              this.$toast.error(this.$t('toast.error'));
              this.myloading = false;
            }
          } else {
            if (lang == 'fr')
              this.$toast.error(
                "Vous avez atteint votre limite d'images.Veuillez vous rendre dans l'onglet Message pour sousrire à une offre "
              );

            if (lang == 'en')
              this.$toast.error(
                'You have reached your limit of images, please go to the Message tab to subscribe to an offer'
              );
          }
        }
      } else{
        if (
          this.form.libelle != '' &&
          this.form.space != 0 &&
          this.form.annee != 0
        ) {
          var compressedFichier = [];
          // var totalFileSize = 0;
          // var totalVideoSize = 0;
          // if (this.defaultAbonnement.pieces) {
          //   if (this.defaultAbonnement.pieces.length != 0) {
          //     compressedFichier = await this.myfunc(
          //       this.defaultAbonnement.pieces
          //     );
          //     totalFileSize = await this.mysum(compressedFichier);
          //   }
          // }

          let itemToSave = {
            pack: this.form.libelle,
            annee: this.form.annee,
            price: this.form.price * this.form.annee,
            space: this.form.space,
            pieces: compressedFichier,
          };

          // console.log(itemToSave);
          // var max = this.$store.state.auth.file_max;
          // var saved = this.$store.state.auth.file_size;
          // var left = max - saved;
          // var total = totalVideoSize + totalFileSize;
          try {
              await this.$store.dispatch('postAbonnement', itemToSave);
              await this.$store.dispatch('userme');
              this.$toast.success(this.$t('toast.save'));
            } catch (e) {
              console.log(e);
              this.$toast.error(this.$t('toast.error'));
              this.myloading = false;
            }
        }
      } 
      // else {
      //   this.transaction.amount = {
      //     total: this.form.price * this.form.annee + '',
      //     currency: 'USD',
      //     details: {
      //       subtotal: this.form.price * this.form.annee + '',
      //       tax: '0',
      //       shipping: '0',
      //       handling_fee: '0',
      //       shipping_discount: '0',
      //       insurance: '0',
      //     },
      //   };
      //   this.transaction.item_list.items.push({
      //     name: 'Abonnement aux service oanke',
      //     description: '',
      //     quantity: '1',
      //     price: this.form.price * this.form.annee + '',
      //     tax: '0',
      //     sku: '0',
      //     currency: 'USD',
      //   });
      //   this.paypal.transactions.push(this.transaction);
      //   console.log(this.paypal);
      //   await this.$store.dispatch('loginPaypal', this.paypal);
      // }

      this.myloading = false;
    },

    async loginPaypal(paiement) {
      const params = new URLSearchParams();
      params.append('grant_type', 'client_credentials');
      axios
        .post('https://api-m.sandbox.paypal.com/v1/oauth2/token', params, {
          auth: {
            username:
              'ATNuFlOkiehKNKLDsvNHxu9ynWLkUltC6OMagOeE_X-EFnjDkgiKhdUxUL91YXFqZSFoPXvJWBaFmnHi',
            password:
              'EAlU4lxcZYmxaiRZDsYQVQeghI7oYdJ18lJaGlkgGygpxn-YwOsg3GTPU2iywlBKw3Aeuq0xjD2ZZ5z_',
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((response) => {
          if (response) {
            // console.log(response.data.access_token)
            // console.log(response);
            // commit("setPaypalToken", response.data)
            this.paied(paiement, response.data.access_token);
            // paied(paiement, response.data.access_token)
          }
        })
        .catch((error) => {
          console.log(error);

          if (rootState.home.lng === 'English')
            alert('Your credentials are incorrect, please change it');
          else alert('Vos identifiants sont incorrects');
        });
    },

    async paied(paiement, token) {
      // console.log(token);
      axios
        .post(
          'https://api-m.sandbox.paypal.com/v1/payments/payment',
          paiement,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          if (response) {
            console.log(response.data.links[1].href);
            window.open(response.data.links[1].href);
          }
        })
        .catch((error) => {
          console.log(error);
          if (rootState.home.lng === 'English')
            alert('Your credentials are incorrect, please change it');
          else alert('Vos identifiants sont incorrects');
        });
    },

    async myfunc(tab) {
      var myTab = [];
      for (var t in tab) {
        var cmp = await compressImage(tab[t]);
        myTab.push(cmp);
      }
      return myTab;
    },

    async mysum(tab) {
      var sum = 0;
      for (var t in tab) {
        sum = sum + tab[t].size;
      }
      return sum;
    },
  },
  mounted: function() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    } else {
      ths.id = 0;
    }

    let pack = this.packs.find((o) => o.id + '' === this.id);

    this.form = {
      libelle: pack.libelle,
      space: pack.space,
      price: pack.price,
      annee: 1,
    };

    this.defaultAbonnement = { pack: pack };

    this.loading = true;
  },

  mounted: function() {
    this.$store.dispatch('getCountAbonnement');
  },
};
</script>
<style scoped>
@media screen and (min-width: 1500px) {
  .contain-xxxl {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}

@media screen and (min-width: 1400px) {
  .contain-xxl {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}

@media screen and (max-width: 1200px) {
  .contain-xl {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}

@media screen and (max-width: 992px) {
  .contain-lg {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .contain-md {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

@media screen and (max-width: 576px) {
  .contain-sm {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
}

@media screen and (max-width: 360px) {
  .contain-esm {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
}

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

@media (max-width: 450px) {
  .col-xxs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 450px) {
  .col-xs-8r {
    width: 49%;
    float: left;
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .col-sm-8r {
    width: 33%;
    float: left;
  }
}

@media (min-width: 750px) {
  .col-xsm-8r {
    width: 25%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-8r {
    width: 16.5%;
    float: left;
  }
}

/* @media (min-width: 1240px) {
  .col-lg-8r {
    width: 12.5%;
    float: left;
  }
} */

@media (min-width: 1550px) {
  .col-xlg-8r {
    width: 12.5%;
    float: left;
  }
}
</style>

/* afficher la liste des categories dans le v-select Redirection vers cette page
à chaque modification de "categorie" verifier que la liste des catégories n'est
pas vide Chargement des albums selon la categorie affichage des albums */
