<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t("notations.list") }}</h4>
      </div>

      <div class="p-1">
        <v-card>
          <div class="row">
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
            <div class="col-12 col-sm-6 text-right pr-5">
              <v-spacer></v-spacer>
              <!-- <v-btn color="primary" dark class="mb-2" @click="dialog = true">
                {{ $t("shops.new") }}
              </v-btn> -->
            </div>
          </div>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="notations"
            class="elevation-1"
          >
            <template #[`item.user`]="{ item }">
              <span>{{ item.user.nom }} {{ item.user.prenom }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="showCommande(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t("voir") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog v-model="dialog" max-width="700px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5"
                >{{ $t("dashboard.detailcommande") }} ?</v-card-title
              >

              <div class="modal-body p-4">
                <div class="container-fluid px-0">
                  <div v-if="detail" class="row">
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("user.nom") }} :
                      </h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.user.nom }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("user.prenom") }} :
                      </h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.user.prenom }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("commande.adresseLiv") }} :
                      </h5>

                      <a
                        v-if="addressIncMaps(detail.adresseLivraison)"
                        :href="detail.adresseLivraison"
                      >
                        <v-icon color="red">
                          mdi-map
                        </v-icon></a
                      >
                      <span v-else class="text-muted" style="font-size: 19px">
                        {{ detail.adresseLivraison }}</span
                      >
                      <br />
                    </div>
                    <div class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("commande.numerro") }} :
                      </h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.numeroDestinataire }}</span
                      >
                      <br />
                    </div>
                    <div v-if="detail.detailCommande" class="">
                      <h5 class="mt-2" style="display: inline-block">
                        {{ $t("commande.detail") }} :
                      </h5>
                      <span class="text-muted" style="font-size: 19px">
                        {{ detail.detailCommande }}</span
                      >
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">{{
                  $t("commande.fermer")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { compressImage } from "../../helper";

export default {
  data: () => {
    return {
      myloading: false,
      alert: false,
      search: "",
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      detail: null,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("notations.user"),
          align: "center",
          sortable: false,
          value: "user",
        },
        {
          text: this.$t("notations.boutique"),
          align: "center",
          sortable: false,
          value: "boutique.nom",
        },
        {
          text: this.$t("notations.commande"),
          align: "center",
          sortable: true,
          value: "commande.idCommande",
        },
        {
          text: this.$t("notations.note"),
          align: "center",
          sortable: true,
          value: "note",
        },
        {
          text: this.$t("notations.commentaire"),
          align: "center",
          sortable: true,
          value: "commentaire",
        },
        { text: this.$t("shops.actions"), value: "actions", sortable: false },
      ];
    },

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("shops.newshop")
        : this.$t("shops.updateshop");
    },

    ...mapGetters(["loading", "notations"]),
  },
  created() {
    this.initialize();
    this.$store.dispatch("getNotations");
  },
  methods: {
    initialize() {},

    convertDate(str) {
      if (str) {
        return convertDate(str);
      } else {
        return 0;
      }
    },

    addressIncMaps(str) {
      if (str) {
        let result = str.includes("https://maps.google.com");
        return result;
      } else {
        return false;
      }
    },

    showCommande(item) {
      // console.log(item);
      this.dialog = true;
      this.detail = item.commande;
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
