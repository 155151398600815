import axios from 'axios';

const state = {
  listUsers: [],
  users: [],
  usersAct: [],
  nbrUser: 0,
  nbrCat: 0,
  nbrBout: 0,
  nbrArt: 0,
};
const getters = {
  listUsers: (state) => state.listUsers,
  users: (state) => state.users,
  usersAct: (state) => state.usersAct,
  nbrUser: (state) => state.nbrUser,
  nbrCat: (state) => state.nbrCat,
  nbrBout: (state) => state.nbrBout,
  nbrArt: (state) => state.nbrArt,
};
const actions = {
  async updateUser({ commit, rootState }, form) {
    let id = rootState.auth.id;
    let token = rootState.auth.token;
    try {
      axios
        .put('users_connect', form, { headers: { 'Authorization': 'Bearer ' + token } })
        .then(response => {
          console.log(response && response.status === 201)
          if (response) {
            commit("updateUser", { nom: form.nom, prenom: form.prenom, username: form.username, email: form.email });
          }
          else {
            console.log(rootState.home.lng);
            if (rootState.home.lng === 'English')
              alert("The username already exists, please change it")
            else
              alert("Cet username existe déjà, veuillez changez le username saisi")
          }
        }
        )
    } catch (error) {
      console.log(error)
    }

  },
  async deleteUser({ rootState }) {
    let token = rootState.auth.token;
    await axios.delete('users_connect', { headers: { 'Authorization': 'Bearer ' + token } });
  },
  async updateFileSpace({ rootState }, id) {
    let token = rootState.auth.token;
    // console.log(token)
    await axios.get('users/size/' + id, {
      headers: { 'Authorization': 'Bearer ' + token }
    });
  },
  async getAllUsers({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('users_simple', {
      headers: { 'Authorization': 'Bearer ' + token }
    });
    commit("setListUsers", { listUsers: response.data });
  },

  async getAllUserFour({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('users/inactive', { headers: { 'Authorization': 'Bearer ' + token } });
    console.log(response)
    commit("setUsers", { users: response.data });
  },

  async getAllUserFourAct({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('users/active', { headers: { 'Authorization': 'Bearer ' + token } });
    console.log(response)
    commit("setUsersAct", { usersAct: response.data });
  },

  async enableFour({ dispatch, rootState }, id) {
    let token = rootState.auth.token;
    let response = await axios.get("user/active/fournisseur/" + id, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    dispatch("getAllUserFour")

  },

  async updatePassword({ rootState }, password) {
    let token = rootState.auth.token;
    let id = rootState.auth.id;
    let response = await axios.put("update_pass/", { nouveau: password.new, ancien: password.old }, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
  },

  async updateUserFour({ rootState }, user) {
    let token = rootState.auth.token;
    var bodyFormData = new FormData();
    bodyFormData.append('numero', user.numero);
    bodyFormData.append('taxId', user.taxId);
    user.pieces.forEach(element => {
      bodyFormData.append('pieces', element, element.name);
    });
    await axios.post('user/piece/' + user.id,
      bodyFormData, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  async LogOut({ commit }) {
    commit("logout");
  },

  async getNbrUser({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('count/user', { headers: { 'Authorization': 'Bearer ' + token } });
    commit("setNbrUser", response.data);
  },
  async getNbrCat({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('count/categorie', { headers: { 'Authorization': 'Bearer ' + token } });
    commit("setNbrCat", response.data);
  },
  async getNbrBout({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('count/boutique', { headers: { 'Authorization': 'Bearer ' + token } });
    commit("setNbrBout", response.data);
  },
  async getNbrArt({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('count/article', { headers: { 'Authorization': 'Bearer ' + token } });
    commit("setNbrArt", response.data);
  },
};

const mutations = {
  setListUsers(state, { listUsers }) {
    state.listUsers = listUsers;
  },
  setUsers(state, { users }) {
    state.users = users;
  },

  setUsersAct(state, { usersAct }) {
    state.usersAct = usersAct;
  },

  setNbrUser(state, nbrUser) {
    state.nbrUser = nbrUser;
  },
  setNbrCat(state, nbrCat) {
    state.nbrCat = nbrCat;
  },
  setNbrBout(state, nbrBout) {
    state.nbrBout = nbrBout;
  },
  setNbrArt(state, nbrArt) {
    state.nbrArt = nbrArt;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};