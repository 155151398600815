<template>
  <div style="padding-top: 100px;">
    <div class="container">
      <div class="row">
        <div class="col-auto" style="padding-left: 12px;padding-top:0">
          <div class="col" style="padding-top:0">
            <div class="tiny-slide">
              <img
                :src="album.photo_entete"
                class="img-fluid rounded"
                style="height: 300px;"
                alt=""
              />
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="section-title ms-md-4">
            <h4 class="title">{{ album.titre }}</h4>
            <h5>{{ $t("cat") }} : {{ album.categorie.libelle }}</h5>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <div class="container pb-10 mb-5">
      <div class="row">
        <div
          class="col-lg-3 col-md-6 col-12 mt-4 pt-2"
          v-for="image in images"
          :key="image.id"
        >
          <div
            class="card shop-list border-0 position-relative"
            style="height: 100%"
          >
            <div
              class="shop-image position-relative overflow-hidden rounded shadow"
              style="box-shadow: 0px 0px 2px 2px rgb(60 72 88 / 15%) !important; height: 100%;"
            >
              <img
                :src="image.name"
                class="img-fluid"
                alt="fichier introuvable"
                style="width:100%; height: 100%"
              />
              <ul class="list-unstyled shop-icons">
                <li class="mt-2">
                  <a
                    @click="setImage(image)"
                    data-bs-toggle="modal"
                    data-bs-target="#productview"
                    class="btn btn-icon btn-pills btn-soft-primary"
                    ><i class="fa fa-eye icon"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--end col-->
        <div
          class="col-lg-3 col-md-6 col-12 mt-4 pt-2"
          v-for="video in videos"
          :key="video.id"
        >
          <div class="card shop-list border-0 position-relative">
            <div
              class="shop-image position-relative overflow-hidden rounded shadow"
              style="box-shadow: 0px 0px 2px 2px rgb(60 72 88 / 15%) !important;"
            >
              <video
                controls
                :src="video.name"
                class="img-fluid"
                alt="fichier introuvable"
                style="width:100%; min-height: 260px"
              />
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->

    <!-- Product View Start -->
    <div
      class="modal fade"
      id="productview"
      tabindex="-1"
      aria-labelledby="productview-title"
      aria-hidden="true"
    >
      <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content rounded shadow border-0">
          <div class="modal-header border-bottom">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body p-4">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-5">
                  <div class="tiny-slide">
                    <img :src="image.name" class="img-fluid rounded" alt="" />
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-7 mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <h5 class="mt-4" style="display: inline-block">Album :</h5>
                  <span class="text-muted" style="font-size: 19px">
                    {{ album.titre }}</span
                  >
                  <br />

                  <h5 class="mt-4" style="display: inline-block">
                    {{ $t("shop") }} :
                  </h5>
                  <span class="text-muted" style="font-size: 19px">
                    {{ album.boutique.nom }}</span
                  ><br />

                  <h5 class="mt-4" style="display: inline-block">
                    {{ $t("publication") }} :
                  </h5>
                  <span class="text-muted" style="font-size: 19px">
                    {{ getDate(album.createdAt) }}</span
                  >
                </div>
                <!--end row-->
              </div>
              <!--end row-->
            </div>
            <!--end container-->
          </div>
        </div>
      </div>
    </div>
    <!-- Product View End -->
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import i18n from "../../i18n";
export default {
  data: () => {
    return {
      image: {},
      loading: true,
    };
  },
  methods: {
    setImage(image) {
      this.image = image;
    },
    copy() {
      let url = window.location.href;
      navigator.clipboard.writeText(url);
    },
    getDate(mydate) {
      let splitted = mydate.split(`T`);
      let date = splitted[0];
      if (i18n.locale === "en") {
        return date;
      } else {
        let split = date.split(`-`);
        let dt = `${split[2]}-${split[1]}-${split[0]}`;
        return dt;
      }
    },
  },
  computed: {
    ...mapGetters(["album", "fichiers"]),
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    },
    nom: function() {
      if (this.image.name != undefined) {
        let splitted = this.image.name.split("/");
        return splitted[splitted.length - 1];
      } else return "";
    },
    images: function() {
      let images = [];
      this.fichiers.forEach((fichier) => {
        let splitted = fichier.name.split(".");
        let ext = splitted[splitted.length - 1];
        if (!(ext == "mkv" || ext == "avi" || ext == "mp4")) {
          images.push(fichier);
        }
      });
      return images;
    },
    videos: function() {
      let videos = [];
      this.fichiers.forEach((fichier) => {
        let splitted = fichier.name.split(".");
        let ext = splitted[splitted.length - 1];
        if (ext == "mkv" || ext == "avi" || ext == "mp4") {
          videos.push(fichier);
        }
      });
      return videos;
    },
  },
  created: function() {
    let id = this.$route.params.idAlb;
    this.$store.dispatch("getAlbum", id);
  },
};
</script>
