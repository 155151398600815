import axios from 'axios';

const state = {
  listTypes: []
};
const getters = {
  listTypes: (state) => state.listTypes,
};
const actions = {
  async updateTypes({ commit, rootState }, form) {
    let id = rootState.auth.id;
    let token = rootState.auth.token;
    try {
      axios
        .put('/types/boutique', form, { headers: { 'Authorization': 'Bearer ' + token } })
        .then(response => {
          console.log(response && response.status === 201)
          if (response) {
            commit("updateTypes", { libelle: form.libelle });
          }
          else {
            console.log(rootState.home.lng);
            if (rootState.home.lng === 'English')
              alert("The username already exists, please change it")
            else
              alert("Cet username existe déjà, veuillez changez le username saisi")
          }
        }
        )
    } catch (error) {
      console.log(error)
    }

  },
  async postType({ dispatch, rootState }, type) {
    let token = rootState.auth.token;
    var bodyFormData = new FormData();

    bodyFormData.append('libelle', type.libelle);
    bodyFormData.append('image', type.image);

    await axios.post('type/boutique',
      bodyFormData, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data'
      }
    });
    dispatch("getAllTypes")
  },
  async updateType({ dispatch, rootState }, type) {
    let token = rootState.auth.token;
    var bodyFormData = new FormData();

    bodyFormData.append('libelle', type.libelle);
    bodyFormData.append('image', type.image);

    await axios.put('type/boutique/' + type.id,
      bodyFormData, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data'
      }
    });
    dispatch("getAllTypes")
  },
  async deleteTypes({ rootState }, type) {
    let token = rootState.auth.token;
    await axios.delete('type/boutique/' + type.id, { headers: { 'Authorization': 'Bearer ' + token } });
    dispatch("getAllTypes")

  },
  async getAllTypes({ commit, rootState }) {
    let token = rootState.auth.token;
    let response = await axios.get('/types/boutique', { headers: { 'Authorization': 'Bearer ' + token } });
    commit("setListTypes", { listTypes: response.data });
  },

  async LogOut({ commit }) {
    commit("logout");
  },
};

const mutations = {
  setListTypes(state, { listTypes }) {
    state.listTypes = listTypes;
  },


};

export default {
  state,
  getters,
  actions,
  mutations,
};