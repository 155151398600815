<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t("user.title") }}</h4>
      </div>

      <div class="p-1">
        <v-alert v-model="alert" dense type="success" dismissible>
          {{ $t("success1") }}
        </v-alert>
        <v-card
          ><div class="row">
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
            <div class="col-12 col-sm-6 text-right pr-5"></div>
          </div>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="usersAct"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                v-for="i in item.pieces"
                :key="i.id"
                small
                class="mr-2"
                @click="showPiece(i.url)"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
          <v-dialog v-model="dialog" max-width="400px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-text class="text-h5 pt-10"
                >{{ $t("user.enable") }} ?</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="save">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
export default {
  data: () => {
    return {
      myloading: false,
      alert: false,
      search: "",
      dialog: false,

      editedIndex: -1,

      editedItem: {
        id: 0,
        idBoutique: 0,
        libelle: "",
        categorie: {},
      },
      defaultItem: {
        id: 0,
        idBoutique: 0,
        libelle: "",
        categorie: {},
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("user.username"),
          align: "center",
          sortable: true,
          value: "username",
        },
        {
          text: this.$t("user.nom"),
          align: "center",
          sortable: true,
          value: "nom",
        },
        {
          text: this.$t("user.prenom"),
          align: "center",
          sortable: true,
          value: "prenom",
        },
        {
          text: this.$t("user.numero"),
          align: "center",
          sortable: false,
          value: "numero",
        },
        {
          text: this.$t("user.role"),
          align: "center",
          sortable: true,
          value: "role",
        },
        { text: this.$t("shops.actions"), value: "actions", sortable: false },
      ];
    },

    ...mapGetters(["loading", "usersAct"]),
  },
  created() {
    this.initialize();
    this.$store.dispatch("getAllUserFourAct");
  },
  methods: {
    async check(libelle) {
      await this.$store.dispatch("checkCategorie", libelle);
    },

    initialize() {},

    setSelected(value) {},

    async showPiece(url) {
      await axios
        .get(url, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          const type = response.headers["content-type"];
          let blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8",
          });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({});
    },

    editItem(item) {
      // console.log(item);
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },

    save() {
      this.myloading = true;
      let lang = this.$i18n.locale;
      let token = this.$store.state.auth.token;
      let pass = false;

      this.$store.dispatch("enableFour", this.editedItem.idUser);
      this.$toast.success(this.$t("toast.enable"));
      this.close();
    },
  },
};
</script>
