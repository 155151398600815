<template>
  <div class="col  pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0 col" style="display: inline-block;">
          {{ $t("abonnement.list") }}
        </h4>

        <div class="row p-4">
          <div class="row">
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
            <div class="col-12 col-sm-6 text-right pr-5">
              <v-spacer></v-spacer>
              <router-link
                class="text-dark fw-bold"
                :to="{
                  path: `/${$i18n.locale}/souscription/1`,
                }"
              >
                <v-btn color="primary" dark class="mb-2">
                  {{ $t("abonnement.new") }}
                </v-btn>
              </router-link>
            </div>
          </div>

          <v-data-table
            :search="search"
            :headers="headers"
            :items="abonnementsUser"
            class="elevation-1"
          >
            <!-- <template #[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="openDialog1(item)">
                mdi-pencil
              </v-icon>
            </template> -->
          </v-data-table>
        </div>

        <v-dialog
          v-model="dialog"
          persistent
          max-width="700px"
          class="col-auto mt-15"
        >
          <v-card :loading="myloading" :disabled="myloading" class="mt-15">
            <v-card-title>
              <span class="text-h5">{{ $t("abonnement.new") }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="defaultAbonnement.pack"
                      :label="$t('abonnement.pack')"
                      :items="packs"
                      item-text="libelle"
                      item-value="value"
                      return-object
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="defaultAbonnement.pieces"
                      accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp"
                      :label="$t('abonnement.select')"
                      multiple
                      prepend-icon="mdi-paperclip"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>

              <small>*{{ $t("required") }}</small>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="blue darken-1" text @click="dialog = false">
                {{ $t("cancel") }}
              </v-btn>

              <v-btn color="blue darken-1" text @click="save">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-row justify="center"> </v-row>

      <v-dialog v-model="dialog1" persistent max-width="600px" class="col-auto">
        <v-card :loading="myloading" :disabled="myloading">
          <v-card-title>
            <span class="text-h5">{{ $t("delete1") }} ?</span>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="close1">
              {{ $t("cancel") }}
            </v-btn>

            <v-btn color="blue darken-1" text @click="deleteAlbum">
              OK
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog2" persistent max-width="600px" class="col-auto">
        <v-card :loading="myloading" :disabled="myloading">
          <v-card-title>
            <span class="text-h5">{{ $t("abonnement.update") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="defaultAbonnement.pack"
                    :label="$t('abonnement.pack')"
                    :items="packs"
                    item-text="libelle"
                    item-value="value"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="defaultAbonnement.pieces"
                    accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp"
                    :label="$t('abonnement.select')"
                    multiple
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close2">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="update">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--end row-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
import te from "/public/js/plugins.init.js";
import tr from "/public/js/tobii.min.js";
import tt from "/public/js/bootstrap.bundle.min.js";
import td from "/public/js/shuffle.min.js";
import { compressImage } from "../../helper";
export default {
  data: () => {
    return {
      albu: {},
      myloading: false,
      search: "",
      dialog1: false,
      dialog2: false,
      alert1: false,
      alert2: false,
      alert: false,
      files: [],
      dialog: false,
      categories: [1, 2, 3],

      packs: [
        {
          id: 1,
          libelle: "Elementaire",
          value: "Elementaire",
        },
        {
          id: 2,
          libelle: "Elite",
          value: "Elite",
        },
        {
          id: 3,
          libelle: "Entreprise",
          value: "Entreprise",
        },
      ],
      defaultAbonnement: {
        pack: {},
        pieces: [],
      },

      initialAbonnement: {
        id: 0,
        pack: {},
        pieces: [],
      },
    };
  },

  watch: {
    dialog1(val) {
      val || this.close1();
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("type.name"),
          align: "center",
          sortable: false,
          value: "pack",
        },
        { text: this.$t("shops.actions"), value: "actions", sortable: false },
      ];
    },

    tab: function() {
      return [this.$t("stock"), this.$t("command")];
    },

    ...mapGetters(["loading", "abonnementsUser"]),
  },

  created() {
    this.$store.dispatch("getAbonnementUser");
  },

  methods: {
    openDialog(alb) {
      this.albu = alb;
      this.dialog1 = true;
    },

    openDialog1(alb) {
      this.albu = alb;
      this.dialog2 = true;
      console.log(this.albu);
      this.defaultAbonnement = {
        pack: this.packs.find((e) => e.value === this.albu.pack),
      };
    },

    setGallery(image) {
      this.image = image;
    },

    close1() {
      this.myloading = false;
      this.dialog1 = false;
      this.albu = {};
      this.$nextTick(() => {
        this.defaultAbonnement = Object.assign({}, this.initialAbonnement);
      });
    },

    close2() {
      this.myloading = false;
      this.dialog2 = false;
      this.albu = {};
      this.$nextTick(() => {
        this.defaultAbonnement = {};
      });
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.defaultAbonnement = Object.assign({}, this.initialAbonnement);
      });
    },

    async deleteAlbum() {
      this.myloading = true;
      try {
        console.log(this.initialAbonnement);
        await this.$store.dispatch("deleteOneAbonnement", this.albu.id);
        this.alert1 = true;
        this.close1();
      } catch (e) {}
    },

    async tr(file) {
      let compressed = await compressImage(file);
      return compressed;
    },

    async save() {
      this.myloading = true;
      var pack = this.defaultAbonnement.pack;
      var pieces = this.defaultAbonnement.pieces;
      let lang = this.$i18n.locale;
      if (pack != null && pieces != null) {
        var compressedFichier = [];
        var totalFileSize = 0;
        var totalVideoSize = 0;

        if (pieces.length != 0) {
          compressedFichier = await this.myfunc(pieces);
          totalFileSize = await this.mysum(compressedFichier);
        }

        let itemToSave = {
          pack: this.defaultAbonnement.pack.value,
          pieces: compressedFichier,
        };
        var max = this.$store.state.auth.file_max;
        var saved = this.$store.state.auth.file_size;
        var left = max - saved;
        var total = totalVideoSize + totalFileSize;
        if (total <= left) {
          try {
            await this.$store.dispatch("postAbonnement", itemToSave);
            await this.$store.dispatch("userme");
            this.$toast.success(this.$t("toast.save"));
            this.close();
          } catch (e) {
            this.$toast.error(this.$t("toast.error"));
            this.myloading = false;
          }
        } else {
          if (lang == "fr")
            alert(
              "Vous avez atteint votre limite d'images.Veuillez vous rendre dans l'onglet Message pour sousrire à une offre "
            );

          if (lang == "en")
            alert(
              "You have reached your limit of images, please go to the Message tab to subscribe to an offer"
            );
        }
      }
      this.myloading = false;
    },

    async myfunc(tab) {
      var myTab = [];
      for (var t in tab) {
        var cmp = await compressImage(tab[t]);
        myTab.push(cmp);
      }
      return myTab;
    },

    async mysum(tab) {
      var sum = 0;
      for (var t in tab) {
        sum = sum + tab[t].size;
      }
      return sum;
    },

    async update() {
      this.myloading = true;
      var pack = this.defaultAbonnement.pack;
      var pieces = this.defaultAbonnement.pieces;
      let itemToSave;
      if (pack != null) {
        var compressedFichier = [];

        if (pieces) {
          if (pieces.length != 0) {
            compressedFichier = await this.myfunc(pieces);
          }
        }

        itemToSave = {
          id: this.albu.id,
          pack: this.defaultAbonnement.pack.value,
          pieces: compressedFichier,
        };

        try {
          await this.$store.dispatch("updateAbonnement", itemToSave);
          await this.$store.dispatch("userme");
          this.$toast.success(this.$t("toast.update"));
          this.close2();
        } catch (e) {
          this.$toast.error(this.$t("toast.error"));
        }
        this.myloading = false;
      }
    },
  },
};
</script>
