import axios from 'axios';

const state = {
	boutiques: [],
	boutiqueActif: [],
	boutiqueInactif: [],
	boutiquesByUsers: [],
	boutiquesByUsersCon: [],
	catBoutiques: [],
	loading: true
};

const getters = {
	boutiques: (state) => state.boutiques,
	boutiqueActif: (state) => state.boutiqueActif,
	boutiqueInactif: (state) => state.boutiqueInactif,
	boutiquesByUsers: (state) => state.boutiquesByUsers,
	boutiquesByUsersCon: (state) => state.boutiquesByUsersCon,
	catBoutiques: (state) => state.catBoutiques,
};

const actions = {
	async getBoutiques({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('boutiques_not_deleted', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setBoutiques', { boutiques: data })
	},
	async getBoutiqueActif({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('boutiques/actif', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setBoutiqueActif', { boutiqueActif: data })
	},
	async getBoutiqueInactif({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('boutiques/inactif', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setBoutiqueInactif', { boutiqueInactif: data })
	},
	async getCateBoutiques({ commit, rootState }, id) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('auth/categories_boutique/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCatBoutiques', { catBoutiques: data })
	},
	async getBoutiquesByUsers({ commit, rootState }) {
		let token = rootState.auth.token;
		let id = rootState.auth.id;
		let response = await axios.get('boutiques_user/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setBoutiquesByUsers', { boutiquesByUsers: data })
	},
	async getBoutiquesByUsersConnect({ commit, rootState }) {
		let token = rootState.auth.token;
		let id = rootState.auth.id;
		let response = await axios.get('boutiques/user/connect', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setBoutiquesByUsersCon', { boutiquesByUsersCon: data })
	},
	async deleteBoutique({ dispatch, rootState }, boutique) {
		let token = rootState.auth.token;
		await axios.delete('boutiques/' + boutique.idBoutique, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getBoutiquesByUsers")
	},
	async updateBoutique({ dispatch, rootState }, boutique) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();

		bodyFormData.append('typeBoutique', boutique.typeBoutique.id);
		bodyFormData.append('nom', boutique.nom);
		bodyFormData.append('telephone', boutique.telephone);
		bodyFormData.append('adresse', boutique.adresse);
		bodyFormData.append('lien', boutique.lien);
		bodyFormData.append('description', boutique.description);
		bodyFormData.append('pays', boutique.pays.id);
		bodyFormData.append('image', boutique.image);

		await axios.put('boutiques/image/current/' + boutique.idBoutique,
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getBoutiquesByUsers")
	},
	async postBoutique({ dispatch, rootState }, boutique) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();

		bodyFormData.append('typeBoutique', boutique.typeBoutique.id);
		bodyFormData.append('nom', boutique.nom);
		bodyFormData.append('telephone', boutique.telephone);
		bodyFormData.append('adresse', boutique.adresse);
		bodyFormData.append('lien', boutique.lien);
		bodyFormData.append('description', boutique.description);
		bodyFormData.append('pays', boutique.pays.id);
		bodyFormData.append('image', boutique.image);

		await axios.post('boutiques/image/current',
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getBoutiquesByUsers")
	},
	async activerBoutique({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		await axios.get('enable/boutique/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getBoutiqueInactif")
	},
	async desactiverBoutique({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		await axios.get('disenable/boutique/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getBoutiqueActif")

	},
};

const mutations = {
	loading(state) {
		state.loading = true;
	},

	setBoutiques(state, { boutiques }) {
		state.boutiques = boutiques;
		state.loading = false;
	},
	setBoutiqueActif(state, { boutiqueActif }) {
		state.boutiqueActif = boutiqueActif;
		state.loading = false;
	},
	setBoutiqueInactif(state, { boutiqueInactif }) {
		state.boutiqueInactif = boutiqueInactif;
		state.loading = false;
	},
	setBoutiquesByUsers(state, { boutiquesByUsers }) {
		state.boutiquesByUsers = boutiquesByUsers;
		state.loading = false;
	},
	setBoutiquesByUsersCon(state, { boutiquesByUsersCon }) {
		state.boutiquesByUsersCon = boutiquesByUsersCon;
		state.loading = false;
	},
	setCatBoutiques(state, { catBoutiques }) {
		state.catBoutiques = catBoutiques;
		state.loading = false;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};