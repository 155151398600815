import axios from 'axios';

const state = {
	abonnements: [],
	abonnementsUser: [],
	abonnementPage: [],
	oneAbonnement: {},
	loading: true,
	countAbonnement: 0,
};

const getters = {
	oneAbonnement: (state) => state.oneAbonnement,
	abonnements: (state) => state.abonnements,
	abonnementsUser: (state) => state.abonnementsUser,
	abonnementPage: (state) => state.abonnementPage,
	countAbonnement: (state) => state.countAbonnement,
};

const actions = {
	async getCountAbonnement({ commit, rootState }) {
		let token = rootState.auth.token;
		let response = await axios.get('auth/count/abonnement', {
			headers: { 'Authorization': 'Bearer ' + token }
		});
		let data = response.data;
		// console.log(data)
		commit('setCountAbonnements', { countAbonnement: data })
	},
	async getAbonnement({ commit, rootState }) {
		let token = rootState.auth.token;
		let response = await axios.get('abonnements', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAbonnements', { abonnements: data })
	},
	async getAbonnementUser({ commit, rootState }) {
		let token = rootState.auth.token;
		let response = await axios.get('abonnements/user', { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAbonnementsUser', { abonnementsUser: data })
	},
	async getAbonnementPage({ commit, rootState }, page) {
		let token = rootState.auth.token;
		let response = await axios.get('abonnements/page/' + page, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAbonnementPage', { abonnementPage: data })
	},
	async getOneAbonnement({ commit, rootState }, id) {
		let token = rootState.auth.token;
		let response = await axios.get('abonnement/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;

		commit('setOneAbonnement', { oneAbonnement: data })
	},
	async deleteOneAbonnement({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		let response = await axios.delete('abonnement/' + id, {
			 headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		dispatch('getEvenement')
	},
	async postAbonnement({ dispatch, rootState }, abonnement) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('pack', abonnement.pack);
		bodyFormData.append('price', abonnement.price);
		bodyFormData.append('annee', abonnement.annee);
		bodyFormData.append('space', abonnement.space);
		abonnement.pieces.forEach(element => {
			bodyFormData.append('pieces', element, element.name);
		});
		await axios.post('abonnement',
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getAbonnementUser")
	},
	async updateAbonnement({ dispatch, rootState }, abonnement) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('pack', abonnement.pack);
		bodyFormData.append('price', abonnement.price);
		bodyFormData.append('annee', abonnement.annee);
		bodyFormData.append('space', abonnement.space);
		abonnement.pieces.forEach(element => {
			bodyFormData.append('pieces', element, element.name);
		});
		await axios.put('abonnement/' + abonnement.id,
			bodyFormData, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'multipart/form-data'
			}
		});
		dispatch("getAbonnementUser")
	},
};

const mutations = {
	loading(state) {
		state.loading = true;
	},

	setCountAbonnements(state, { countAbonnement }) {
		state.countAbonnement = countAbonnement;
		// state.loading = false;
	},

	setAbonnements(state, { abonnements }) {
		state.abonnements = abonnements;
		state.loading = false;
	},

	setAbonnementsUser(state, { abonnementsUser }) {
		state.abonnementsUser = abonnementsUser;
		state.loading = false;
	},
	setAbonnementPage(state, { abonnementPage }) {
		state.abonnementPage = abonnementPage;
		state.loading = false;
	},
	setOneAbonnement(state, { oneAbonnement }) {
		state.oneAbonnement = oneAbonnement;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};