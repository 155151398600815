<template>
  <v-app>
    <div>
      <div class="back-to-home rounded d-none d-sm-block">
        <router-link :to="{ path: `/${$i18n.locale}` }"
          ><div class="btn btn-icon btn-primary"><i class="fa fa-home"></i></div
        ></router-link>
      </div>

      <!-- Hero Start -->
      <section class="bg-home bg-circle-gradiant d-flex align-items-center">
        <div class="bg-overlay bg-overlay-white"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-8">
              <div class="card login-page bg-white shadow rounded border-0">
                <div class="card-body">
                  <h4 class="card-title text-center">{{ $t('login') }}</h4>
                  <form class="login-form mt-4" @submit.prevent="submit">
                    <div class="row">
                      <div class="col-lg-12">
                        <v-alert
                          :value="alert"
                          color="blue"
                          dark
                          border="top"
                          transition="scale-transition"
                        >
                          Veuillez cliquer sur le lien d'activation que nous
                          vous avons envoyé par courrier électronique. Si vous
                          n'avez pas reçu le courrier électronique d'ici
                          quelques minutes, veuillez vérifier votre dossier spam
                        </v-alert>
                      </div>

                      <div class="col-lg-12">
                        <div class="mb-3">
                          <label for="username" class="form-label"
                            >{{ $t('username') }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-user icon"></i>
                            <input
                              type="text"
                              class="form-control ps-10"
                              :placeholder="$t('username')"
                              name="u"
                              required=""
                              v-model="form.username"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12">
                        <div class="mb-3">
                          <label for="password" class="form-label"
                            >{{ $t('password') }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-key icon"></i>
                            <input
                              type="password"
                              class="form-control ps-10"
                              :placeholder="$t('password')"
                              required=""
                              v-model="form.password"
                            />
                          </div>
                          <p class="mb-0">
                            <router-link
                              class="text-dark fw-bold"
                              :to="{ path: `/${$i18n.locale}/forgotPassword` }"
                              >{{ $t('forget') }}</router-link
                            >
                          </p>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12 mb-0">
                        <div class="d-grid">
                          <button type="submit" class="btn btn-primary">
                            {{ $t('connexion') }}
                          </button>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-12 text-center">
                        <p class="mb-0 mt-3">
                          <small class="text-dark me-2"
                            >{{ $t('createtext') }} ?</small
                          >
                          <router-link
                            class="text-dark fw-bold"
                            :to="{ path: `/${$i18n.locale}/signup` }"
                            >{{ $t('signup') }}</router-link
                          >
                        </p>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
              <!---->
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
    </div>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      alert: false,
      form: {
        username: '',
        password: '',
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(['LogIn']),
    async submit() {
      try {
        this.$store.dispatch('LogIn', this.form);
        this.showError = false;
      } catch (error) {
        console.log(error);
        this.showError = true;
      }
    },
  },

  mounted() {
    let params = this.$route.query.alert;
    // console.log(params);
    if (params && params == 1) {
      this.alert = true;
    }
  },
};
</script>
<style scoped>
.icon {
  padding: 10px;
  min-width: 40px;
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}
</style>
