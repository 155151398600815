import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueI18n from 'vue-i18n'
import colors from 'vuetify/lib/util/colors'
import n from '../i18n'

Vue.use(Vuetify);
Vue.use(VueI18n)

const messages = {
  en: {
      acceuil:{
        allcat: "All categories"
      },
      $vuetify: {
        close: 'Close',
        noDataText: 'No data',
        dataTable: {
          itemsPerPageText: 'Items per page:'
        },
        dataFooter: {
          itemsPerPageAll: 'All',
          pageText: '{0}-{1} of {2}',
          prevPage: 'Previous',
          nextPage: 'Next'
        },
      },
  },
  fr: {
      acceuil:{
        allcat: "Toutes les catégories"
      },
      $vuetify: {
        close: 'Fermer',
        noDataText: 'Aucune donnée',
        dataTable: {
          itemsPerPageText: 'Elements par pages:'
        },
        dataFooter: {
          itemsPerPageAll: 'Tout',
          pageText: '{0}-{1} de {2}',
          prevPage: 'Précédent',
          nextPage: 'Suivant'
        },
      },
    },
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: n.locale, // set locale
  messages: messages, // set locale messages
})

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
    icons: {
        iconfont: 'md' || 'fa' 
      },
      theme: {
        themes: {
            light: {
                background: colors.grey.lighten2, // Not automatically applied
              },
          dark: {
            background: colors.shades.white, // If not using lighten/darken, use base to return hex
          },
        },
      },
});
