<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t("shops.list") }}</h4>
      </div>

      <div class="p-1">
        <v-card>
          <div class="row">
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
            <div class="col-12 col-sm-6 text-right pr-5">
              <v-spacer></v-spacer>
              <!-- <v-btn color="primary" dark class="mb-2" @click="dialog = true">
                {{ $t("shops.new") }}
              </v-btn> -->
            </div>
          </div>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="boutiqueActif"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t("inactif") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog
            v-model="dialog"
            max-width="700px"
          >
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title>
                <span class="text-h5">{{ $t("shops.inactif") }}</span>
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { compressImage } from "../../helper";

export default {
  data: () => {
    return {
      myloading: false,
      alert: false,
      search: "",
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        nom: "",
        adresse: "",
        telephone: "",
        lien: "",
        description: "",
        pays: null,
        typeBoutique: null,
        image: null,
      },
      defaultItem: {
        id: 0,
        nom: "",
        adresse: "",
        telephone: "",
        lien: "",
        description: "",
        pays: null,
        typeBoutique: null,
        image: null,
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("user.utilisateur"),
          align: "center",
          sortable: false,
          value: "user.nom",
        },
        {
          text: this.$t("user.username"),
          align: "center",
          sortable: false,
          value: "user.username",
        },
        {
          text: this.$t("shops.name"),
          align: "center",
          sortable: false,
          value: "nom",
        },
        {
          text: this.$t("shops.adress"),
          align: "center",
          sortable: false,
          value: "adresse",
        },
        {
          text: this.$t("shops.contact"),
          align: "center",
          sortable: false,
          value: "telephone",
        },
        { text: this.$t("shops.actions"), value: "actions", sortable: false },
      ];
    },

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("shops.newshop")
        : this.$t("shops.updateshop");
    },

    ...mapGetters(["loading", "boutiqueActif", "listPays", "listTypes"]),
  },
  created() {
    this.initialize();
    this.$store.dispatch("getBoutiqueActif");
    this.$store.dispatch("getAllPays");
    this.$store.dispatch("getAllTypes");
  },
  methods: {
    initialize() {},

    editItem(item) {
      this.editedIndex = this.boutiqueActif.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    copyLink(item) {
      const el = document.createElement("textarea");
      let url = window.location.href;
      let splitted = url.split(".com");
      let domain = splitted[0];
      let btname = item.nom;
      let btnamereplace = btname.replace(" ", "%20");
      let finalUrl = `${domain}.com/fr/shopHome/${btnamereplace}`;
      el.value = finalUrl;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      try {
        this.$store.dispatch("desactiverBoutique", this.editedItem.idBoutique);
        this.$toast.success(this.$t("toast.update"));
        this.close();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
