<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <h4 class="title mb-0">{{ $t("category.list") }}</h4>
      </div>

      <div class="p-1">
        <v-card
          ><div class="row">
            <div class="col-12 col-sm-6 pl-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('shops.search')"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
            <div class="col-12 col-sm-6 text-right pr-5">
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="dialog = true">
                {{ $t("category.new") }}
              </v-btn>
            </div>
          </div>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="categoriesByBoutiques"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="defaultBoutique"
                        :label="$t('category.select')"
                        :items="boutiquesByUsersCon"
                        item-text="nom"
                        item-value="nom"
                        return-object
                        @change="isVisible()"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="visible">
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.categorie"
                        :items="cat"
                        :label="$t('category.grand')"
                        item-text="libelle"
                        item-value="value"
                        return-object
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.libelle"
                        :label="$t('category.name')"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card :loading="myloading" :disabled="myloading">
              <v-card-title class="text-h5">{{ $t("delete1") }} ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import de from "/public/js/app.js";
import da from "/public/js/feather.min.js";
export default {
  data: () => {
    return {
      myloading: false,
      alert: false,
      search: "",
      dialog: false,
      dialogDelete: false,
      visible: false,
      cat: [
        {
          id: 1,
          libelle: "S2A EQUIPEMENT",
          value: "S2A",
        },
        {
          id: 2,
          libelle: "OUTILS SPORTIF",
          value: "OUTILS",
        },
        {
          id: 3,
          libelle: "AUTRES MARQUES",
          value: "AUTRES",
        },
      ],
      defaultBoutique: {
        id: 0,
        nom: "",
        adresse: "",
        telephone: "",
      },
      initialBoutique: {
        id: 0,
        nom: "",
        adresse: "",
        telephone: "",
      },
      editedIndex: -1,
      editedItem: {
        id: 0,
        idBoutique: 0,
        libelle: "",
        categorie: {},
      },
      defaultItem: {
        id: 0,
        idBoutique: 0,
        libelle: "",
        categorie: {},
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("category.name"),
          align: "center",
          sortable: false,
          value: "libelle",
        },
        {
          text: this.$t("shops.nameshop"),
          align: "center",
          sortable: false,
          value: "boutique.nom",
        },
        { text: this.$t("shops.actions"), value: "actions", sortable: false },
      ];
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("category.newcat")
        : this.$t("category.updatecat");
    },

    ...mapGetters(["loading", "categoriesByBoutiques"]),
    ...mapGetters(["loading", "boutiquesByUsersCon"]),
  },
  created() {
    this.initialize();
    this.$store.dispatch("getCategoriesByBoutiques");
    this.$store.dispatch("getBoutiquesByUsersConnect");
  },
  methods: {
    async check(libelle) {
      await this.$store.dispatch("checkCategorie", libelle);
    },

    isVisible() {
      const s2a = this.defaultBoutique.code;
      const s2a1 = "S2AARF";
      if (s2a1 === s2a) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },

    initialize() {},

    setSelected(value) {},

    editItem(item) {
      this.editedIndex = this.categoriesByBoutiques.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.boutique.nom === "S2A SPORT") {
        this.editedItem.categorie = this.cat.find(
          (o) => o.value === item.grandCategorie
        );
        this.visible = true;
      } else {
        this.editedItem.categorie = null;
        this.visible = false;
      }
      this.defaultBoutique = Object.assign({}, item.boutique);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.categoriesByBoutiques.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.myloading = true;
      await this.$store.dispatch("deleteCategorie", this.editedItem);
      this.closeDelete();
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.visible = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.defaultBoutique = Object.assign({}, this.initialBoutique);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.myloading = false;
      this.dialogDelete = false;
      this.visible = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.myloading = true;
      var nom = this.defaultBoutique.nom;
      var libelle = this.editedItem.libelle;
      if (nom != "" && libelle != "") {
        let lang = this.$i18n.locale;
        let token = this.$store.state.auth.token;
        let pass = false;
        if (this.editedIndex > -1) {
          let grandCat;
          if (this.editedItem.boutique.nom === "S2A SPORT") {
            this.editedItem.categorie = this.cat.find(
              (o) => o.value === this.editedItem.grandCategorie
            );
            grandCat = this.editedItem.categorie.value;
          } else {
            this.editedItem.categorie = null;
            grandCat = null;
          }
          let itemToSave = {
            id: this.editedItem.idCategorie,
            boutique: this.defaultBoutique.idBoutique,
            libelle: this.editedItem.libelle,
            grandCategorie: grandCat,
          };
          axios
            .get(
              `check_libelle/${this.editedItem.idCategorie}/` +
                this.editedItem.libelle,
              { headers: { Authorization: "Bearer " + token } }
            )
            .then((response) => {
              pass = !response.data;
              if (pass) {
                try {
                  this.$store
                    .dispatch("updateCategorie", itemToSave)
                    .then((response) => {
                      this.$toast.success(this.$t("toast.update"));
                    });
                } catch (e) {
                  this.$toast.error(this.$t("toast.error"));
                }
              }
              if (!pass) {
                if (lang == "fr")
                  this.$toast.error(
                    "Le nom de la categorie existe déjà.Veuillez ajouter une description supplémentaire"
                  );

                if (lang == "en")
                  this.$toast.error(
                    "The name of the catogery already exists.Please add a description to it"
                  );
              }
            });
        } else {
          let itemToSave = {
            id: 0,
            boutique: this.defaultBoutique.idBoutique,
            libelle: this.editedItem.libelle,
            grandCategorie: this.editedItem.categorie.value,
          };
          axios
            .get("check_libelle/" + this.editedItem.libelle, {
              headers: { Authorization: "Bearer " + token },
            })
            .then((response) => {
              pass = !response.data;
              if (pass) {
                try {
                  this.$store
                    .dispatch("postCategorie", itemToSave)
                    .then((response) => {
                      this.$toast.success(this.$t("toast.save"));
                    });
                } catch (e) {
                  this.$toast.error(this.$t("toast.error"));
                }
              }
              if (!pass) {
                if (lang == "fr")
                  this.$toast.error("Le nom de la categorie existe déjà");

                if (lang == "en")
                  this.$toast.error("The name of the catogery already exists");
              }
            });
        }

        this.close();
      }
    },
  },
};
</script>
