<template>
  <div>
    <div
      class="tab-pane bg-white shadow rounded p-2"
      role="tabpanel"
      aria-labelledby="account-details"
    >
      <v-tabs>
        <v-tab>Informartion personnel</v-tab>
        <v-tab>Securite</v-tab>
        <v-tab>S2APAY</v-tab>
        <v-tab v-if="role === 0">{{ $t('personal.space') }}</v-tab>
        <v-tab-item>
          <v-container fluid>
            <div v-if="role === 1" class="mb-5">
              <button class="btn btn-primary" @click="dialog = true">
                {{ $t('personal.fournisseur') }}
              </button>
            </div>
            <form @submit.prevent="updateUser">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">{{ $t('nom') }}</label>
                    <div class="form-icon position-relative">
                      <i data-feather="user" class="fea icon-sm icons"></i>
                      <input
                        id="first-name"
                        type="text"
                        class="form-control ps-5"
                        v-model="user.nom"
                      />
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">{{ $t('prenom') }}</label>
                    <div class="form-icon position-relative">
                      <i
                        data-feather="user-check"
                        class="fea icon-sm icons"
                      ></i>
                      <input
                        id="last-name"
                        type="text"
                        class="form-control ps-5"
                        v-model="user.prenom"
                      />
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">{{ $t('username') }}</label>
                    <div class="form-icon position-relative">
                      <i
                        data-feather="user-check"
                        class="fea icon-sm icons"
                      ></i>
                      <input
                        id="display-name"
                        type="text"
                        class="form-control ps-5"
                        v-model="user.username"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">{{ $t('email') }}</label>
                    <div class="form-icon position-relative">
                      <i
                        data-feather="user-check"
                        class="fea icon-sm icons"
                      ></i>
                      <input
                        id="display-name"
                        type="text"
                        class="form-control ps-5"
                        v-model="user.email"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12 mt-2 mb-0">
                  <button class="btn btn-primary">{{ $t('save') }}</button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <h5 class="mt-4">{{ $t('personal.update') }} :</h5>
            <form @submit.prevent="updatePwd">
              <div class="row mt-3">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">{{ $t('personal.old') }} :</label>
                    <div class="form-icon position-relative">
                      <i data-feather="key" class="fea icon-sm icons"></i>
                      <input
                        type="password"
                        class="form-control ps-5"
                        placeholder="Old password"
                        v-model="password.old"
                        required=""
                      />
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">{{ $t('personal.new') }} :</label>
                    <div class="form-icon position-relative">
                      <i data-feather="key" class="fea icon-sm icons"></i>
                      <input
                        type="password"
                        class="form-control ps-5"
                        placeholder="New password"
                        v-model="password.new"
                        required=""
                      />
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-12 mt-2 mb-0">
                  <button class="btn btn-primary">{{ $t('save') }}</button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <div class="mb-5 mt-5">
              <button class="btn btn-primary" @click="dialogS2apay = true">
                {{ $t('s2apay.add') }}
              </button>

              <div class="mb-3" v-if="portefeuille">
                <label class="form-label">{{ $t('s2apay.port') }} :</label>
                <div class="form-icon position-relative">
                  <i data-feather="key" class="fea icon-sm icons"></i>
                  <input
                    class="form-control ps-5"
                    disabled
                    v-model="portefeuille"
                    required=""
                  />
                </div>
              </div>
            </div>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="role === 0">
          <v-container fluid>
            <div>
              <h5 class="mt-4">{{ $t('personal.space') }} :</h5>
              <form @submit.prevent="updateUserSpace">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">{{ $t('personal.clt') }}</label>
                      <div class="form-icon position-relative">
                        <v-select
                          :label="$t('personal.selectClt')"
                          v-model="myuser"
                          :items="listUsers"
                          :hint="`${myuser.nom} ${myuser.prenom}`"
                          item-text="nom"
                          item-value="prenom"
                          return-object
                          dense
                          solo
                        ></v-select>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">{{
                        $t('personal.space1')
                      }}</label>
                      <div class="form-icon position-relative">
                        <i
                          data-feather="user-check"
                          class="fea icon-sm icons"
                        ></i>
                        <input
                          type="text"
                          class="form-control ps-5"
                          v-model="myuser.file_max"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-12 mt-2 mb-0">
                    <button class="btn btn-primary">{{ $t('save') }}</button>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
          </v-container>
        </v-tab-item>
      </v-tabs>

      <!-- <div>
        <h5 class="mt-4">{{ $t("personal.fournisseur") }} :</h5>
        <form @submit.prevent="updateUserFour">
          <div class="row">
            <v-col cols="12">
              <v-text-field
                v-model="form.numero"
                :label="$t('personal.numero')"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="form.idc"
                :label="$t('personal.idc')"
                accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp,application/pdf"
                prepend-icon="mdi-paperclip"
                multiple
                required
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="form.cfe"
                :label="$t('personal.cfe')"
                accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp,application/pdf"
                prepend-icon="mdi-paperclip"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
            <div class="col-lg-12 mt-2 mb-0">
              <button class="btn btn-primary">{{ $t("save") }}</button>
            </div>
          </div>
        </form>
      </div> -->

      <v-dialog v-model="dialog" persistent max-width="600px" class="col-auto">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ $t('personal.fournisseur') }}</span>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <v-col cols="12">
                <v-text-field
                  v-model="form.numero"
                  :label="$t('personal.numero')"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.taxId"
                  :label="$t('personal.taxId')"
                  required
                >
                </v-text-field>
              </v-col>
              <!--end col-->
              <v-col cols="12">
                <v-file-input
                  v-model="form.idc"
                  :label="$t('personal.idc')"
                  accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp,application/pdf"
                  prepend-icon="mdi-paperclip"
                  multiple
                  required
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="form.cfe"
                  :label="$t('personal.cfe')"
                  accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp,application/pdf"
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <!--end col-->
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="closeDialog">
              {{ $t('cancel') }}
            </v-btn>

            <v-btn color="blue darken-1" text @click="updateUserFour">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogS2apay"
        persistent
        max-width="600px"
        class="col-auto"
      >
        <v-card>
          <v-card-title class="text-center">
            <span class="text-h6 text-center">{{ $t('s2apay.add') }}</span>
          </v-card-title>

          <v-card-text>
            <h6 class="mt-4 mb-15 text-center">{{ $t('s2apay.info') }}</h6>
            <div class="row">
              <v-col cols="12">
                <v-text-field
                  v-model.trim.lazy="formLogin.username"
                  :label="$t('username')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim.lazy="formLogin.password"
                  :label="$t('password')"
                  type="password"
                ></v-text-field>
              </v-col>
            </div>
            <div class="col-12 text-center">
                        <p class="mb-0 mt-3">
                          <small class="text-dark me-2"
                            >{{ $t('createtext') }} ?</small
                          >
                          <a
                            class="text-dark fw-bold"
                            href="https://s2apay.com/signup"
                            >{{ $t('signup') }}</a
                          >
                        </p>
                      </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="closeDialogS2apay">
              {{ $t('cancel') }}
            </v-btn>

            <v-btn color="blue darken-1" text @click="submitS2apayLogin">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <div v-if="role === 0">
        <h5 class="mt-4">{{ $t('personal.space') }} :</h5>
        <form @submit.prevent="updateUserSpace">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">{{ $t('personal.clt') }}</label>
                <div class="form-icon position-relative">
                  <v-select
                    :label="$t('personal.selectClt')"
                    v-model="myuser"
                    :items="listUsers"
                    :hint="`${myuser.nom} ${myuser.prenom}`"
                    item-text="nom"
                    item-value="prenom"
                    return-object
                    dense
                    solo
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">{{ $t('personal.space1') }}</label>
                <div class="form-icon position-relative">
                  <i data-feather="user-check" class="fea icon-sm icons"></i>
                  <input
                    type="text"
                    class="form-control ps-5"
                    v-model="myuser.file_max"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-2 mb-0">
              <button class="btn btn-primary">{{ $t('save') }}</button>
            </div>
          </div>
        </form>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import de from '/public/js/app.js';
import da from '/public/js/feather.min.js';
export default {
  data: () => {
    return {
      dialog: false,
      dialogS2apay: false,
      alert1: false,
      alert2: false,
      alert: false,
      myuser: {
        idUser: null,
        file_max: '',
        nom: '',
        prenom: '',
      },
      form: {
        numero: '',
        taxId: '',
        idc: [],
        cfe: null,
      },
      user: {
        nom: '',
        prenom: '',
        username: '',
        email: '',
      },
      password: {
        old: '',
        new: '',
      },
      formLogin: {
        username: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters(['listUsers']),
    id: function() {
      return this.$store.getters.id;
    },
    nom: function() {
      return this.$store.getters.nom;
    },
    prenom: function() {
      return this.$store.getters.prenom;
    },
    username: function() {
      return this.$store.getters.username;
    },
    role: function() {
      return this.$store.getters.role;
    },
    email: function() {
      return this.$store.getters.email;
    },
    // portefeuille: function() {
    //   return this.$store.getters.portefeuille;
    // },
    portefeuille() {
      if (this.$store.getters.portefeuille) {
        return this.$store.getters.portefeuille;
      } else {
        return null;
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;

      this.form = {
        numero: '',
        taxId: '',
        idc: [],
        cfe: null,
      };
    },
    closeDialogS2apay() {
      this.dialogS2apay = false;

      this.formLogin = {
        username: '',
        password: '',
      };
    },
    updateUser: function() {
      this.$store.dispatch('updateUser', this.user);
      this.$toast.success(this.$t('toast.update'));
    },
    updatePwd: function() {
      this.$store.dispatch('updatePassword', this.password);
      this.$toast.success(this.$t('toast.update'));
    },
    updateUserSpace: function() {
      this.$store.dispatch('updateFileSpace', this.myuser);
      this.$toast.success(this.$t('toast.update'));
    },
    updateUserFour: function() {
      const four = {};
      four.id = this.id;
      four.pieces = [];
      for (var i = 0; i < this.form.idc.length; i++) {
        four.pieces.push(this.form.idc[i]);
      }
      four.numero = this.form.numero;
      four.taxId = this.form.taxId;
      if (this.form.cfe) four.pieces.push(this.form.cfe);
      try {
        this.$store.dispatch('updateUserFour', four);
        this.$toast.success(this.$t('toast.update'));
        this.closeDialog();
      } catch (error) {
        this.$toast.error(this.$t('toast.error'));
      }
    },

    async submitS2apayLogin() {
      try {
        this.$store.dispatch('logInS2apay', this.formLogin);
        this.dialogS2apay = false;
        this.showError = false;
      } catch (error) {
        console.log(error);
        this.showError = true;
      }
    },
  },
  mounted: function() {
    this.$store.dispatch('getAllUsers');
    this.user.nom = this.$store.getters.nom;
    this.user.prenom = this.$store.getters.prenom;
    this.user.username = this.$store.getters.username;
    this.user.email = this.$store.getters.email;
    // this.portefeuille = this.$store.getters.portefeuille;
    // console.log(this.portefeuille)
  },
};
</script>

<style scoped></style>
