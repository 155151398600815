<template>
  <v-app>
    <div>
      <div class="back-to-home rounded d-none d-sm-block">
        <router-link :to="{ path: `/${$i18n.locale}` }"
          ><div class="btn btn-icon btn-primary"><i class="fa fa-home"></i></div
        ></router-link>
      </div>

      <!-- Hero Start -->
      <section class="bg-auth-home bg-circle-gradiant d-table w-100">
        <div class="bg-overlay bg-overlay-white"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
              <div class="card shadow rounded border-0 mt-4">
                <div class="card-body">
                  <h4 class="card-title text-center">{{ $t('signup') }}</h4>
                  <form class="login-form mt-4" @submit.prevent="submit">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label" for="nom"
                            >{{ $t('nom') }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-user icon"></i>
                            <input
                              type="text"
                              class="form-control ps-10"
                              :placeholder="$t('nom')"
                              name="s"
                              required=""
                              v-model="form.nom"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label" for="prenom"
                            >{{ $t('prenom') }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-user icon"></i>
                            <input
                              type="text"
                              class="form-control ps-10"
                              :placeholder="$t('prenom')"
                              name="s"
                              required=""
                              v-model="form.prenom"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label for="username" class="form-label"
                            >{{ $t('email') }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-envelope icon"></i>
                            <input
                              type="text"
                              class="form-control ps-10"
                              :placeholder="$t('email')"
                              name="u"
                              required=""
                              v-model="form.email"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label for="tel" class="form-label"
                            >{{ $t('telephone') }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-envelope icon"></i>
                            <input
                              type="text"
                              class="form-control ps-10"
                              :placeholder="$t('tel')"
                              name="u"
                              required=""
                              v-model="form.tel"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label" for="username"
                            >{{ $t('username') }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-user icon"></i>
                            <input
                              type="text"
                              class="form-control ps-10"
                              :placeholder="$t('username')"
                              name="username"
                              required=""
                              v-model="form.username"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label" for="password"
                            >{{ $t('password') }}
                            <span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-key icon"></i>
                            <input
                              type="password"
                              class="form-control ps-10"
                              :placeholder="$t('password')"
                              required=""
                              v-model="form.password"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label" for="password"
                            >{{ $t('confpass')
                            }}<span class="text-danger">*</span></label
                          >
                          <div class="input-icons">
                            <i class="fa fa-key icon"></i>
                            <input
                              type="password"
                              class="form-control ps-10"
                              :placeholder="$t('confpass')"
                              required=""
                              v-model="form.confirmPassword"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="mb-3">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                              >{{ $t('accept') }}</label
                            >
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-12">
                        <div class="d-grid">
                          <button class="btn btn-primary" type="submit">
                            {{ $t('save') }}
                          </button>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="mx-auto">
                        <p class="mb-0 mt-3">
                          <small class="text-dark me-2"
                            >{{ $t('already') }} ?</small
                          >
                          <router-link
                            class="text-dark fw-bold"
                            :to="{ path: `/${$i18n.locale}/login` }"
                            >{{ $t('con') }}</router-link
                          >
                        </p>
                      </div>
                    </div>
                    <!--end row-->
                  </form>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Hero End -->
    </div>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'SignUp',
  data() {
    return {
      form: {
        username: '',
        nom: '',
        prenom: '',
        tel: '',
        password: '',
        confirmPassword: '',
        email: '',
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(['Register']),
    ...mapActions(['checkUsername']),
    async submit() {
      try {
        if (
          this.form.username != '' &&
          this.form.nom != '' &&
          this.form.email != '' &&
          this.form.password != '' &&
          this.form.tel != ''
        ) {
          if (this.form.password === this.form.confirmPassword) {
            await this.Register(this.form);
            this.form.username = '';
            this.form.nom = '';
            this.form.prenom = '';
            this.form.password = '';
            this.form.confirmPassword = '';
            this.form.email = '';
            this.form.tel = '';
            //this.$router.push({ name: 'Login'})
            this.showError = false;
            // this.alert = true;
          } else {
            if (this.$store.state.home.lng === 'English')
              this.$toast.error('Passwords entered do not match');
            else
              this.$toast.error(
                'Les mots de passe saisis ne sont pas conformes'
              );
          }
        } else {
          if (this.$store.state.home.lng === 'English')
            this.$toast.error('Please fill all the fields');
          else this.$toast.error('Veuillez remplir tous les champs');
        }
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>
<style scoped>
.icon {
  padding: 10px;
  min-width: 40px;
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}
</style>
